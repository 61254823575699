export const DEFAULT_OPTION = '- Select -';
export const YES_NO = ['YES', 'NO'];
export const PAYMENT_CHARGE_MODE =['DEDUCT_FROM_RENT',  'INVOICE']
export const HIGH_LOW = ['HIGH', 'LOW'];
export const FEE_RATE_UNIT = ['%', 'ABSOLUTE'];
export const FEE_PAYMENT_INTERVAL = ['','PERIOD'];
export const FEE_PAYMENT_MECHANISM = ['','PRE_PAID', 'POST_PAID'];
export const LOADING_DATA = 'Loading Data...';
export const ZERO_DATA = 'No Records Found';
export const PAYMENT_MODES = ['ONLINE','CHEQUE'];
export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
export const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/mov', 'video/wmv', 'video/flv', 'video/mkv'];
export const SUPPORTED_MEDIA_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'video/mp4', 'video/mov', 'video/wmv', 'video/flv', 'video/mkv'];
export const LEASE_TYPE = ['INDIVIDUAL', 'COMPANY'];
export const TENANT_TYPE = ['FAMILY', 'BACHELOR'];
export const SUPPORTED_CURRENCIES = ['INR', 'AED'];
export const NOTICE_PERIOD_UNITS = ['DAY', 'MONTH'];
export const RENT_PAYMENT_BILL_DURATIONS = ['MONTH', 'QUARTER'];
export const TENANT_RENT_PAYMENT_BILL_PAYER_CATEGORY = ['TENANT'];
export const TENANT_RENT_PAYMENT_BILL_PAYEE_CATEGORY = ['OWNER'];
export const TENANT_RENT_PAYMENT_BILL_KEEPER_CATEGORY = ['OWNER', 'AZURO'];
export const PROPERTY_TYPES = ['APARTMENT/CONDO', 'INDEPENDENT HOUSE/VILLA'];
export const BHK_FOR_APARTMENT = ['1 RK', 'Studio' ,'1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '6', '7', '8', '9'];
export const BHK_FOR_VILLA = ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '7', '8', '9'];
export const ADDRESSS_TYPE = ['Current', 'Permanent', 'Past'];
export const RECORDS_PER_PAGE =[10,20,50,100];
export const tcfIdPattern = "TCF-[0-9]{8}-[0-9]{4}";
export const RENT_PAYMENT_DAY = ['2', '3', '4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28'];
export const RENT_PAYMENT_BILL_STATUS = ['PENDING','PAYMENT_INITIATED','PENDING_TRANSFER','PROCESSING_TRANSFER','PARTIALLY_TRANSFERRED','TRANSFERRED','CONFIRMATION_INITIATED','CONFIRMATION_VERIFIED','CONFIRMATION_FAILED'];
export const REFUND_STATUS =['PENDING','SUCCESS','FAILED'];
