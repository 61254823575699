import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, Button, Form, Tabs, Tab, Jumbotron, Dropdown, Badge, Table,
  DropdownButton, Modal, DropdownItem, Accordion,
} from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import { addDays } from 'date-fns';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import EstimatesComponent from '../../../partials/layout/EstimatesComponent.js';
import { leaseProfileServices } from './LeaseProfilePage.services';
import { PropertyProfilePageServices } from '../PropertyProfilePage/PropertyProfilePage.services.js';
import { commonServices } from '../../../../app/commonServices/commonServices';
import { Link } from "react-router-dom";
import TasksListComponent from '../../../partials/layout/TasksListComponent.js';
import SchedulesListComponent from '../../../partials/layout/SchedulesListComponent';
import * as constants from '../../../constants';
import { toAbsoluteUrl, formatDate, formatAmount, findPrimary, formatProperCase, formatProperCaseWithUnderscores } from '../../../../_metronic/utils/utils';
import { store } from 'react-notifications-component';
import * as Yup from 'yup'; // for everything
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import AdminAssignments from '../../../partials/layout/AdminAssignments';
import AddUserComponent from '../../../partials/layout/AddUserComponent';
import RentPaymentBills from '../RentPaymentBills/RentPaymentBills';
import SecurityDepositBills from '../SecurityDepositBills/SecurityDepositBills.js';
import UserBankAccounts from '../../../partials/layout/UserBankAccounts';
import BackgroundVerificationRequests from '../../../partials/layout/BackgroundVerificationRequests';
import GenerateUserCredentials from '../../../partials/layout/GenerateUserCredentialComponent';

// common to addDocumentation & addFurnishing
const addWorkSchema = Yup.object().shape({
  works: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Name is required')
  })).min(1, 'Atleast one work is required')
})

const markDocumentWorkCompletedSchema = Yup.object().shape({
  preMoveInWork: Yup.object().shape({
    terminalStatus: Yup.string().required()
  }),
  leaseDocuments: Yup.array().of(Yup.object().shape({
    name: Yup.string().required(),
    isVerified: Yup.boolean().required(),
    file: Yup.mixed().required('Document file is required.')
  }))
})

const markOtherWorkCompletedSchema = Yup.object().shape({
  preMoveInWork: Yup.object().shape({
    terminalStatus: Yup.string().required()
  })
})

const addDocumentSchema = Yup.object().shape({
  name: Yup.string().required('Please select Document Name'),
  documentNumber: Yup.string().notRequired(),
});

const moveInInitiationSchema = Yup.object().shape({
  lease: Yup.object().shape({
    expectedMoveInDate: Yup.date().required('Expected Move-in Date is required'),
    moveInInitiationStatus: Yup.string().required()
  })
});

const moveOutInitiationSchema = Yup.object().shape({
  lease: Yup.object().shape({
    expectedMoveOutDate: Yup.date().required('Expected Move-Out Date is required'),
    moveOutInitiationStatus: Yup.string().required(),
    moveOutInitiationRemarksCategory: Yup.string().required('Move-Out Remarks Category are required'),
    reRentingAvailabilityStatus: Yup.string().required('Re-Renting Availability Status is required'),
    moveOutIntimationDate: Yup.date().required('Move-Out Intimation Date is required')
  })
});
const noticePeriodStatusInitiationSchema = Yup.object().shape({
  lease: Yup.object().shape({
    expectedMoveOutDate: Yup.date().required('Expected Move-Out Date is required'),
    moveOutInitiationRemarksCategory: Yup.string().required('Move-Out Remarks Category are required'),
    reRentingAvailabilityStatus: Yup.string().required('Re-Renting Availability Status is required'),
    moveOutIntimationDate: Yup.date().required('Move-Out Intimation Date is required')
  })
});

const editLeaseSchema = Yup.object().shape({
  lease: Yup.object().shape({
    // these are always optional
    leaseType: Yup.string().optional(),
    tenantFoundByAzuro: Yup.string().optional(),
    tenantRentPaymentBillDurationLength: Yup.string().optional(),
    tenantRentPaymentMode: Yup.string().optional(),
    tenantType: Yup.string().optional(),
    // to determine moveInDate based validation
    // moveInDate: Yup.date().nullable().required(),
    expectedMoveInDate: Yup.date().when("moveInDate", { is: 'moveInDate' === null, then: Yup.date().required('Expected Move-in date is required') }),
    isRentFlowing: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Rent Flowing through Azuro is required field') }),
    leaseEndDate: Yup.date().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.date().required('End Date is required') }),
    leaseStartDate: Yup.date().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.date().required('Start Date is required') }),
    lockInEndDate: Yup.date().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.date().required('Lock in End Date is required') }),
    noticePeriodQuantity: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Notice Period is required') }),
    noticePeriodUnit: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Notice Period Unit is required') }),
    rentPaymentTiming: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Pre Paid or Post Paid is required') }),
    rentPeriods: Yup.array().when("moveInDate", {
      is: 'moveInDate' !== null, then: Yup.array(
        Yup.object().shape({ startDate: Yup.date().required('Start Date is required'), endDate: Yup.date().required('End Date is required'), rent: Yup.number().required('Rent is required') })
      )
    }),
    securityDepositType: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Deposit Scheme is required') }),
    tenantRentPaymentDay: Yup.string().when("moveInDate", { is: 'moveInDate' !== null, then: Yup.string().required('Rent Cycle is required') }),
    // these are sub-dependent on securityDepositType
    isSecurityDepositFlowing: Yup.string().when("securityDepositType", { is: 'PARTIAL' || 'FULL', then: Yup.string().required('Security Deposit Flowing through Azuro is required') }).nullable(),
    securityDeposit: Yup.number().when("securityDepositType", { is: 'PARTIAL' || 'FULL', then: Yup.number().required('Amount is required').moreThan(0, 'Amount cannot be zero or negative') }).nullable(),
  })
});

const moveOutTerminationSchema = Yup.object().shape({
  lease: Yup.object().shape({
    moveOutDate: Yup.date().required('Move-out Date is required'),
    file: Yup.mixed().required('Move-Out-Reconciliation File is required'),
  })
});

// const newBackgroundVerificationRequestSchema = Yup.object().shape({
//   backgroundVerificationRequest: Yup.object().shape({
//     workId: Yup.number().required('workId is a mandatory field'),
//     leaseId: Yup.number().required('leaseId is a mandatory field'),
//     houseId: Yup.number().required('houseId is a mandatory field'),
//     userId: Yup.number().required('Tenant is a mandatory field'),
//     name: Yup.string().required('Name is a mandatory field'),
//     email: Yup.string().required('Email is a mandatory field'),
//     dateOfBirth: Yup.string().required('Date of Birth is a mandatory field'),
//     fathersName: Yup.string().required(`Father's is a mandatory field`),
//   })
// });

class LeaseProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leaseId: props.match.params.leaseId,
      isFetching: false,
      leaseProfile: undefined,
      showAddDocumentationWorkModal: false,
      showAddFurnishingWorkModal: false,
      showCreateMoveInWorkModal: false,
      showCreateMoveOutWorkModal: false,
      showAddDocumentsModal: false,
      showEditUserModal: false,
      showDeleteDocumentModal: false,
      showMoveInInitiationModal: false,
      showMoveInModal: false,
      showNoticePeriodModal: false,
      showLeaseExtensionsModal: false,
      showMoveOutInitiationModal: false,
      showLeaseModal: false,
      showGenerateUserCredentialsModal: false,
      showRequestBackgroundVerificationModal: false,
      showGenerateEscrowAgreementModal: false,
      showMoveOutConfirmModal: false,
      showNoticePeriodTerminalModal: false,
      showLeaseExtentionTerminalModal: false,
      userConfirmation: false,
      documentConfirmation: false,
      userArrayAddDocument: [],
      documentName: [
        { 'aadhaarCard': 'AADHAAR_CARD' }, {}, {}
      ],
      defaultDocumentationWorkNames: [
        {
          // leaseId: parseInt(props.match.params.leaseId, 10),
          workCategoryId: 3,
          isChecked: false,
          name: 'Lease Agreement',
          code: 'LEASE_AGREEMENT',
          isPreMoveInWork: true
        },
        {
          // leaseId: parseInt(props.match.params.leaseId, 10),
          workCategoryId: 3,
          isChecked: false,
          name: 'Society NOC',
          code: 'SOCIETY_NOC',
          isPreMoveInWork: true
        },
        {
          // leaseId: parseInt(props.match.params.leaseId, 10),
          workCategoryId: 3,
          isChecked: false,
          name: 'Police Verification',
          code: 'POLICE_VERIFICATION',
          isPreMoveInWork: true
        },
        {
          // leaseId: parseInt(props.match.params.leaseId, 10),
          workCategoryId: 3,
          isChecked: false,
          name: 'Background Verification',
          code: 'BACKGROUND_VERIFICATION',
          isPreMoveInWork: true
        },
      ],
      currentUncreatedDocuments: [],
      sampleEmptyOtherDocument: {
        // leaseId: parseInt(props.match.params.leaseId, 10),
        workCategoryId: 3,
        name: '',
        code: undefined,
        // isChecked: true
      },
      // sampleEmptyFurnishing: {
      //   leaseId: parseInt(props.match.params.leaseId, 10),
      //   workCategoryId: 4,
      //   name: ''
      // },
      sampleEmptyMoveIn: {
        leaseId: parseInt(props.match.params.leaseId, 10),
        workCategoryId: 5,
        name: 'Move-In Report',
        isPreMoveInWork: true
      },
      sampleEmptyMoveOut: {
        leaseId: parseInt(props.match.params.leaseId, 10),
        workCategoryId: 7,
        name: 'Move-Out Report',
        isMoveOutWork: true
      },

      sampleEmptyRentPeriod: {
        startDate: '',
        endDate: '',
        rent: 0
      },
      showMarkWorkAsCompletedModal: false,
      addDocumentDirectly: undefined,
      allowedUserEmails: ["support@azuro.in", "sudhanshu_mishra@azuro.in"],
    };
  }

  async componentDidMount() {
    await this.getLeaseDetails();
    await this.getPropertySupervisors();
    await this.getTenantsAndOwners();
  }

  getCurrentUncreatedDocumentations = () => {
    // for each existing document
    const { defaultDocumentationWorkNames, leaseProfile, documentationAction } = this.state;
    var tempArray = defaultDocumentationWorkNames;
    leaseProfile.documentationData.forEach((doc) => {
      // check if that name is present in default documents
      defaultDocumentationWorkNames.forEach((defaultDoc, idx) => {
        if (documentationAction.isPreMoveInDocumentationWork) {
          doc.isPreMoveInWork === true && doc.name === defaultDoc.name && tempArray.splice(idx, 1);
        }
        if (documentationAction.isLeaseExtensionDocumentationWork) {
          doc.leaseExtensionId !== null && doc.name === defaultDoc.name && tempArray.splice(idx, 1);
        }
        if (documentationAction.isMoveOutDocumentationWork) {
          doc.isMoveOutWork === true && doc.name === defaultDoc.name && tempArray.splice(idx, 1);
        }
      }
      )
    }
    )
    this.setState({ currentUncreatedDocuments: tempArray });
  }

  findActiveLeaseExtension = (leaseExtensions) => {
    let activeLeaseExtension = leaseExtensions.find(leaseExtension => (leaseExtension.terminalStatus === null));
    return activeLeaseExtension;
  }

  getLeaseDetails = async () => {
    this.setState({ isFetching: true })
    const { leaseId } = this.state;
    const data = {
      include: ['HOUSE_OWNERS', 'HOUSE', 'LEASE_DOCUMENTS', 'MOVE_IN_MANAGER', 'TENANT_MOVE_IN', 'LEASE_EXTENSIONS', 'BACKGROUND_VERIFICATION_REQUESTS'],
      workCategoryNames: ['TENANT_MOVE_IN']
    }
    const result = await leaseProfileServices.getLeaseDetails(leaseId, data);
    if (result && result.status === 200) {
      let { leaseProfile } = this.state;
      let activeLeaseExtension = this.findActiveLeaseExtension(result.data?.lease?.leaseExtensions);
      this.setState({
        leaseProfile: {
          ...leaseProfile,
          ...result.data
        },
        activeLeaseExtension
      })
    }
    this.setState({ isFetching: false })
  }

  getLeaseDocumentation = async () => {
    const data = {
      leaseId: this.state.leaseId,
      workCategoryNames: ['DOCUMENTATION'],
      includeLeaseExtensionWorks: 'TRUE',
      include: ['BACKGROUND_VERIFICATION_REQUESTS']
    }
    const result = await leaseProfileServices.getLeaseDocumentation(data);
    if (result && result.status === 200) {
      let { leaseProfile } = this.state;
      const data = result.data && result.data.leaseWorks;
      const formattedData = this.formatLeaseDocumentation(data);
      this.setState({
        leaseProfile: {
          ...leaseProfile,
          documentationData: formattedData
        }
      })
    }
  }

  formatLeaseDocumentation = (documentationWorks) => {
    if (documentationWorks.some(documentationWork => documentationWork.code === "BACKGROUND_VERIFICATION")) {
      let result = [];
      const backgroundVerificationDocumentation = documentationWorks.filter(documentationWork => documentationWork.code === "BACKGROUND_VERIFICATION");
      const otherDocumentation = documentationWorks.filter(documentationWork => documentationWork.code !== "BACKGROUND_VERIFICATION");
      result = [...backgroundVerificationDocumentation, ...otherDocumentation];
      return result;
    } else {
      return documentationWorks;
    }
  }

  getLeaseFurnishing = async () => {
    const data = {
      leaseId: this.state.leaseId,
      workCategoryNames: ['FURNISHING'],
      include: ['HOUSE_ID'],
      includeLeaseExtensionWorks: 'TRUE'
    }
    const result = await leaseProfileServices.getLeaseFurnishing(data);
    if (result && result.status === 200) {
      let { leaseProfile } = this.state;
      const data = result.data && result.data.leaseWorks;
      this.setState({
        leaseProfile: {
          ...leaseProfile,
          furnishingData: data
        }
      })
    }
  }

  getLeaseReports = async () => {
    const data = {
      leaseId: this.state.leaseId,
      workCategoryNames: ['TENANT_MOVE_IN', "TENANT_MOVE_OUT"],
      include: ['MOVE_IN_REPORT_EXECUTION_DATE', 'IS_MOVE_IN_REPORT_PUBLISHED', 'MOVE_OUT_REPORT_EXECUTION_DATE', 'IS_MOVE_IN_REPORT_PUBLISHED']
    }
    const result = await leaseProfileServices.getLeaseWorks(data);
    if (result && result.status === 200) {
      let { leaseProfile } = this.state;
      const data = result.data && result.data.leaseWorks;
      this.setState({
        leaseProfile: {
          ...leaseProfile,
          reportsData: data
        }
      })
    }
  }

  formatLeaseParties = (rawLeaseParties) => {
    const formattedData = rawLeaseParties.reduce((partiesData, data) => {
      const userSubCategory = data.userSubCategory;
      if (!partiesData[userSubCategory]) {
        partiesData[userSubCategory] = [];
      }
      partiesData[userSubCategory].push(data);
      return partiesData;
    }, {});
    return formattedData;
  }

  getLeaseParties = async () => {
    const data = {
      leaseId: this.state.leaseId,
      houseId: this.state.leaseProfile.lease.houseOffering.house.id,
      include: ['USER_PHONE_NUMBERS', 'USER_BANK_ACCOUNTS', 'USER_DOCUMENTS', 'USER_HOUSE_OWNERSHIPS', 'USER_LEASE_TENANT_PARTICIPATIONS']
    }
    const res = await PropertyProfilePageServices.getPropertyOwner(data);
    const result = await leaseProfileServices.getLeaseTeanants(data);
    if (result && result.status === 200) {
      let { leaseProfile } = this.state;
      const tenants = result.data && result.data.leaseTenantParticipations;
      const owners = res.data && res.data.houseOwnerships;
      if (owners && owners.length > 0) {
        owners.forEach(item => {
          let propertiesLeaseCountArr = [];
          if (item.user.houseOwnerships.length > 0) {
            let propertyString = item.user.houseOwnerships.length === 1 ? " Property" : " Properties"
            propertiesLeaseCountArr.push("Owner in " + item.user.houseOwnerships.length + propertyString)
          }
          if (item.user.leaseTenantParticipations.length > 0) {
            let leaseString = item.user.leaseTenantParticipations.length === 1 ? " Lease" : " Leases"
            propertiesLeaseCountArr.push("Tenant in " + item.user.leaseTenantParticipations.length + leaseString)
          }
          item.propertiesLeaseCountArr = propertiesLeaseCountArr;
        })
      }
      if (tenants && tenants.length > 0) {
        tenants.forEach(item => {
          let propertiesLeaseCountArr = [];
          if (item.user.houseOwnerships.length > 0) {
            let propertyString = item.user.houseOwnerships.length === 1 ? " Property" : " Properties"
            propertiesLeaseCountArr.push("Owner in " + item.user.houseOwnerships.length + propertyString)
          }
          if (item.user.leaseTenantParticipations.length > 0) {
            let leaseString = item.user.leaseTenantParticipations.length === 1 ? " Lease" : " Leases"
            propertiesLeaseCountArr.push("Tenant in " + item.user.leaseTenantParticipations.length + leaseString)
          }
          item.propertiesLeaseCountArr = propertiesLeaseCountArr;
        })
      }
      this.setState({
        leaseProfile: {
          ...leaseProfile,
          owners: owners,
          tenants: tenants
        },
        userArrayAddDocument: owners?.concat(tenants)
      })
    }
  }

  handleMarkPrimaryUserButtonClick = (userType = null, id = null) => {
    if (userType === 'Owner') {
      if (id !== null) {
        this.setState({ houseOwnershipToBeEdited: id, userType: userType, leaseTenantparticipantsToBeEdited: null });
      }
    } else {
      if (id !== null) {
        this.setState({ leaseTenantparticipantsToBeEdited: id, userType: userType, houseOwnershipToBeEdited: null });
      }
    }
    this.setState(state => ({
      showMarkPrimaryUserModal: !state.showMarkPrimaryUserModal
    }));
  }

  handleConfirmMarkPrimaryUserButtonClick = async () => {
    let result;
    if (this.state.leaseTenantparticipantsToBeEdited !== null) {
      result = await leaseProfileServices.markPrimaryLeaseTeanantParticipants(this.state.leaseTenantparticipantsToBeEdited);
    }
    if (this.state.houseOwnershipToBeEdited !== null) {
      result = await PropertyProfilePageServices.markPrimaryHouseOwnership(this.state.houseOwnershipToBeEdited);
    }
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: this.state.userType + ' marked as Primary!',
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
    }
    this.setState(state => ({ showMarkPrimaryUserModal: !state.showMarkPrimaryUserModal }));
    this.getLeaseParties();
  }

  handleDeleteLeaseTenantParticipationButtonClick = (userType = null, id = null) => {
    if (userType === 'Owner') {
      if (id !== null) {
        this.setState({ houseOwnershipToBeDeleted: id, userType: userType, leaseTenantparticipantsToBeDeleted: null });
      }
    } else {
      if (id !== null) {
        this.setState({ leaseTenantparticipantsToBeDeleted: id, userType: userType, houseOwnershipToBeDeleted: null });
      }
    }
    this.setState(state => ({ showDeleteLeaseTenantParticipationModal: !state.showDeleteLeaseTenantParticipationModal }));
  }

  handleConfirmDeleteLeaseTenantParticipationButtonClick = async () => {
    let result;
    if (this.state.leaseTenantparticipantsToBeDeleted !== null) {
      result = await leaseProfileServices.deleteLeaseTeanantParticipants(this.state.leaseTenantparticipantsToBeDeleted);
    }
    if (this.state.houseOwnershipToBeDeleted !== null) {
      result = await PropertyProfilePageServices.deleteHouseOwnership(this.state.houseOwnershipToBeDeleted);
    }
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: this.state.userType + ' removed from the Lease!',
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
    }
    this.setState(state => ({ showDeleteLeaseTenantParticipationModal: !state.showDeleteLeaseTenantParticipationModal }));
    this.getLeaseParties();
  }

  handleSelect = async (key) => {
    if (key === 'documentationNoData' || key === 'furnishingNoData' || key === 'moveInNoData') {
      //Do nothing
    } else if (key === 'documentation') {
      await this.getLeaseDocumentation();
    } else if (key === 'furnishing') {
      await this.getLeaseFurnishing();
    } else if (key === 'moveIn') {
      await this.getLeaseReports();
    } else if (key === 'parties') {
      await this.getLeaseParties();
    } else if (key === 'rentPaymentBills') {
      await this.setState({ isRentPaymentBillsTab: true });
      await this.setState({ isSecurityDepositBillTab: true })
    }
  }

  getTenantsAndOwners = async () => {
    const { leaseProfile } = this.state;
    const data = {
      leaseId: leaseProfile && leaseProfile.lease && leaseProfile.lease.id,
      stakeHolderCategories: ['TENANTS', 'OWNERS']
    }
    const requestorData = await commonServices.getUserListData(data);
    if (requestorData && requestorData.status === 200) {
      const latestTenantAndOnwers = requestorData.data.users;
      const tenants = this.formatLeaseParties(latestTenantAndOnwers)?.TENANT;
      await this.setState({
        latestTenantAndOnwers,
        tenants,
      });
    }
  }

  getPropertySupervisors = async () => {
    this.setState({ isFetching: true })
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      (result.data.users).push(this.props.userDetails);
      await this.setState({ propertySuperVisors: result.data.users })
    }
    this.setState({ isFetching: false })
  }

  handleChildData = async (component) => {
    if (component === "DOCUMENTATION") {
      await this.getLeaseDocumentation();
    }
    if (component === "FURNISHING") {
      await this.getLeaseFurnishing();
    }
    if (component === 'MOVEINREPORT' || component === 'MOVEOUTREPORT') {
      await this.getLeaseReports();
    }
  }

  handleAddDocumentationButtonClick = async (documentationAction = null) => {
    documentationAction && await this.setState({ documentationAction });
    documentationAction && this.getCurrentUncreatedDocumentations();
    this.setState(state => ({ showAddDocumentationWorkModal: !state.showAddDocumentationWorkModal }));
  }

  handleAddFurnishingButtonClick = (furnishingAction = null) => {
    furnishingAction && this.setState({ furnishingAction });
    this.setState(state => ({ showAddFurnishingWorkModal: !state.showAddFurnishingWorkModal }));
  }

  handleCreateMoveInReportButtonClick = () => {
    this.setState(state => ({ showCreateMoveInWorkModal: !state.showCreateMoveInWorkModal }));
  }

  handleCreateMoveOutReportButtonClick = () => {
    this.setState(state => ({ showCreateMoveOutWorkModal: !state.showCreateMoveOutWorkModal }));
  }

  handleMarkWorkAsCompletedButtonClick = (workId = null, workCategoryId = null, workCode = null, workName = null) => {
    if (workId !== null && workCategoryId !== null) {
      this.setState({
        markCompleted: {
          workId: workId,
          workCategoryId: workCategoryId,
          workCode: workCode,
          workName: workName
        }
      });
    }
    this.setState(state => ({ showMarkWorkAsCompletedModal: !state.showMarkWorkAsCompletedModal }))
  }

  handleEditUserButtonClick = (userType = null, userId = null) => {
    if (userId !== null) {
      const tenantArray = this.state.leaseProfile && this.state.leaseProfile.tenants;
      const ownerArray = this.state.leaseProfile && this.state.leaseProfile.owners;
      let toBeEdited = undefined;
      if (userType === 'Tenant') {
        let tenants = tenantArray.find(tenant => (tenant.user.id === userId));
        toBeEdited = tenants.user;

      } else {
        let owners = ownerArray.find(owner => (owner.user.id === userId));
        toBeEdited = owners.user;
      }
      this.setState({ userToBeEdited: toBeEdited });
    }
    this.setState(state => ({
      showEditUserModal: !state.showEditUserModal,
      userType: userType,
      userConfirmation: false,
    }));
  }

  handleAddDocumentsButtonClick = (userId = null, addDocumentDirectly = null) => {
    this.setState(state => ({
      showAddDocumentsModal: !state.showAddDocumentsModal,
      activeUser: userId,
      addDocumentDirectly: addDocumentDirectly,
      documentConfirmation: false
    }))
  }

  handleDeleteDocumentButtonClick = (documentId = null) => {
    if (documentId !== null) {
      this.setState({ idToBeDeleted: documentId })
    }
    this.setState(state => ({ showDeleteDocumentModal: !state.showDeleteDocumentModal }));
  }

  handleConfirmDeleteDocumentButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await commonServices.deleteDocument(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Document deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteDocumentModal: !state.showDeleteDocumentModal }));
        this.getLeaseDetails();
      }
    }
  }

  handleMoveInButtonClick = async (eventKey) => {
    this.setState(state => ({ showMoveInModal: !state.showMoveInModal, eventKey: eventKey }));
  }

  handleNoticePeriodButtonClick = async () => {
    this.setState(state => ({ showNoticePeriodModal: !state.showNoticePeriodModal }))
  }

  handleleaseExtensionsButtonClick = async () => {
    this.setState(state => ({ showLeaseExtensionsModal: !state.showLeaseExtensionsModal }))
  }

  handleMoveOutInitiationButtonClick = async () => {
    this.setState(state => ({ showMoveOutInitiationModal: !state.showMoveOutInitiationModal }))
  }

  handleMoveInInitiationButtonClick = async () => {
    this.setState(state => ({ showMoveInInitiationModal: !state.showMoveInInitiationModal }))
  }

  handleEditLeaseButtonClick = async (id = null) => {
    await this.setState(state => ({ showLeaseModal: !state.showLeaseModal }));
  }

  handleAdminAssignmentsData = async (isReassign, leaseOrHouse) => {
    isReassign && (leaseOrHouse === 'lease') && await this.getLeaseDetails();
  }

  handleRequestBackgroundVerificationButtonClick = async (backgroundVerificationRequest = null) => {
    backgroundVerificationRequest && await this.setState(backgroundVerificationRequest);
    this.setState(state => ({ showRequestBackgroundVerificationModal: !state.showRequestBackgroundVerificationModal }))
  }

  handleGenerateEscrowAgreementButtonClick = async (agreementRequest = null) => {
    if (agreementRequest !== null) {
      console.log("=== got agreementRequest", agreementRequest);
      if (agreementRequest !== "GENERATE") {
        console.log("=== saving in state", agreementRequest);
        agreementRequest && await this.setState(state => ({ agreementRequest: agreementRequest }));
      } else {
        console.log("=== making the call", agreementRequest);
        const data = {
          workId: this.state.agreementRequest.workId,
          leaseId: this.state.agreementRequest.leaseId,
          houseId: this.state.agreementRequest.houseId,
        }
        const result = await leaseProfileServices.generateEscrowAgreement(data);
        if (result && result.status === 201) {
          console.log("=== result", result);
          store.addNotification({
            title: "Success!",
            message: `Agreement was generated successfully!`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
              showIcon: true,
              pauseOnHover: true
            }
          });
          await this.getLeaseDocumentation();
        }
      }
    }
    this.setState(state => ({ showGenerateEscrowAgreementModal: !state.showGenerateEscrowAgreementModal }))
  }

  handleCustomChange = async (e, setFieldValue) => {
    const { tenants } = this.state;
    if (e.target.validity.valid) {
      if (e.target.value === '0') {
        setFieldValue('backgroundVerificationRequest.name', '');
        setFieldValue('backgroundVerificationRequest.email', '');
      } else {
        const selectedTenant = tenants.find(tenant => (tenant.id.toString() === e.target.value));
        selectedTenant && setFieldValue('backgroundVerificationRequest.name', selectedTenant.fullName);
        selectedTenant && setFieldValue('backgroundVerificationRequest.email', selectedTenant.email);
      }
    }
  };

  handleMoveOutConfirmButtonClick = async (id = null) => {
    await this.setState(state => ({ showMoveOutConfirmModal: !state.showMoveOutConfirmModal }))
  }

  handleNoticePeriodTerminationButtonClick = async (id = null) => {
    await this.setState(state => ({ showNoticePeriodTerminalModal: !state.showNoticePeriodTerminalModal }))
  }

  handleLeaseExtensionTerminalButtonClick = async (id = null) => {
    await this.setState(state => ({ showLeaseExtentionTerminalModal: !state.showLeaseExtentionTerminalModal }))
  }

  handleGenerateUserCredentialsData = async (childData) => {
    if (childData === 'LeaseProfile') {
      await this.getLeaseParties();
    }
  }

  render() {
    const { leaseId, leaseProfile, currentUncreatedDocuments,
      userToBeEdited, markCompleted, isFetching, latestTenantAndOnwers, tenants, isRentPaymentBillsTab, isSecurityDepositBillTab,
      furnishingAction, activeLeaseExtension, moveInMoveOut, documentationAction,
      showRequestBackgroundVerificationModal, backgroundVerificationRequest, allowedUserEmails } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    return (
      <>
        <Container fluid key={'c' + 1}>
          <Jumbotron fluid className="py-3 px-2">
            <Container>
              <Row>
                <Col xs={12}>
                  <span className="small">Lease #{leaseProfile && leaseProfile.lease && leaseProfile.lease.id}</span>
                  <Link to={`/property-profile/${leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering.house && leaseProfile.lease.houseOffering.house.id}`}>
                    <h3>{
                      leaseProfile?.lease?.houseOffering?.house?.formattedHouseName
                    } <br />
                      <small>{leaseProfile?.lease?.houseOffering?.house?.society?.locality?.name}, {leaseProfile?.lease?.houseOffering?.house?.society?.locality?.city?.name}</small></h3>
                  </Link>
                  <p><small>Created by: {leaseProfile?.lease.createdBy?.fullName ? leaseProfile?.lease.createdBy?.fullName : 'Unknown'} on {formatDate(leaseProfile?.lease.createdAt)}</small></p>
                  <p><small>{leaseProfile && leaseProfile.lease.houseOffering.house.propertyManagementStatus === 'YES' ? <span className="text-success">Property Under Management</span> : <span className="text-danger">Not Under Management</span>}</small></p>
                </Col>
              </Row>
              <Row className="d-flex align-items-start">
                <Col xs={6} md={4}>
                  <div className="kt-widget4">
                    <p className="mb-0 small">OWNER</p>
                    <div className="kt-widget4__item ">
                      <div className="kt-widget4__pic kt-widget4__pic--pic ">
                        <img alt="" src={toAbsoluteUrl((leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering && leaseProfile.lease.houseOffering.owners && findPrimary(leaseProfile.lease.houseOffering.owners) && findPrimary(leaseProfile.lease.houseOffering.owners).profilePicture) || '/media/users/default.jpg')} />
                      </div>
                      <div className="kt-widget4__info ">
                        <p className="mb-0 kt-widget4__username">
                          {leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering && leaseProfile.lease.houseOffering.owners &&
                            findPrimary(leaseProfile.lease.houseOffering.owners) && findPrimary(leaseProfile.lease.houseOffering.owners).fullName}
                          {leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering && leaseProfile.lease.houseOffering.owners &&
                            findPrimary(leaseProfile.lease.houseOffering.owners) && findPrimary(leaseProfile.lease.houseOffering.owners).isOnCustomerApp === true && <span className="bg-white text-success ml-2" title={'App installed'}><i className="la la-mobile"></i><i className="la la-check"></i></span>}
                          {leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering && leaseProfile.lease.houseOffering.owners &&
                            findPrimary(leaseProfile.lease.houseOffering.owners) && (findPrimary(leaseProfile.lease.houseOffering.owners).isOnCustomerApp === null || findPrimary(leaseProfile.lease.houseOffering.owners).isOnCustomerApp === false) && <span className="bg-white text-danger ml-2" title="App not installed"><i className="la la-mobile"></i><i className="la la-exclamation"></i></span>}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInManager &&
                    <div className="kt-widget4">
                      <p className="mb-0 small">MOVE-IN MANAGER</p>
                      <div className="kt-widget4__item ">
                        <div className="kt-widget4__pic kt-widget4__pic--pic ">
                          <img alt="" src={toAbsoluteUrl((leaseProfile.lease.moveInManager.profilePicture) || '/media/users/default.jpg')} />
                        </div>
                        <div className="kt-widget4__info ">
                          <p className="mb-0 kt-widget4__username">
                            {leaseProfile.lease.moveInManager.fullName}
                          </p>
                          {/* <p className="kt-widget4__text ">
                        </p> */}
                        </div>
                      </div>
                    </div>}
                  <AdminAssignments leaseId={leaseId} isReassign={true}
                    adminAssignmentsParent={this.handleAdminAssignmentsData}
                    title={(leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInManager) ? 'Reassign MIM' : 'Assign MIM'}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <p className="mb-0 small">LEASE STATUS</p><br />
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Pre Move-in">
                      <Dropdown.Item eventKey='start-move-in' onClick={() => this.handleMoveInInitiationButtonClick()}>Start Move-in Process</Dropdown.Item>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === 'YES' &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Move-in in Process"
                      onSelect={(eventKey) => this.handleMoveInButtonClick(eventKey)}>
                      <Dropdown.Item eventKey='moved-in'>Mark Moved-in</Dropdown.Item>
                      <Dropdown.Item eventKey='deal-cancelled'>Deal Cancelled</Dropdown.Item>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === 'NO' &&
                    <Button variant="outline-danger" disabled>Deal Cancelled</Button>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === 'YES' && leaseProfile.lease.moveOutInitiationStatus === null &&
                    leaseProfile.lease.moveInDate !== null && leaseProfile.lease.moveOutDate === null && leaseProfile.lease.noticePeriods.length === 0 && leaseProfile.lease.leaseExtensions.length === 0 &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Moved-in">
                      <Dropdown.Item eventKey='start-notice-period' onClick={() => this.handleNoticePeriodButtonClick()} disabled={!this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')}>Initiate Notice Period</Dropdown.Item>
                      <Dropdown.Item eventKey='extend-lease' onClick={() => this.handleleaseExtensionsButtonClick()}>Extend Lease</Dropdown.Item>
                      <Dropdown.Item eventKey='start-move-out' onClick={() => this.handleMoveOutInitiationButtonClick()} disabled={!this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')}>Start Move-out Process</Dropdown.Item>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === 'YES' && leaseProfile.lease.noticePeriods.length > 0 && leaseProfile.lease.noticePeriods[leaseProfile.lease.noticePeriods.length - 1].terminalStatus === null &&
                    leaseProfile.lease.moveInDate !== null && leaseProfile.lease.moveOutDate === null &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Notice Period in Process">
                      <DropdownItem onClick={() => this.handleNoticePeriodTerminationButtonClick()}>Confirm Notice Period</DropdownItem>
                      {/* <DropdownItem onClick={()=> this.handleMoveOutInitiationButtonClick()}>Start Move-Out Process</DropdownItem> */}
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === 'YES' && leaseProfile.lease.moveOutInitiationStatus === 'YES' && leaseProfile.lease.moveOutTerminalStatus === null &&
                    leaseProfile.lease.moveInDate !== null && leaseProfile.lease.moveOutDate === null &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Move-Out in Process">
                      <DropdownItem onClick={() => this.handleMoveOutConfirmButtonClick()}>End Move-Out Process</DropdownItem>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.leaseExtensions.length > 0 && leaseProfile.lease.leaseExtensions[leaseProfile.lease.leaseExtensions.length - 1].terminalStatus === null && leaseProfile.lease.moveOutInitiationStatus === null && leaseProfile.lease.noticePeriods.length === 0 &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Lease Extension in Process">
                      <DropdownItem onClick={() => this.handleLeaseExtensionTerminalButtonClick()}>End Lease Extension Process</DropdownItem>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.leaseExtensions.length > 0 && leaseProfile.lease.leaseExtensions[leaseProfile.lease.leaseExtensions.length - 1].terminalStatus === 'YES' && leaseProfile.lease.moveOutInitiationStatus === null && leaseProfile.lease.noticePeriods.length === 0 &&
                    <DropdownButton id="dropdown-basic-button" variant="outline-primary" title="Lease Extended">
                      <Dropdown.Item eventKey='start-notice-period' onClick={() => this.handleNoticePeriodButtonClick()} disabled={!this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')}>Initiate Notice Period</Dropdown.Item>
                      <Dropdown.Item eventKey='extend-lease' onClick={() => this.handleleaseExtensionsButtonClick()}>Extend Lease</Dropdown.Item>
                      <Dropdown.Item eventKey='start-move-out' onClick={() => this.handleMoveOutInitiationButtonClick()} disabled={!this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')}>Start Move-out Process</Dropdown.Item>
                    </DropdownButton>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === 'YES' && leaseProfile.lease.moveOutInitiationStatus === 'YES' && leaseProfile.lease.moveOutTerminalStatus === 'YES' &&
                    leaseProfile.lease.moveOutDate !== null &&
                    <Button variant="outline-secondary" disabled>Moved-out</Button>
                  }
                </Col>
              </Row>
            </Container>
          </Jumbotron>
          <Card>
            <Card.Body className="">
              <Tabs defaultActiveKey="leaseTerms" id="lease-termination-tabs" variant="pills" className="mb-3" onSelect={(key) => this.handleSelect(key)}>
                <Tab eventKey="leaseTerms" title="Lease Details">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />and then continue working on this lease.
                    </Card.Body>
                  </Card>}
                  {
                    leaseProfile && !leaseProfile.lease &&
                    <p>{constants.LOADING_DATA}</p>
                  }
                  {
                    leaseProfile && leaseProfile.lease && leaseProfile.lease.length === 0 &&
                    <p>{constants.ZERO_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveOutInitiationStatus === "YES" && leaseProfile.lease.moveOutTerminalStatus === null && <Card className="my-3" bg={'danger'} style={{ 'color': 'white' }}>
                    <Card.Header style={{ 'backgroundColor': '#fd397a' }} >
                      <h5 >Move-Out in Process</h5>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col>
                          <p className="mb-0"><strong>Started on: </strong>{formatDate(leaseProfile.lease?.moveOutInitiationStatusMarkedAt)}</p>
                        </Col>
                        <Col xs={4}>
                          <Button className="pull-right" variant="primary" size="sm" onClick={() => this.handleMoveOutConfirmButtonClick()}>End Move-Out Process</Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>}
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveOutInitiationStatus === "YES" && leaseProfile.lease.moveOutTerminalStatus === 'YES' && <Card className="my-3">
                    <Card.Header>
                      <h5>Move-out</h5>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col>
                          <p className="mb-0"><strong>Started on: </strong>{formatDate(leaseProfile.lease?.moveOutInitiationStatusMarkedAt)}</p>
                          <p className="mb-0"><strong>Marked as ended on: </strong>{formatDate(leaseProfile.lease?.moveOutTerminalStatusMarkedAt)}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>}
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease?.noticePeriods[leaseProfile?.lease?.noticePeriods?.length - 1] && leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods?.length - 1]?.terminalStatus === null && <Card className="my-3" bg={'warning'} style={{ 'color': 'white' }}>
                    <Card.Header style={{ 'backgroundColor': '#ffb822', 'color': 'white' }}>
                      <h5>Notice Period in Process</h5>
                      <p className="mb-0 mt-2 small"> {`Created by: ${leaseProfile.lease.noticePeriods[leaseProfile.lease.noticePeriods.length - 1]?.creator.fullName} on  ${formatDate(leaseProfile.lease?.noticePeriods[leaseProfile.lease.noticePeriods.length - 1]?.createdAt)}`}</p>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col className='pb-0' xs={8}>
                          <p className="mb-0"><strong>Initiated by: </strong>{leaseProfile.lease?.noticePeriods[leaseProfile.lease.noticePeriods.length - 1]?.initiator.fullName}</p>
                          <p className="mb-0"><strong>Ends on: </strong>{formatDate(leaseProfile.lease?.noticePeriods[leaseProfile.lease.noticePeriods.length - 1]?.noticePeriodEndDate)}</p>
                        </Col>
                        <Col xs={4}>
                          <Button className="pull-right" variant="primary" size="sm" onClick={() => this.handleNoticePeriodTerminationButtonClick()}>Confirm Notice Period</Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>}
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1] && leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1]?.terminalStatus === 'YES' && <Card className="my-3">
                    <Card.Header>
                      <h5>Notice Period</h5>
                      <p className="mb-0 mt-2 small"> {`Created by: ${leaseProfile.lease?.noticePeriods[leaseProfile.lease.noticePeriods.length - 1]?.creator.fullName} on  ${formatDate(leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1]?.createdAt)}`}</p>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col className='pb-0' xs={8}>
                          <p className="mb-0"><strong>Initiated by: </strong>{leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1]?.initiator.fullName}</p>
                          <p className="mb-0"><strong>Ends on: </strong>{formatDate(leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1]?.noticePeriodEndDate)}</p>
                          <p className="mb-0"><strong>Marked as ended by: </strong>{leaseProfile.lease?.noticePeriods[leaseProfile.lease?.noticePeriods.length - 1]?.terminalStatusMarker.fullName}<strong> on: </strong>{formatDate(leaseProfile.lease?.noticePeriods[leaseProfile.lease.noticePeriods.length - 1].terminalStatusMarkedAt)}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>}
                  {leaseProfile && leaseProfile?.lease && leaseProfile.lease?.leaseExtensions && leaseProfile.lease?.leaseExtensions.slice(0).reverse().map((leaseExtension, index) => (
                    <React.Fragment key={"le" + index}>
                      {leaseExtension.terminalStatus === null ?
                        <>
                          <Card className="my-3" bg={'success'} style={{ 'color': 'white' }}>
                            <Card.Header style={{ 'backgroundColor': '#0abb87' }}>
                              <h5>Lease Extension in Process</h5>
                              <p className="mb-0 small"> {`Created by: ${leaseExtension.creator.fullName} on ${formatDate(leaseExtension.createdAt)}`}</p>
                            </Card.Header>
                            <Card.Body>
                              <Row className="mb-3">
                                <Col xs={12}><Button className="pull-right" variant="primary" size="sm" onClick={() => this.handleLeaseExtensionTerminalButtonClick()}>End Lease Extension Process</Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card></> :
                        <>
                          <Card className="my-3">
                            <Card.Header>
                              <h5>Lease Extensions</h5>
                              <p className="mb-0 small"> {`Created by: ${leaseExtension.creator.fullName} on ${formatDate(leaseExtension.createdAt)}`}</p>
                            </Card.Header>
                            <Card.Body>
                              <Row className="mb-3">
                                <Col xs={8}>
                                  <p className="mb-0"><strong>Previous Lease End Date: </strong>{formatDate(leaseExtension.previousLeaseEndDate)}</p>
                                  <p className="mb-0"><strong>Extended Lease End Date: </strong>{formatDate(leaseExtension.extendedLeaseEndDate)}</p>
                                  <p className="mb-0"><strong>Marked as completed by: </strong>{leaseExtension.terminalStatusMarker.fullName}<strong> on: </strong>{formatDate(leaseExtension.terminalStatusMarkedAt)}</p>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </>
                      }
                    </React.Fragment>
                  ))}
                  {leaseProfile && leaseProfile.lease && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={10}>
                          <h5>Lease Details</h5>
                          <p className="small mb-0">Lease terms as per agreement</p>
                        </Col>
                        <Col xs={2}>
                          {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveOutTerminalStatus !== 'YES' && <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleEditLeaseButtonClick()}><i className="flaticon2-edit"></i></Button>}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xs={12}><p className="text-muted small mb-1">For House Offering ID #{leaseProfile.lease.houseOffering.id} | Property Management Status: {leaseProfile.lease.houseOffering.house.propertyManagementStatus}</p></Col>
                      </Row>
                      <Row >
                        <Col xs={6} ><p className="text-muted mb-1"><strong>Beats Listing ID</strong> #{leaseProfile.lease.houseOffering.beatsListingIdentifier}</p></Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <p className="mb-0" ><strong>Moved-in:</strong> {leaseProfile.lease.moveInDate ? formatDate(leaseProfile.lease.moveInDate) : <span className="text-warning">Not Set</span>}</p>
                        </Col>
                        <Col xs={6}>
                          <p className="mb-0"><strong>Moved-out:</strong> {leaseProfile.lease.moveOutDate ? formatDate(leaseProfile.lease.moveOutDate) : <span className="text-warning">Not Set</span>}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}><strong>Expected Move-in Date:</strong> {leaseProfile.lease.expectedMoveInDate !== null ? formatDate(leaseProfile.lease.expectedMoveInDate) : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Expected Move-out Date:</strong> {leaseProfile.lease.expectedMoveOutDate !== null ? formatDate(leaseProfile.lease.expectedMoveOutDate) : <span className="text-warning">Not Set</span>}</Col>
                        <Col className='offset-md-6' xs={6}><strong>Move-out Reconciliation File:</strong> {leaseProfile.lease.moveOutReconciliationFileUrl !== null ? <Button target="_blank" rel="noopener noreferrer" variant="clean" size="sm" href={leaseProfile.lease.moveOutReconciliationFileUrl}><i className='flaticon-doc'></i></Button> : <span className="text-warning">Not Set</span>}</Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={6}><strong>Start Date:</strong> {leaseProfile.lease.leaseStartDate !== null ? formatDate(leaseProfile.lease.leaseStartDate) : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>End Date:</strong> {leaseProfile.lease.leaseEndDate !== null ? formatDate(leaseProfile.lease.leaseEndDate) : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Lock-in End Date:</strong> {leaseProfile.lease.lockInEndDate !== null ? formatDate(leaseProfile.lease.lockInEndDate) : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Escalation:</strong> {
                          (leaseProfile.lease.rentEscalationPercentage && leaseProfile.lease.rentEscalationPercentage !== null) ? '' + leaseProfile.lease.rentEscalationPercentage.toFixed(2) + '%' : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Notice Period:</strong> {(leaseProfile.lease.noticePeriodQuantity && leaseProfile.lease.noticePeriodUnit !== null) ? leaseProfile.lease.noticePeriodQuantity + " " + leaseProfile.lease.noticePeriodUnit : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Lease Type:</strong> {leaseProfile.lease.leaseType !== null ? leaseProfile.lease.leaseType : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Tenant Type:</strong> {leaseProfile.lease.tenantType !== null ? leaseProfile.lease.tenantType : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Tenant Found by Azuro:</strong> {leaseProfile.lease.tenantFoundByAzuro === true ? "YES" : leaseProfile.lease.tenantFoundByAzuro === false ? "NO" : <span className="text-warning">Not Set</span>}</Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="mt-4">
                          <h5 className="mb-2">Rent Payment Details</h5>
                        </Col>
                        <>
                          <Col xs={6}><strong>Rent Cycle:</strong> {leaseProfile.lease.tenantRentPaymentDay !== null ? ((leaseProfile.lease.tenantRentPaymentDay === 1 && <span> 1 to month-end </span>) || ((leaseProfile.lease.tenantRentPaymentDay > 1 && leaseProfile.lease.tenantRentPaymentDay < 29) && (leaseProfile.lease.tenantRentPaymentDay) + ' to ' + (leaseProfile.lease.tenantRentPaymentDay - 1))) : <span className="text-warning">Not Set</span>}</Col>
                          <Col xs={6}><strong>Flowing through Azuro:</strong> {leaseProfile.lease.isRentFlowing === true ? "YES" : leaseProfile.lease.isRentFlowing === false ? "NO" : <span className="text-warning">Not Set</span>}</Col>
                          <Col xs={6}><strong>Payment Timing:</strong> {leaseProfile.lease.rentPaymentTiming !== null ? leaseProfile.lease.rentPaymentTiming === 'PRE' ? 'Pre-paid' : 'Post-paid' : <span className="text-warning">Not Set</span>}</Col>
                          <Col xs={6}><strong>Send Reminders:</strong> {leaseProfile.lease?.rentReminderSendStatus || <span className="text-warning">Not Set (Default YES)</span>}</Col>
                          <Col xs={6}><strong>Mode of Payment:</strong> {leaseProfile.lease.tenantRentPaymentMode !== null ? leaseProfile.lease.tenantRentPaymentMode : <span className="text-warning">Not Set</span>}</Col>
                          <Col xs={6}><strong>Rent Payment Frequency:</strong> {leaseProfile.lease.tenantRentPaymentBillDurationLength !== null ? (leaseProfile.lease.tenantRentPaymentBillDurationLength === 'QUARTER' ? 'QUARTERLY' : 'MONTHLY') : <span className="text-warning">Not Set</span>}</Col>
                        </>
                      </Row>
                      <Row>
                        <Col xs={12} className="mt-4">
                          <h5 className="mb-2">Rent Periods</h5>
                        </Col>
                        {leaseProfile.lease.rentPeriods.map((rentPeriod, index) => (
                          <React.Fragment key={'rentPeriod' + index}>
                            <Col xs={6}><strong>Period:</strong> {formatDate(rentPeriod.startDate)} to {formatDate(rentPeriod.endDate)}</Col>
                            <Col xs={6}><strong>Rent:</strong> {formatAmount(rentPeriod.rent, rentPeriod.currency)}</Col>
                          </React.Fragment>
                        ))}
                      </Row>
                      <Row>
                        <Col xs={12} className="mt-4">
                          <h5>Security Deposit</h5>
                        </Col>
                        <Col xs={6}><strong>Deposit Scheme:</strong> {leaseProfile.lease.securityDepositType !== null ? leaseProfile.lease.securityDepositType : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Amount:</strong>  {leaseProfile.lease.securityDeposit !== null ? formatAmount(leaseProfile.lease.securityDeposit, leaseProfile.lease.currency) : <span className="text-warning">Not Set</span>}</Col>
                        <Col xs={6}><strong>Flowing through Azuro:</strong> {leaseProfile.lease.isSecurityDepositFlowing !== null ? leaseProfile.lease.isSecurityDepositFlowing ? "YES" : "NO" : <span className="text-warning">Not Set</span>}</Col>
                      </Row>
                    </Card.Body>
                  </Card>}
                  {<Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={10}>
                          <h5>Documents</h5>
                        </Col>
                        <Col xs={2} className="text-right">
                          <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentsButtonClick(null, true)}>Add Document</Button>
                        </Col>
                      </Row>

                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        {leaseProfile?.lease?.leaseDocuments && leaseProfile?.lease?.leaseDocuments?.length === 0 && <Col className="text-center" xs={12}><p>No Documents</p></Col>}
                        <Col xs={4}>
                          {leaseProfile?.lease?.leaseDocuments && leaseProfile?.lease?.leaseDocuments?.length > 0 && leaseProfile?.lease?.leaseDocuments.map((doc, index) =>
                          (
                            <div className='m-2 d-flex' key={'doc' + index}>
                              <Button className='flex-grow-1 text-left' target="_blank" rel="noopener noreferrer" href={doc.file} variant="link" size="sm" title={(doc.code !== null && doc.code !== '') ? "This document is recognized by the system" : "Ancillary documents"}>
                                {doc.name}{doc.documentNumber ? ' (' + doc.documentNumber + ')' : ''}{(doc.code !== null && doc.code !== '') ? <span className="pl-3 la la-check-circle text-success"></span> : <span className="pl-3 la la-file text-muted"></span>}
                              </Button>
                              {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveOutTerminalStatus !== "YES" && this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <Button className='flex-shrink-1' variant="clean" size="sm" onClick={() => this.handleDeleteDocumentButtonClick(doc.id)}>
                                <i className="flaticon2-trash mr-0"></i>
                              </Button>}
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <p className="">Legend:<br />
                            <span className='la la-check-circle text-success'></span> This document is recognized by the system as a whitelisted document.<br />
                            <span className='la la-file text-muted'></span> This document is an ancillary document for the system.<br />
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  }
                  <Card>
                    <Card.Header>
                      {leaseProfile && leaseProfile.lease &&
                        <h5>Reports</h5>}
                    </Card.Header>
                    <Card.Body>
                      {leaseProfile?.lease?.isMoveInReportPublished !== null ?
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Report Name </th>
                              <th>Execution Date</th>
                              <th>Report Published</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Move-in Report</td>
                              <td>{leaseProfile?.lease?.moveInReportExecutionDate ? formatDate(leaseProfile?.lease?.moveInReportExecutionDate) : 'Not Added'}</td>
                              <td>{leaseProfile?.lease?.isMoveInReportPublished ? (leaseProfile?.lease?.isMoveInReportPublished ? 'Yes' : 'No') : 'Not Added'}</td>
                              <td>
                                {leaseProfile?.lease?.isMoveInReportPublished === null &&
                                  <Link to={`/move-in-move-out-report/${leaseId}/edit`} target="_blank">
                                    <Button variant="clean" size="sm">Edit Report</Button>
                                  </ Link>}
                                <Link to={`/move-in-move-out-report/${leaseId}/view`} target="_blank">
                                  <Button variant="clean" size="sm">View Report</Button>
                                </ Link>
                              </td>
                            </tr>
                            {leaseProfile?.lease?.moveOutInitiationStatus === 'YES' &&
                              <tr>
                                <td>Move-out Report</td>
                                <td>{leaseProfile?.lease?.moveOutReportExecutionDate ? formatDate(leaseProfile?.lease?.moveOutReportExecutionDate) : 'Not Added'}</td>
                                <td>{leaseProfile?.lease?.isMoveOutReportPublished ? (leaseProfile?.lease?.isMoveOutReportPublished ? 'Yes' : 'No') : 'Not Added'}</td>
                                <td>
                                  {leaseProfile?.lease?.isMoveOutReportPublished === null &&
                                    <Link to={`/move-in-move-out-report/${leaseId}/edit`} target="_blank">
                                      <Button variant="clean" size="sm">Edit Report</Button>
                                    </ Link>}
                                  <Link to={`/move-in-move-out-report/${leaseId}/view`} target="_blank">
                                    <Button variant="clean" size="sm">View Report</Button>
                                  </ Link>
                                </td>
                              </tr>}
                          </tbody>
                        </Table> :
                        <Row className="mb-3">
                          <Col className="text-center" xs={12}><p>No Published Reports</p></Col>
                        </Row>}
                    </Card.Body>
                  </Card>
                  {<Card className="my-3">
                    <Card.Header>
                      {leaseProfile?.lease?.backgroundVerificationRequests &&
                        <h5>Background Verification Requests</h5>
                      }
                    </Card.Header>
                    <Card.Body>
                      <BackgroundVerificationRequests requests={leaseProfile?.lease?.backgroundVerificationRequests} />
                    </Card.Body>
                  </Card>
                  }
                </Tab>
                <Tab eventKey="parties" title="Parties">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />and then continue working on this lease.
                    </Card.Body>
                  </Card>}
                  {leaseProfile && !leaseProfile.tenants && !leaseProfile.owners &&
                    <p>{constants.LOADING_DATA}</p>
                  }
                  {
                    leaseProfile && leaseProfile.tenants && leaseProfile.owners === 0 &&
                    <p>{constants.ZERO_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.owners && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={10}>
                          <h5>Owners</h5>
                          <p className="small mb-0">Details and documents</p>
                        </Col>
                        {leaseProfile.lease &&
                          <Col xs={2} className="text-right">
                            <AddUserComponent userType="Owner" page="LeaseProfile" houseId={leaseProfile.lease.houseOffering.house.id} leaseProfilePageCallback={this.getLeaseParties} />
                          </Col>}
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {!leaseProfile.owners &&
                        <Row className="mb-3">
                          <Col xs={12} className='text-center'>
                            <p>No Owners Found!</p>
                          </Col>
                        </Row>
                      }
                      {leaseProfile.owners && leaseProfile.owners.map(owner =>
                        <React.Fragment key={'owner' + owner.user.id}>
                          <Row className="mb-3">
                            <Col xs={7}>
                              <div className="kt-widget4">
                                <div className="kt-widget4__item ">
                                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                    <img alt="" src={toAbsoluteUrl(owner.user.profilePicture || '/media/users/default.jpg')} />
                                  </div>
                                  <div className="kt-widget4__info ">
                                    <p className="mb-0 kt-widget4__username">
                                      {owner.user.fullName || ''} {owner.isPrimary && <Badge className="ml-1" variant="warning">Primary</Badge>}
                                    </p>
                                    <p className="kt-widget4__text d-block">
                                      {owner.user.email && owner.user.email !== '' && <span><a target="_blank" rel="noopener noreferrer" title="Click to email" href={"mailto:" + owner.user.email}>{owner.user.email}</a></span>}
                                      {owner.user?.phoneNumbers && <span className="ml-3">
                                        <span> | +{owner?.user.phoneNumbers[0]?.countryCode}-{owner?.user.phoneNumbers[0]?.number}</span>
                                        <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"tel:" + owner?.user.phoneNumbers[0]?.countryCode + owner?.user.phoneNumbers[0]?.number} title="Click to Call">
                                          <i className="la la-phone mr-0"></i>
                                        </a>
                                        <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"https://wa.me/" + owner?.user.phoneNumbers[0]?.countryCode + owner?.user.phoneNumbers[0]?.number + ""} title="Click to WhatsApp">
                                          <i className="flaticon-whatsapp mr-0"></i>
                                        </a>
                                      </span>}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={3} className="text-right">
                              <GenerateUserCredentials
                                user={owner.user}
                                isLeaseProfile={true}
                                generateUserCredentialsParentCallback={this.handleGenerateUserCredentialsData} />
                            </Col>
                            <Col xs={2} className="text-right">
                              <DropdownButton id="dropdown-basic-button" size="sm" variant="outline-primary" title="Change">
                                <Dropdown.Item eventKey='edit Owner' onClick={() => this.handleEditUserButtonClick('Owner', owner.user.id)}>Edit User</Dropdown.Item>
                                {!owner.isPrimary && <Dropdown.Item eventKey='mark as primary' onClick={() => this.handleMarkPrimaryUserButtonClick('Owner', owner.id)}>Mark as Primary Owner </Dropdown.Item>}
                                {allowedUserEmails.includes(this.props.userDetails.email) && <Dropdown.Item eventKey='remove owner' onClick={() => this.handleDeleteLeaseTenantParticipationButtonClick('Owner', owner.id)}>Remove Owner from this Property</Dropdown.Item>}
                                <Dropdown.Item as="a"
                                  href={"https://wa.me/" + owner?.user.phoneNumbers[0]?.countryCode + owner?.user.phoneNumbers[0]?.number + `?text=${encodeURIComponent('Please share your experience by reviewing us via this link: https://bit.ly/4bwzhDW')}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Click to WhatsApp"
                                >
                                  Send Google Review Link
                                </Dropdown.Item>
                              </DropdownButton>
                            </Col>
                            <Col xs={12}>
                              <p className="kt-widget4__text small text-muted">Occupation: {owner.user.worksAs || 'Unknown Designation'} at {owner.user.worksAt || 'Unknown Company'}</p>
                              <p className="kt-widget4__text mb-0"><i className="la la-info-circle mr-2"></i>This user is {owner.propertiesLeaseCountArr.join(" & ")}.</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} className="border-right">
                              <UserBankAccounts bankAccounts={owner.user.bankAccounts} userId={owner.user.id} page="LeaseProfile" leaseProfilePageCallback={this.getLeaseParties} existingUsers={leaseProfile.owners} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                  <h6>Documents</h6>
                                </Col>
                                {leaseProfile && leaseProfile.lease &&
                                  <Col xs={6} className="text-right">
                                    <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentsButtonClick(owner.user.id)}>Add Document</Button>
                                  </Col>}
                                {owner.user.documents && owner.user.documents.length === 0 && <Col className="text-center" xs={12}><p>No Documents</p></Col>}
                                <Col xs={12}>
                                  {owner.user.documents && owner.user.documents.length > 0 && owner.user.documents.map(doc =>
                                  (
                                    <div className='m-2 d-flex' key={doc.id}>
                                      <Button className='flex-grow-1 text-left' target="_blank" rel="noopener noreferrer" href={doc.file} variant="link" size="sm">{doc.name}{doc.documentNumber ? ' (' + doc.documentNumber + ')' : ''}</Button>
                                      {leaseProfile && leaseProfile.lease && this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                                        <Button className='flex-shrink-1' variant="clean" size="sm" onClick={() => this.handleDeleteDocumentButtonClick(doc.id)}>
                                          <i className="flaticon2-trash mr-0"></i>
                                        </Button>}
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <hr />
                        </React.Fragment>
                      )
                      }
                    </Card.Body>
                  </Card>
                  }
                  {leaseProfile && leaseProfile.tenants && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={10}>
                          <h5>Tenants</h5>
                          <p className="small mb-0">Details and documents</p>
                        </Col>
                        {leaseProfile.lease &&
                          <Col xs={2} className="text-right">
                            <AddUserComponent userType="Tenant" page="LeaseProfile" leaseId={leaseProfile.lease.id} leaseProfilePageCallback={this.getLeaseParties} />
                          </Col>}
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {
                        !leaseProfile.tenants &&
                        <Row className="mb-3">
                          <Col xs={12} className='text-center'>
                            <p>No Tenants Found!</p>
                          </Col>
                        </Row>
                      }
                      {leaseProfile.tenants && leaseProfile.tenants.map(tenant =>
                        <React.Fragment key={'tenant' + tenant.user.id}>
                          <Row className="mb-3">
                            <Col xs={7}>
                              <div className="kt-widget4">
                                <div className="kt-widget4__item ">
                                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                    <img alt="" src={toAbsoluteUrl(tenant.user.profilePicture || '/media/users/default.jpg')} />
                                  </div>
                                  <div className="kt-widget4__info ">
                                    <p className="mb-0 kt-widget4__username">
                                      {tenant.user.fullName || ''} {tenant.isPrimary && <Badge className="ml-1" variant="warning">Primary</Badge>}
                                    </p>
                                    <p className="kt-widget4__text d-block">
                                      {tenant.user.email && tenant.user.email !== '' && <span><a target="_blank" rel="noopener noreferrer" title="Click to email" href={"mailto:" + tenant.user.email}>{tenant.user.email}</a></span>}
                                      {tenant?.user.phoneNumbers && <span className="ml-3">
                                        <span> | +{tenant?.user.phoneNumbers[0]?.countryCode}-{tenant?.user.phoneNumbers[0]?.number}</span>
                                        <a className="btn btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"tel:" + tenant?.user.phoneNumbers[0]?.countryCode + tenant?.user.phoneNumbers[0]?.number} title="Click to Call">
                                          <i className="la la-phone mr-0"></i>
                                        </a>
                                        <a className="btn btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"https://wa.me/" + tenant?.user.phoneNumbers[0]?.countryCode + tenant?.user.phoneNumbers[0]?.number + ""} title="Click to WhatsApp">
                                          <i className="flaticon-whatsapp mr-0"></i>
                                        </a>
                                      </span>}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={4} md={3} className="text-right">
                              <GenerateUserCredentials
                                user={tenant.user}
                                isLeaseProfile={true}
                                generateUserCredentialsParentCallback={this.handleGenerateUserCredentialsData}
                              />
                            </Col>
                            <Col xs={1} md={2} className="text-right">
                              <DropdownButton id="dropdown-basic-button" size="sm" variant="outline-primary" title="Change">
                                <Dropdown.Item eventKey='edit tenant' onClick={() => this.handleEditUserButtonClick('Tenant', tenant.user.id)}>Edit User</Dropdown.Item>
                                {!tenant.isPrimary && <Dropdown.Item eventKey='mark as primary' onClick={() => this.handleMarkPrimaryUserButtonClick('Tenant', tenant.id)}>Mark as Primary Tenant </Dropdown.Item>}
                                {allowedUserEmails.includes(this.props.userDetails.email) && <Dropdown.Item eventKey='remove tenant' onClick={() => this.handleDeleteLeaseTenantParticipationButtonClick('Tenant', tenant.id)}>Remove tenant from this Lease</Dropdown.Item>}
                                <Dropdown.Item as="a"
                                  href={"https://wa.me/" + tenant?.user.phoneNumbers[0]?.countryCode + tenant?.user.phoneNumbers[0]?.number + `?text=${encodeURIComponent('Please share your experience by reviewing us via this link: https://bit.ly/4bwzhDW')}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Click to WhatsApp"
                                >
                                  Send Google Review Link
                                </Dropdown.Item>
                              </DropdownButton>
                            </Col>
                            <Col xs={12}>
                              <p className="kt-widget4__text">Occupation: {tenant.user.worksAs || 'Unknown Designation'} at {tenant.user.worksAt || 'Unknown Company'}</p>
                              <p className="small text-muted mb-0"><i className="la la-info-circle mr-2"></i>This user is {tenant.propertiesLeaseCountArr.join(" & ")}.</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} className="border-right">
                              <UserBankAccounts bankAccounts={tenant.user.bankAccounts} userId={tenant.user.id} page="LeaseProfile" leaseProfilePageCallback={this.getLeaseParties} existingUsers={leaseProfile.tenants} />
                            </Col>
                            <Col xs={6}>
                              <Row>
                                <Col xs={12} md={6} className="d-flex align-items-center">
                                  <h6>Documents</h6>
                                </Col>
                                {leaseProfile && leaseProfile.lease &&
                                  <Col xs={6} className="text-right">
                                    <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentsButtonClick(tenant.user.id)}>Add Document</Button>
                                  </Col>}
                                {tenant.user.documents && tenant.user.documents.length === 0 && <Col className="text-center" xs={12}><p>No Documents</p></Col>}
                                <Col xs={12}>
                                  {tenant.user.documents && tenant.user.documents.length > 0 && tenant.user.documents.map(doc =>
                                  (
                                    <div className='m-2 d-flex' key={doc.id}>
                                      <Button className='flex-grow-1 text-left' target="_blank" rel="noopener noreferrer" href={doc.file} variant="link" size="sm">{doc.name}{doc.documentNumber ? ' (' + doc.documentNumber + ')' : ''}</Button>
                                      {leaseProfile && leaseProfile.lease && this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                                        <Button className='flex-shrink-1' variant="clean" size="sm" onClick={() => this.handleDeleteDocumentButtonClick(doc.id)}>
                                          <i className="flaticon2-trash mr-0"></i>
                                        </Button>}
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <hr />
                        </React.Fragment>
                      )}
                    </Card.Body>
                  </Card>}
                </Tab>
                <Tab eventKey="documentation" title="Documentation">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />to continue adding Documentations for this lease.
                    </Card.Body>
                  </Card>}
                  {leaseProfile && !leaseProfile.documentationData &&
                    <p>{constants.LOADING_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.documentationData && leaseProfile.lease.documentationData === 0 &&
                    <p>{constants.ZERO_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.documentationData && <Card className="my-3">
                    <Card.Header>
                      <Row className="py-2 px-3 azuro-table-header">
                        <Col xs={9}>
                          <h5>Documentation</h5>
                          <p className="small mb-0">All documentation related work should be added here</p>
                        </Col>
                        {leaseProfile && leaseProfile.lease &&
                          <Col xs={3} className="text-right">
                            {leaseProfile.lease.moveInTerminalStatus !== "YES" && <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentationButtonClick({ isPreMoveInDocumentationWork: true })}>Add Pre Move-in Documentation</Button>}
                            {
                              activeLeaseExtension &&
                              <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentationButtonClick({ isLeaseExtensionDocumentationWork: true })}>Add Lease Extension Documentation</Button>
                            }
                            {(leaseProfile?.lease?.moveOutTerminalStatus === null) && (leaseProfile?.lease?.moveOutInitiationStatus !== null) &&
                              <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentationButtonClick({ isMoveOutDocumentationWork: true })}>Add Move-out Documentation</Button>
                            }
                          </Col>}
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {leaseProfile && leaseProfile.documentationData && leaseProfile.documentationData.map((documentation, index) =>
                        documentation.terminalStatus === null ?
                          <>
                            <Row className="mb-3">
                              <Col xs={9}>
                                <h5>{documentation?.name}
                                  {documentation.isPreMoveInWork && <Badge variant="primary" className='ml-3'>Pre Move-in</Badge>}
                                  {documentation.isMoveOutWork && <Badge variant="success" className='ml-3'>Move-out</Badge>}
                                  {documentation.leaseExtensionId && <Badge variant="info" className='ml-3'>Lease Extension</Badge>}
                                </h5>
                              </Col>
                              <Col xs={3} className="text-right">
                                {documentation.terminalStatus === null && documentation.code === 'BACKGROUND_VERIFICATION' &&
                                  <Button variant="clean" title="Request Background Verification" onClick={() => this.handleRequestBackgroundVerificationButtonClick(
                                    {
                                      backgroundVerificationRequest: {
                                        workId: documentation.id,
                                        leaseId: documentation.lease?.id,
                                        houseId: documentation.houseId
                                      }
                                    }
                                  )}>
                                    <span>Raise New Request</span>
                                  </Button>
                                }
                                {documentation.terminalStatus === null && documentation.code === 'LEASE_AGREEMENT' && documentation.escrowAgreementIdentifier === null &&
                                  <Button variant="clean" title="Generate Escrow Agreement" onClick={() => this.handleGenerateEscrowAgreementButtonClick({ workId: documentation.id, leaseId: documentation.lease?.id, houseId: documentation.houseId })}>
                                    <span>Generate Escrow Agreement</span>
                                  </Button>
                                }
                                {documentation.terminalStatus === null && documentation.code === 'LEASE_AGREEMENT' && documentation.escrowAgreementIdentifier !== null &&
                                  <a className="btn btn-primary" href={documentation.escrowAgreementUrl} target="_blank" rel="noopener noreferrer">
                                    <span>View/Edit Agreement #{documentation.escrowAgreementIdentifier}</span>
                                  </a>
                                }
                              </Col>
                            </Row>
                            {documentation.code === 'BACKGROUND_VERIFICATION' &&
                              documentation.backgroundVerificationRequests &&
                              <Row className="mb-4" key={0}>
                                <BackgroundVerificationRequests requests={documentation.backgroundVerificationRequests} />
                              </Row>}
                            <SchedulesListComponent schedules={documentation.schedules || []} allowAddSchedules={
                              (documentation.terminalStatus === null ? true : false)
                            }
                              preMoveInWorks={documentation}
                              isDocumentation={"DOCUMENTATION"}
                              parentCallback={this.handleChildData}
                              latestTenantAndOnwers={latestTenantAndOnwers && documentation.code === 'LEASE_AGREEMENT' ? latestTenantAndOnwers : undefined}
                              house={leaseProfile?.lease?.houseOffering?.house}
                            />
                            <TasksListComponent tasks={documentation.tasks || []} allowAddTasks={
                              (documentation.terminalStatus === null ? true : false)
                            }
                              preMoveInWorksId={documentation.id}
                              // houseId={req.house && req.house.id}
                              isDocumentation={"DOCUMENTATION"}
                              parentCallback={this.handleChildData}
                              // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                              approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                              houseId={leaseProfile?.lease?.houseOffering?.house?.id}
                            />
                            <NotesComponent notes={documentation.notes || []}
                              allowAddNotes={
                                (documentation.terminalStatus === null ? true : false)
                              }
                              preMoveInWorksId={documentation.id}
                              isDocumentation={"DOCUMENTATION"}
                              parentCallback={this.handleChildData} />
                            <Col xs={12} className="text-right">
                              {documentation.terminalStatus === null &&
                                <Button variant="success" size="sm" title="Mark Completed" onClick={() => this.handleMarkWorkAsCompletedButtonClick(documentation.id, documentation.workCategory.id, documentation.code, documentation.name)}>
                                  {/* <i className="flaticon2-check-mark"></i> */}
                                  <span>Mark Work as Completed</span>
                                </Button>}
                            </Col>
                            <hr />
                          </> :
                          <>
                            <Accordion>
                              <Card className="my-3">
                                <Card.Header>
                                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                    <Row className="py-2 px-3 azuro-table-header">
                                      <Col xs={9}>
                                        <h5 title="Click to expand/collapse">{documentation?.name}
                                          {documentation.isPreMoveInWork && <Badge variant="primary" className='ml-3'>Pre Move-in</Badge>}
                                          {documentation.isMoveOutWork && <Badge variant="success" className='ml-3'>Move-out</Badge>}
                                          {documentation.leaseExtensionId && <Badge variant="info" className='ml-3'>Lease Extension</Badge>}
                                          {<i className="flaticon2-check-mark ml-4 text-success" title="Work Completed"></i>}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse as={Card.Body} eventKey="0">
                                  <Card.Body>
                                    {documentation.code === 'BACKGROUND_VERIFICATION' &&
                                      documentation.backgroundVerificationRequests &&
                                      <Row className="mb-4" key={0}>
                                        <BackgroundVerificationRequests requests={documentation.backgroundVerificationRequests} />
                                      </Row>}
                                    <SchedulesListComponent schedules={documentation.schedules || []} allowAddSchedules={false}
                                      preMoveInWorks={documentation}
                                      isDocumentation={"DOCUMENTATION"}
                                      parentCallback={this.handleChildData}
                                      latestTenantAndOnwers={latestTenantAndOnwers && documentation.code === 'LEASE_AGREEMENT' ? latestTenantAndOnwers : undefined}
                                      house={leaseProfile?.lease?.houseOffering?.house}
                                    />
                                    <TasksListComponent tasks={documentation.tasks || []} allowAddTasks={false}
                                      preMoveInWorksId={documentation.id}
                                      // houseId={req.house && req.house.id}
                                      isDocumentation={"DOCUMENTATION"}
                                      parentCallback={this.handleChildData}
                                      // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                                      approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                                      houseId={leaseProfile?.lease?.houseOffering?.house?.id}
                                    />
                                    <NotesComponent notes={documentation.notes || []}
                                      allowAddNotes={false}
                                      preMoveInWorksId={documentation.id}
                                      isDocumentation={"DOCUMENTATION"}
                                      parentCallback={this.handleChildData} />
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <hr />
                            </Accordion>
                          </>
                      )
                      }
                    </Card.Body>
                  </Card>}
                </Tab>
                <Tab eventKey="furnishing" title="Furnishing &amp; Repairs">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />to continue adding Furnishing &amp; Repairs for this lease.
                    </Card.Body>
                  </Card>}
                  {leaseProfile && !leaseProfile.furnishingData &&
                    <p>{constants.LOADING_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.furnishingData && leaseProfile.lease.furnishingData === 0 &&
                    <p>{constants.ZERO_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.furnishingData && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={9}>
                          <h5>Furnishing &amp; Repairs</h5>
                          <p className="small mb-0">All furnishing & repairs related work should be added here</p>
                        </Col>
                        {leaseProfile && leaseProfile.lease &&
                          <Col xs={3} className="text-right">
                            {leaseProfile?.lease?.moveInTerminalStatus === null &&
                              <Button variant="clean" size="sm" onClick={() => this.handleAddFurnishingButtonClick({ isPreMoveInWork: true })}>Add Pre Move-in Furnishing/Repair</Button>
                            }
                            {activeLeaseExtension &&
                              <Button variant="clean" size="sm" onClick={() => this.handleAddFurnishingButtonClick({ isLeaseExtensionWork: true })}>Add Lease Extension Furnishing/Repair</Button>
                            }
                            {(leaseProfile?.lease?.moveOutTerminalStatus === null) && (leaseProfile?.lease?.moveOutInitiationStatus !== null) &&
                              <Button variant="clean" size="sm" onClick={() => this.handleAddFurnishingButtonClick({ isMoveOutWork: true })}>Add Move-out Furnishing/Repair</Button>
                            }
                          </Col>}
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {leaseProfile && leaseProfile.lease && leaseProfile.furnishingData.map(furnishing =>
                        furnishing.terminalStatus === null ?
                          <>
                            <Row className="mb-3">
                              <Col xs={6} >
                                <h5>{furnishing.name}
                                  {furnishing.isPreMoveInWork && <Badge variant="primary" className='ml-3'>Pre Move-in</Badge>}
                                  {furnishing.isMoveOutWork && <Badge variant="success" className='ml-3'>Move-out</Badge>}
                                  {furnishing.leaseExtensionId && <Badge variant="info" className='ml-3'>Lease Extension</Badge>}</h5>
                              </Col>
                              <Col xs={3} className="text-right">
                                {furnishing.assignee && furnishing.assignee === '' && <Button variant="clean" size="sm">Assign</Button>}
                                {furnishing.assignee && furnishing.assignee !== '' && <Button variant="clean" size="sm">{furnishing.assignee}</Button>}
                              </Col>
                            </Row>
                            <SchedulesListComponent schedules={furnishing.schedules || []} allowAddSchedules={
                              (furnishing.terminalStatus === null ? true : false)
                            }
                              preMoveInWorks={furnishing}
                              isFurnishing={"FURNISHING"}
                              parentCallback={this.handleChildData}
                              house={leaseProfile?.lease?.houseOffering?.house}
                            />
                            <EstimatesComponent
                              estimates={furnishing.costEstimates || []}
                              currency={furnishing.costEstimates && (furnishing.costEstimates.length > 0) && furnishing.costEstimates[0].currency}
                              allowAddEstimates={(furnishing.terminalStatus === null ? true : false)}
                              preMoveInWorksId={furnishing.id}
                              isFurnishing={"FURNISHING"}
                              approverData={latestTenantAndOnwers && latestTenantAndOnwers.length > 0 && latestTenantAndOnwers}
                              parentCallback={this.handleChildData}
                              houseId={furnishing.houseId}
                              isLeaseProfile={true}
                            />
                            <TasksListComponent tasks={furnishing.tasks || []} allowAddTasks={
                              (furnishing.terminalStatus === null ? true : false)
                            }
                              preMoveInWorksId={furnishing.id}
                              isFurnishing={"FURNISHING"}
                              parentCallback={this.handleChildData}
                              // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                              approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                              houseId={leaseProfile?.lease?.houseOffering?.house?.id}
                            />
                            <NotesComponent notes={furnishing.notes || []}
                              allowAddNotes={
                                (furnishing.terminalStatus === null ? true : false)
                              }
                              preMoveInWorksId={furnishing.id}
                              isFurnishing={"FURNISHING"}
                              parentCallback={this.handleChildData} />
                            {furnishing.terminalStatus === null &&
                              <Col xs={12} className="text-right">
                                <Button variant="success" size="sm" title="Mark Completed" onClick={() => this.handleMarkWorkAsCompletedButtonClick(furnishing.id, furnishing.workCategory.id)}>
                                  <span>Mark Work as Completed</span>
                                </Button>
                              </Col>}
                            <hr />
                          </> :
                          <>
                            <Accordion>
                              <Card className="my-3">
                                <Card.Header>
                                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                    <Row className="py-2 px-3 azuro-table-header">
                                      <Col xs={6}>
                                        <h5 title="Click to expand/collapse">{furnishing.name}
                                          {furnishing.isPreMoveInWork && <Badge variant="primary" className='ml-3'>Pre Move-in</Badge>}
                                          {furnishing.isMoveOutWork && <Badge variant="success" className='ml-3'>Move-out</Badge>}
                                          {furnishing.leaseExtensionId && <Badge variant="info" className='ml-3'>Lease Extension</Badge>}
                                          <i className="flaticon2-check-mark  ml-4 text-success" title="Work Completed"></i>
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                    <SchedulesListComponent schedules={furnishing.schedules || []} allowAddSchedules={
                                      (furnishing.terminalStatus === null ? true : false)
                                    }
                                      preMoveInWorks={furnishing}
                                      isFurnishing={"FURNISHING"}
                                      parentCallback={this.handleChildData}
                                      house={leaseProfile?.lease?.houseOffering?.house}
                                    />
                                    <EstimatesComponent
                                      estimates={furnishing.costEstimates || []}
                                      currency={furnishing.costEstimates && (furnishing.costEstimates.length > 0) && furnishing.costEstimates[0].currency}
                                      allowAddEstimates={(furnishing.terminalStatus === null ? true : false)}
                                      preMoveInWorksId={furnishing.id}
                                      isFurnishing={"FURNISHING"}
                                      approverData={latestTenantAndOnwers && latestTenantAndOnwers.length > 0 && latestTenantAndOnwers}
                                      parentCallback={this.handleChildData}
                                      houseId={furnishing.houseId}
                                      isLeaseProfile={true}
                                    />
                                    <TasksListComponent tasks={furnishing.tasks || []} allowAddTasks={
                                      (furnishing.terminalStatus === null ? true : false)
                                    }
                                      preMoveInWorksId={furnishing.id}
                                      isFurnishing={"FURNISHING"}
                                      parentCallback={this.handleChildData}
                                      // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                                      approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                                      houseId={leaseProfile?.lease?.houseOffering?.house?.id}
                                    />
                                    <NotesComponent notes={furnishing.notes || []}
                                      allowAddNotes={
                                        (furnishing.terminalStatus === null ? true : false)
                                      }
                                      preMoveInWorksId={furnishing.id}
                                      isFurnishing={"FURNISHING"}
                                      parentCallback={this.handleChildData} />
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <hr />
                            </Accordion>
                          </>
                      )
                      }
                    </Card.Body>
                  </Card>}
                </Tab>
                <Tab eventKey="rentPaymentBills" title="Payment Bills">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />and then continue working on this lease.
                    </Card.Body>
                  </Card>}
                  {isRentPaymentBillsTab === true && <RentPaymentBills leaseIdFromLeaseProfile={leaseId} />}
                  {isSecurityDepositBillTab === true && <SecurityDepositBills leaseIdFromLeaseProfile={leaseId} />}
                </Tab>
                <Tab eventKey="moveIn" title="Reports">
                  {leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.lease.moveInInitiationStatus === null && <Card className="my-3">
                    <Card.Body>
                      <span className="text-warning">⚠</span> Update the Lease Status from 'Pre Move-in' to 'Start Move-in Process'<br />to continue adding Reports for this lease.
                    </Card.Body>
                  </Card>}
                  {leaseProfile && !leaseProfile.reportsData &&
                    <p>{constants.LOADING_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.reportsData && leaseProfile.lease.reportsData === 0 &&
                    <p>{constants.ZERO_DATA}</p>
                  }
                  {leaseProfile && leaseProfile.reportsData && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={9}>
                          <h5>Move-in &amp; Move-out Reports</h5>
                          <p className="small mb-0">Schedule tenant move-in and move-out report should be assigned to be PS</p>
                        </Col>
                        <Col xs={3} className="d-flex align-items-center justify-content-end">
                          {
                            // leaseProfile && leaseProfile.reportsData.length === 0 &&
                            <>
                              {leaseProfile.lease.moveInTerminalStatus === null && leaseProfile.reportsData[leaseProfile.reportsData.length - 1]?.workCategory.id !== 5 && <Button variant="clean" size="sm" onClick={() => this.handleCreateMoveInReportButtonClick()}>Create Move-in Report</Button>}
                              {leaseProfile.lease.moveOutInitiationStatus === "YES" && leaseProfile.lease.moveOutTerminalStatus === null && leaseProfile.reportsData[0]?.workCategory?.id !== 7 && <Button variant="clean" size="sm" onClick={() => this.handleCreateMoveOutReportButtonClick()}>Create Move-out Report</Button>}
                            </>
                          }
                          {leaseProfile && leaseProfile.reportsData && leaseProfile.reportsData.assignee && leaseProfile.reportsData.assignee === 'Unassigned' && <Button variant="clean" size="sm">Assign</Button>}
                          {leaseProfile && leaseProfile.reportsData && leaseProfile.reportsData.assignee && leaseProfile.reportsData.assignee !== 'Unassigned' && <Button variant="clean" size="sm">{this.state.leaseProfile.move_in_details.assignee}</Button>}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {leaseProfile.reportsData.map(reportWork =>
                        <>
                          <Row className="mb-3">
                            <Col xs={12}>
                              <h5>{(reportWork.workCategory?.id === 5 && `Move-in Report`) || (reportWork.workCategory?.id === 7 && `Move-out Report`)}</h5>
                            </Col>
                            {reportWork.lease &&
                              <Col xs={12}><strong>Report Date:</strong> {(reportWork.workCategory?.id === 5 && reportWork.lease.moveInReportExecutionDate) || (reportWork.workCategory?.id === 7 && reportWork.lease.moveOutReportExecutionDate)}</Col>}
                            <Col xs={6}>
                              <p><strong>Status: </strong>{(reportWork.workCategory?.id === 5 && (reportWork.lease.isMoveInReportPublished ? "Published" : "Pending")) || (reportWork.workCategory?.id === 7 && reportWork.lease.isMoveOutReportPublished ? "Published" : "Pending")}</p>
                            </Col>
                            <Col xs={6} className="text-right">
                              {((reportWork.workCategory.id === 5 && reportWork.lease.isMoveInReportPublished === null) || (reportWork.workCategory.id === 7 && reportWork.lease.isMoveOutReportPublished === null)) &&
                                <Link to={`/move-in-move-out-report/${reportWork.lease.id}/edit`} target="_blank">
                                  <Button variant="clean" size="sm">Edit Report</Button>
                                </Link>
                              }
                              <Link to={`/move-in-move-out-report/${reportWork.lease.id}/view`} target="_blank">
                                <Button variant="clean" size="sm">View Report</Button>
                              </Link>
                            </Col>
                          </Row>
                          <SchedulesListComponent schedules={reportWork.schedules || []} allowAddSchedules={
                            (reportWork.terminalStatus === null ? true : false)
                          }
                            preMoveInWorks={reportWork}
                            isReport={(reportWork.workCategory.id === 5 && "MOVEINREPORT") || (reportWork.workCategory.id === 7 && "MOVEOUTREPORT")}
                            parentCallback={this.handleChildData}
                            house={leaseProfile?.lease?.houseOffering?.house}
                            isMoveInReportPublished={reportWork.lease.isMoveInReportPublished}
                            isMoveOutReportPublished={reportWork.lease.isMoveOutReportPublished}
                          />
                          <TasksListComponent tasks={reportWork.tasks || []} allowAddTasks={
                            (reportWork.terminalStatus === null ? true : false)
                          }
                            preMoveInWorksId={reportWork.id}
                            isReport={(reportWork.workCategory.id === 5 && "MOVEINREPORT") || (reportWork.workCategory.id === 7 && "MOVEOUTREPORT")}
                            parentCallback={this.handleChildData}
                            // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                            approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                            houseId={leaseProfile?.lease?.houseOffering?.house?.id}
                            isMoveInReportPublished={reportWork.lease.isMoveInReportPublished}
                            isMoveOutReportPublished={reportWork.lease.isMoveOutReportPublished}
                          />
                          <NotesComponent notes={reportWork.notes || []}
                            allowAddNotes={
                              (reportWork.terminalStatus === null ? true : false)
                            }
                            preMoveInWorksId={reportWork.id}
                            isReport={(reportWork.workCategory.id === 5 && "MOVEINREPORT") || (reportWork.workCategory.id === 7 && "MOVEOUTREPORT")}
                            parentCallback={this.handleChildData}
                            isMoveInReportPublished={reportWork.lease.isMoveInReportPublished}
                            isMoveOutReportPublished={reportWork.lease.isMoveOutReportPublished}
                          />
                          <hr />
                        </>
                      )
                      }
                    </Card.Body>
                  </Card>}
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Container>

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddDocumentationWorkModal} onHide={this.handleAddDocumentationButtonClick}>
          <Modal.Header>
            <Modal.Title>Add Documentation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                works: currentUncreatedDocuments,
              }}
              validationSchema={addWorkSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  /* clearing residue values before submitting*/
                  /*step1: extra values*/
                  if (values.works0) delete values.works0;
                  if (values.works1) delete values.works1;
                  if (values.works2) delete values.works2;
                  if (values.works3) delete values.works3;
                  /*step2: unchecked values*/
                  let valuesToSubmit = JSON.parse(JSON.stringify(values));
                  for (let index = 0; index < valuesToSubmit?.works?.length; index++) {
                    if (valuesToSubmit.works[index].isChecked === false) {
                      valuesToSubmit.works.splice(index, 1);
                      index = index - 1;
                    }
                  }
                  /**
                    * Check for leaseExtensionWork and moveOutWork
                    * TODO:
                    *    - Improve the logic for this section
                    */
                  for (let index = 0; index < valuesToSubmit?.works?.length; index++) {
                    if (documentationAction?.isPreMoveInDocumentationWork || documentationAction?.isMoveOutDocumentationWork) {
                      valuesToSubmit.works[index].leaseId = parseInt(leaseId, 10)
                    }
                    if (activeLeaseExtension && documentationAction?.isLeaseExtensionDocumentationWork) {
                      valuesToSubmit.works[index].leaseExtensionId = parseInt(activeLeaseExtension.id, 10)
                    }
                    if (documentationAction?.isPreMoveInDocumentationWork) {
                      valuesToSubmit.works[index].isPreMoveInWork = documentationAction?.isPreMoveInDocumentationWork
                    } else {
                      delete valuesToSubmit.works[index].isPreMoveInWork;
                    }
                    if (documentationAction?.isMoveOutDocumentationWork) {
                      valuesToSubmit.works[index].isMoveOutWork = documentationAction?.isMoveOutDocumentationWork
                    }
                  }
                  const result = await leaseProfileServices.createLeaseWorks(valuesToSubmit);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Document for Work added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddDocumentationWorkModal: !state.showAddDocumentationWorkModal }));
                    await this.getLeaseDocumentation();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <FieldArray
                      name="works"
                      render={arrayHelpers => (
                        <>
                          {values.works.map((thisWork, index) => (
                            <Row key={'w' + index}>
                              <Col xs={10}>
                                {thisWork.code !== undefined &&
                                  <Form.Group controlId={`works${index}`}>
                                    <Form.Check type="checkbox">
                                      <Form.Check.Input type="checkbox"
                                        checked={thisWork.isChecked}
                                        value={{ thisWork }}
                                        onChange={handleChange}
                                        onClick={() => thisWork.isChecked = !thisWork.isChecked} />
                                      <Form.Check.Label className="ml-3 mt-1">{thisWork.name}</Form.Check.Label>
                                    </Form.Check>
                                  </Form.Group>
                                }
                                {thisWork.code === undefined &&
                                  <Form.Group controlId={`works-${index}`}>
                                    <Form.Control type="text" name={`works[${index}].name`} onChange={handleChange} onBlur={handleBlur} value={thisWork.name} />
                                    {errors.works && touched.works && errors.works[index] && touched.works[index] && <Form.Text className="text-danger">
                                      {errors.works[index].name && touched.works[index].name && errors.works[index].name}
                                    </Form.Text>}
                                  </Form.Group>
                                }
                              </Col>
                              <Col xs={2}>
                                <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                              </Col>
                            </Row>
                          ))}
                          {typeof errors.preMoveInWork === 'string' ? <p className="text-danger mb-2">{errors.works}</p> : null}
                          <Button variant="secondary" onClick={() => arrayHelpers.push(this.state.sampleEmptyOtherDocument)}>Add Another Document</Button>
                        </>
                      )}
                    />
                    <Row>
                      <Col xs={12}>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddDocumentationButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showAddFurnishingWorkModal} onHide={this.handleAddFurnishingButtonClick}>
          <Modal.Header>
            <Modal.Title>Add Furnishing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                works: [
                  {
                    leaseId: (furnishingAction?.isPreMoveInWork || furnishingAction?.isMoveOutWork) ? parseInt(leaseId, 10) : undefined,
                    leaseExtensionId: (activeLeaseExtension && furnishingAction?.isLeaseExtensionWork) ? parseInt(activeLeaseExtension.id, 10) : undefined,
                    workCategoryId: 4,
                    name: '',
                    isPreMoveInWork: furnishingAction?.isPreMoveInWork || undefined,
                    isMoveOutWork: furnishingAction?.isMoveOutWork || undefined,
                  }
                ]
              }}
              validationSchema={addWorkSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.works.forEach((thisWork, index) => {
                    thisWork.leaseId = (furnishingAction?.isPreMoveInWork || furnishingAction?.isMoveOutWork) && parseInt(leaseId, 10);
                    thisWork.leaseExtensionId = (activeLeaseExtension && furnishingAction?.isLeaseExtensionWork) && parseInt(activeLeaseExtension.id, 10);
                    thisWork.workCategoryId = 4;
                    thisWork.isPreMoveInWork = furnishingAction?.isPreMoveInWork || undefined;
                    thisWork.isMoveOutWork = furnishingAction?.isMoveOutWork || undefined;
                  })
                  const result = await leaseProfileServices.createLeaseWorks(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Furnishing Work added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddFurnishingWorkModal: !state.showAddFurnishingWorkModal }));
                    await this.getLeaseFurnishing();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <FieldArray
                      name="works"
                      render={arrayHelpers => (
                        <>
                          {values.works.map((thisWork, index) => (
                            <Row key={'w' + index}>
                              <Col xs={12}>
                                <Form.Label>Furnishing Name</Form.Label>
                              </Col>
                              <Col xs={10}>
                                <Form.Group controlId={`works[${index}].name`}>
                                  <Form.Control type="text" name={`works[${index}].name`} onChange={handleChange} onBlur={handleBlur} value={thisWork && thisWork.name} />
                                  {errors.works && touched.works && errors.works[index] && touched.works[index] && <Form.Text className="text-danger">
                                    {errors.works[index].name && touched.works[index].name && errors.works[index].name}
                                  </Form.Text>}</Form.Group>
                              </Col>
                              <Col xs={2}>
                                <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                              </Col>
                            </Row>
                          ))}
                          {typeof errors.preMoveInWork === 'string' ? <p className="text-danger mb-2">{errors.works}</p> : null}
                          <Button variant="secondary" onClick={() => arrayHelpers.push(this.state.sampleEmptyFurnishing)}>Add Another Furnishing</Button>
                        </>
                      )}
                    />
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddFurnishingButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 3} show={this.state.showEditUserModal} onHide={() => this.handleEditUserButtonClick(null, null)} >
          <Modal.Header closeButton>
            <Modal.Title>Edit {this.state.userType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                file: undefined,
                fullName: (userToBeEdited && userToBeEdited.fullName) || '',
                email: (userToBeEdited && userToBeEdited.email) || '',
                worksAs: (userToBeEdited && userToBeEdited.worksAs) || '',
                worksAt: (userToBeEdited && userToBeEdited.worksAt) || '',
                phoneNumbers: (userToBeEdited && userToBeEdited.phoneNumbers) || [{
                  countryCode: undefined,
                  number: undefined,
                  isPrimary: undefined
                }],
              }}
              // TODO: Remove this validation as it is doing nothing. Replace with a better validationSchema.
              validate={values => {
                const errors = {};
                // if (!values.preMoveInWorks.length === 0) {
                //   errors.preMoveInWorks = 'Atlessast one Furnishing Name is required';
                // }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let formData = new FormData();
                  values.file && formData.append('file', values.file);
                  values.fullName && formData.append('user[fullName]', values.fullName);
                  values.email && formData.append('user[email]', values.email);
                  formData.append('user[worksAs]', values.worksAs);
                  formData.append('user[worksAt]', values.worksAt);
                  values.phoneNumbers && (values.phoneNumbers).forEach((phone, index) => {
                    formData.append(`user[phoneNumbers][${index}][countryCode]`, phone.countryCode);
                    formData.append(`user[phoneNumbers][${index}][number]`, phone.number);
                    formData.append(`user[phoneNumbers][${index}][isPrimary]`, (phone.isPrimary === 'true' || phone.isPrimary === true));
                  });
                  const result = await leaseProfileServices.editUsers(userToBeEdited && userToBeEdited.id, formData);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: this.state.userType + ` Info updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showEditUserModal: !state.showEditUserModal }));
                    this.getLeaseParties();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {!this.state.userConfirmation && userToBeEdited.houseOwnerships.length + userToBeEdited.leaseTenantParticipations.length > 1 ?
                      <Row>
                        <Col xs={12}>
                          <h5>Please Note</h5>
                          <p>Any changes to this user's details will reflect in all Properties where this user is an Owner and all Leases where this user is a Tenant.
                          </p>
                        </Col>
                        <Col xs={12} className="text-right">
                          <Button onClick={() => this.setState({ userConfirmation: true })}>Continue</Button>
                        </Col>
                      </Row>
                      :
                      <>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <Form.Label>Change Profile Picture</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="mb-2">
                                <input id="file" name="file" type="file" multiple onChange={(event) => {
                                  setFieldValue("file", event.currentTarget.files[0]);
                                }} />
                                {errors.file && touched.file && <Form.Text className="text-danger">
                                  {errors.file}
                                </Form.Text>}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="fullName">
                              <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
                              <Form.Control type="text" name='fullName' onChange={handleChange} onBlur={handleBlur} value={values.fullName} pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"} />
                            </Form.Group>
                          </Col>
                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="email">
                              <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                              {userToBeEdited.email === null && <Form.Control type="text" name='email' onChange={handleChange} onBlur={handleBlur} value={values.email} />}
                              {userToBeEdited.email !== null && <Form.Control type="text" name='email' readOnly value={values.email} />}
                              {userToBeEdited.email !== null && <Form.Text className='text-muted'>Edit Disabled</Form.Text>}
                            </Form.Group>
                          </Col>
                          <Col xs={6} className='mt-2'>
                            <Form.Group controlId="worksAs">
                              <Form.Label>Works As</Form.Label>
                              <Form.Control type="text" name='worksAs' onChange={handleChange} onBlur={handleBlur} value={values.worksAs} />
                            </Form.Group>
                          </Col>
                          <Col xs={6} className='mt-2'>
                            <Form.Group controlId="worksAt">
                              <Form.Label>Works At</Form.Label>
                              <Form.Control type="text" name='worksAt' onChange={handleChange} onBlur={handleBlur} value={values.worksAt} />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                            <FieldArray
                              name="phoneNumbers"
                              render={arrayHelpers => (
                                <div>
                                  {values.phoneNumbers.map((phoneNumber, index) =>
                                  (<Row key={index}>
                                    <Col xs={3}>
                                      <Form.Group controlId={"countryCode" + index}>
                                        <Form.Label>Country Code <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" name={`phoneNumbers[${index}].countryCode`} required="required" readOnly value={phoneNumber.countryCode} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                      <Form.Group controlId={"number" + index}>
                                        <Form.Label>Number <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" name={`phoneNumbers[${index}].number`} required="required" readOnly value={phoneNumber.number} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group controlId={"isPrimary" + index}>
                                        <Form.Label>Primary <span className="text-danger">*</span></Form.Label>
                                        <Form.Control as="select" name={`phoneNumbers[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.isPrimary}>
                                          <option value={null}>{constants.DEFAULT_OPTION}</option>
                                          <option value={true}>YES</option>
                                          <option value={false}>NO</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={2} className='d-flex align-self-center justify-content-right'>
                                      {/* <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button> */}
                                    </Col>
                                    <Col xs={12}>
                                      <Form.Text className='text-muted'>You can edit the Phone Number's Primary status. However, editing the Phone Number itself is disabled. </Form.Text>
                                    </Col>
                                  </Row>)
                                  )}
                                  {/* <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({
                                    countryCode: null,
                                    number: null,
                                    isPrimary: null
                                  })}>Add Phone Number</Button> */}
                                </div>
                              )}
                            />
                            <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={() => this.handleEditUserButtonClick(null, null)}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 4} show={this.state.showAddDocumentsModal} onHide={this.handleAddDocumentsButtonClick} >
          <Modal.Header closeButton>
            <Modal.Title>Add Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                leaseId: (this.state.addDocumentDirectly && leaseId) || null,
                userId: this.state.activeUser || null,
                name: null,
                code: null,
                file: undefined,
                documentNumber: undefined,
              }}
              validationSchema={addDocumentSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let formData = new FormData();
                  values.file && formData.append('file', values.file);
                  (values.userId !== null) && formData.append('documents[userId]', values.userId);
                  (values.leaseId !== null) && formData.append('documents[leaseId]', values.leaseId);
                  values.name && formData.append('documents[name]', values.name);
                  switch (values.name) {
                    case 'Aadhaar Card':
                      values.code = 'AADHAAR_CARD'
                      break;
                    case 'Pan Card':
                      values.code = 'PAN_CARD'
                      break;
                    case 'Passport':
                      values.code = 'PASSPORT'
                      break;
                    case 'Driving License':
                      values.code = 'DRIVING_LICENSE'
                      break;
                    case 'Employment Proof':
                      values.code = 'EMPLOYMENT_PROOF'
                      break;
                    case 'Lease Agreement':
                      values.code = 'LEASE_AGREEMENT'
                      break;
                    case 'Society NOC':
                      values.code = 'SOCIETY_NOC';
                      break;
                    case 'Police Verification':
                      values.code = 'POLICE_VERIFICATION';
                      break;
                    default:
                      values.code = null
                      break;
                  }
                  values.code && formData.append('documents[code]', values.code);
                  values.documentNumber && formData.append('documents[documentNumber]', values.documentNumber);
                  const result = await commonServices.createUserDocuments(formData);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.name + ` Document added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddDocumentsModal: !state.showAddDocumentsModal }));
                    (values.userId !== null && values.userId !== undefined) && this.getLeaseParties();
                    (values.leaseId !== null && values.leaseId !== null) && this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {!this.state.documentConfirmation && this.state.userArrayAddDocument?.filter((data) => data?.id === this.state.activeUser)[0]?.houseOwnerships.length + this.state.userArrayAddDocument?.filter((data) => data?.id === this.state.activeUser)[0]?.leaseTenantParticipations.length > 1 ? <Row>
                      <Col xs={12}>
                        <h5>Please Note</h5>
                        <p>Any changes to this user's details will reflect in all Properties where this user is an Owner and all Leases where this user is a Tenant.</p>
                      </Col>
                      <Col xs={12} className="text-right">
                        <Button onClick={() => this.setState({ documentConfirmation: true })}>Continue</Button>
                      </Col>
                    </Row> :
                      <>
                        <Row>
                          <Col xs={12}>
                            <label>Select Document</label><br />
                            <input id="file" name="file" type="file" onChange={(event) => {
                              setFieldValue("file", event.currentTarget.files[0]);
                            }} />
                          </Col>
                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="name">
                              <Form.Label>Name</Form.Label>
                              <Form.Control as="select" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}>
                                <option value={""}>{constants.DEFAULT_OPTION}</option>
                                {values.userId && <option value={"Aadhaar Card"}>Aadhaar Card</option>}
                                {values.userId && <option value={"Pan Card"}>Pan Card</option>}
                                {values.userId && <option value={"Passport"}>Passport</option>}
                                {values.userId && <option value={"Driving License"}>Driving License</option>}
                                {values.userId && <option value={"Emirates ID"}>Emirates ID</option>}
                                {values.userId && <option value={"Visa"}>Visa</option>}
                                {values.userId && <option value={"Employment Proof"}>Employment Proof</option>}
                                {values.leaseId && <option value="Lease Agreement">Lease Agreement</option>}
                                {values.leaseId && <option value="Society NOC">Society NOC</option>}
                                {values.leaseId && <option value="Police Verification">Police Verification</option>}
                              </Form.Control>
                              {errors.name && touched.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                            </Form.Group>
                          </Col>
                          {(values.name === 'Aadhaar Card' || values.name === 'Pan Card') &&
                            <Col xs={10}>
                              <Form.Group controlId='documentNumber'>
                                <Form.Label>Document Number</Form.Label>
                                <Form.Control type="text" name={`documentNumber`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.documentNumber} />
                              </Form.Group>
                            </Col>
                          }
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={this.handleAddDocumentsButtonClick}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 18} show={this.state.showGenerateEscrowAgreementModal} onHide={() => this.handleGenerateEscrowAgreementButtonClick(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Escrow Agreement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-info"></i> We'll try to generate an escrow agreement for this lease. If all required fields are available, the agreement will be generated and you will be redirected to edit the agreement and fill in the rest of the details.</p>
            <p><strong>Proceed with generating a draft agreement?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleGenerateEscrowAgreementButtonClick(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => this.handleGenerateEscrowAgreementButtonClick("GENERATE")}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 5} show={this.state.showDeleteDocumentModal} onHide={this.handleDeleteDocumentButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Document?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteDocumentButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteDocumentButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 6} show={this.state.showMarkWorkAsCompletedModal} onHide={this.handleMarkWorkAsCompletedButtonClick}>
          <Modal.Header>
            <Modal.Title>Mark Work As Completed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                preMoveInWork: {
                  terminalStatus: 'COMPLETED'
                },
                leaseDocuments: [
                  {
                    name: markCompleted && markCompleted.workName,
                    code: (markCompleted && markCompleted.workCode) || '',
                    file: undefined,
                    documentNumber: '',
                    isVerified: true
                  }
                ],
              }}
              validationSchema={markCompleted && markCompleted.workCategoryId === 3 ? markDocumentWorkCompletedSchema : markOtherWorkCompletedSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let formData = new FormData();
                  values.preMoveInWork.terminalStatus && formData.append('preMoveInWork[terminalStatus]', values.preMoveInWork.terminalStatus)
                  if (markCompleted && markCompleted.workCategoryId === 3) {
                    (values.leaseDocuments).forEach((leaseDocument, index) => {
                      formData.append(`leaseDocuments[${index}][name]`, leaseDocument.name);
                      formData.append(`leaseDocuments[${index}][code]`, leaseDocument.code);
                      formData.append(`leaseDocuments[${index}][file]`, leaseDocument.file);
                      formData.append(`leaseDocuments[${index}][documentNumber]`, leaseDocument.documentNumber);
                      formData.append(`leaseDocuments[${index}][isVerified]`, leaseDocument.isVerified);
                    });
                  }
                  const result = await leaseProfileServices.markTerminalStatus(markCompleted && markCompleted.workId, formData);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Work marked as Completed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMarkWorkAsCompletedModal: !state.showMarkWorkAsCompletedModal }));
                    if (markCompleted && markCompleted.workCategoryId === 3) {
                      await this.getLeaseDocumentation();
                    } else if (markCompleted && markCompleted.workCategoryId === 4) {
                      await this.getLeaseFurnishing();
                    }
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {!(markCompleted && markCompleted.workCategoryId === 3) &&
                      !(markCompleted && markCompleted.workCode === 'LEASE_AGREEMENT') &&
                      <p><strong>Do you want to mark this work as completed?</strong></p>
                    }
                    {/* Determine if this is DOCUMENTATION work, if yes, show add file */}
                    {markCompleted && markCompleted.workCategoryId === 3 &&
                      <Row>
                        <Col xs={12}>
                          <label className="mb-2">Select Document File</label><br></br>
                          <input id="file" name="file" type="file" onChange={(event) => {
                            setFieldValue("leaseDocuments[0].file", event.currentTarget.files[0]);
                          }} />
                          {errors.leaseDocuments && errors.leaseDocuments[0].file && touched.leaseDocuments && touched.leaseDocuments[0].file && <Form.Text className="text-danger">
                            {errors.leaseDocuments[0].file}
                          </Form.Text>}
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Form.Group controlId='leaseDocuments[0].documentNumber-ctrl'>
                            <Form.Label>Document Number</Form.Label>
                            <Form.Control type="text" name={`leaseDocuments[0].documentNumber`} onChange={handleChange} onBlur={handleBlur} value={values.leaseDocuments[0].documentNumber} />
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group controlId={`leaseDocuments[0].isVerified`}>
                            <Form.Check type="checkbox">
                              <Form.Check.Input type="checkbox"
                                checked={values.leaseDocuments[0].isVerified}
                                value={true}
                                onChange={handleChange}
                                onClick={() => values.leaseDocuments[0].isVerified = !values.leaseDocuments[0].isVerified} />
                              <Form.Check.Label className="ml-3 mt-1">Document is Verified</Form.Check.Label>
                            </Form.Check>
                            {errors.leaseDocuments && errors.leaseDocuments[0].isVerified && touched.leaseDocuments && touched.leaseDocuments[0].isVerified && <Form.Text className="text-danger">
                              {errors.leaseDocuments[0].isVerified}
                            </Form.Text>}
                          </Form.Group>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMarkWorkAsCompletedButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 7} show={this.state.showMoveInModal} onHide={this.handleMoveInButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.eventKey === 'moved-in' ? "Mark Moved-in" : "Mark Deal Cancelled"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                lease: {
                  moveInTerminalStatus: this.state.eventKey === 'moved-in' ? "YES" : "NO",
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.markMoveInTerminalStatus(this.state.leaseId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-in Terminal status updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    setSubmitting(false);
                    this.setState(state => ({ showMoveInModal: !state.showMoveInModal }));
                    await this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {values.lease.moveInTerminalStatus === 'YES' && <Row>
                      <Col xs={12}>
                        <p>
                          <strong>Rent Cycle:</strong> {leaseProfile.lease.tenantRentPaymentDay !== null ? ((leaseProfile.lease.tenantRentPaymentDay === 1 && <span> 1 to month-end </span>) || ((leaseProfile.lease.tenantRentPaymentDay > 1 && leaseProfile.lease.tenantRentPaymentDay < 29) && (leaseProfile.lease.tenantRentPaymentDay) + ' to ' + (leaseProfile.lease.tenantRentPaymentDay - 1))) : <span className="text-warning">Not Set</span>}<br />
                          <strong>Move-in Date:</strong> {leaseProfile.lease.moveInDate ? formatDate(leaseProfile.lease.moveInDate) : <span className="text-warning">Not Set</span>}
                        </p>
                        <p>Proceed with marking this lease as Moved-in?</p>
                      </Col>
                    </Row>}
                    {values.lease.moveInTerminalStatus === 'NO' && <Row>
                      <Col xs={12}>
                        <p>Are you sure you want to mark this deal as cancelled?</p>
                      </Col>
                    </Row>}
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMoveInButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant={this.state.eventKey === 'moved-in' ? "primary" : "danger"} disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : this.state.eventKey === 'moved-in' ? 'Save' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 8} show={this.state.showCreateMoveInWorkModal} onHide={this.handleCreateMoveInReportButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title> {`Create Move-In Report`} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                works: [this.state.sampleEmptyMoveIn]
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.createLeaseWorks(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-in created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateMoveInWorkModal: !state.showCreateMoveInWorkModal }));
                    await this.getLeaseReports();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p>
                          <strong>
                            {moveInMoveOut?.isMoveIn ? `Confirm create of Move-in work?` : `Confirm create of Move-in work?`}
                          </strong>
                        </p>
                      </Col>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCreateMoveInReportButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Creating...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 9} show={this.state.showCreateMoveOutWorkModal} onHide={this.handleCreateMoveOutReportButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title> {`Create Move-Out Report`} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                works: [this.state.sampleEmptyMoveOut]
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.createLeaseWorks(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-out created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateMoveOutWorkModal: !state.showCreateMoveOutWorkModal }));
                    await this.getLeaseReports();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p>
                          <strong>
                            {moveInMoveOut?.isMoveIn ? `Confirm create of Move-out work?` : `Confirm create of Move-out work?`}
                          </strong>
                        </p>
                      </Col>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCreateMoveOutReportButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Creating...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal size="lg" backdrop="static" keyboard={false} key={'m' + 10} show={this.state.showLeaseModal} onHide={this.handleEditLeaseButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Lease Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                lease: {
                  leaseStartDate: (leaseProfile?.lease?.leaseStartDate) || undefined,
                  leaseEndDate: (leaseProfile?.lease?.leaseEndDate) || undefined,
                  lockInEndDate: (leaseProfile?.lease?.lockInEndDate) || undefined,
                  tenantFoundByAzuro: leaseProfile?.lease?.tenantFoundByAzuro !== null ? leaseProfile?.lease?.tenantFoundByAzuro.toString() : undefined,
                  isRentFlowing: leaseProfile?.lease?.isRentFlowing !== null ? leaseProfile?.lease?.isRentFlowing.toString() : undefined,
                  securityDeposit: (leaseProfile?.lease?.securityDeposit) || undefined,
                  noticePeriodQuantity: (leaseProfile?.lease?.noticePeriodQuantity) || 1,
                  noticePeriodUnit: (leaseProfile?.lease?.noticePeriodUnit) || undefined,
                  tenantRentPaymentDay: (leaseProfile?.lease?.tenantRentPaymentDay) || undefined,
                  tenantRentPaymentMode: (leaseProfile?.lease?.tenantRentPaymentMode) || undefined,
                  tenantRentPaymentBillDurationLength: (leaseProfile?.lease?.tenantRentPaymentBillDurationLength) || undefined,
                  moveInDate: (leaseProfile?.lease?.moveInDate) || null,
                  moveOutDate: (leaseProfile?.lease?.moveOutDate) || undefined,
                  leaseType: (leaseProfile?.lease?.leaseType) || undefined,
                  tenantType: (leaseProfile?.lease?.tenantType) || undefined,
                  rentReminderSendStatus: (leaseProfile?.lease?.rentReminderSendStatus) || undefined,
                  securityDepositType: (leaseProfile?.lease?.securityDepositType) || undefined,
                  isSecurityDepositFlowing: leaseProfile?.lease?.isSecurityDepositFlowing !== null ? leaseProfile?.lease?.isSecurityDepositFlowing.toString() : undefined,
                  expectedMoveInDate: (leaseProfile?.lease?.expectedMoveInDate) || undefined,
                  expectedMoveOutDate: (leaseProfile?.lease?.expectedMoveOutDate) || undefined,
                  rentPeriods: (leaseProfile?.lease?.rentPeriods.length > 0 && leaseProfile?.lease?.rentPeriods) || [
                    {
                      id: undefined,
                      startDate: undefined,
                      endDate: undefined,
                      rent: undefined,
                    }
                  ],
                  rentPaymentTiming: (leaseProfile?.lease?.rentPaymentTiming) || undefined,
                }
              }}
              validationSchema={editLeaseSchema}
              onSubmit={async (values) => {
                values.lease.tenantFoundByAzuro = (values.lease.tenantFoundByAzuro === 'true') ? true : (values.lease.tenantFoundByAzuro === 'false' ? false : undefined)
                values.lease.isRentFlowing = (values.lease.isRentFlowing === 'true') ? true : (values.lease.isRentFlowing === 'false' ? false : undefined)
                if (values.lease && values.lease.securityDepositType === 'ZERO') {
                  values.lease.securityDeposit = null;
                }
                values.lease && values.lease.securityDepositType === 'ZERO' ? values.lease.isSecurityDepositFlowing = null : values.lease.isSecurityDepositFlowing = (values.lease.isSecurityDepositFlowing === 'true') ? true : (values.lease.isSecurityDepositFlowing === 'false' ? false : undefined)
                const result = await leaseProfileServices.editLease(leaseId, values);
                if (result && result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: `Lease updated!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showLeaseModal: !state.showLeaseModal }));
                  this.getLeaseDetails();
                }
              }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>Finalized Lease Details</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <Form.Group controlId="lease.moveInDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Actual Move-in Date'
                            dateFormat='yyyy-MM-dd'
                            isMandatory={values.lease.moveInDate ? true : false}
                            name='lease.moveInDate'
                            value={values.lease.moveInDate}
                            onChange={setFieldValue}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <Form.Group controlId="lease.leaseStartDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start Date'
                            isMandatory={values.lease.moveInDate && true}
                            dateFormat='yyyy-MM-dd'
                            name='lease.leaseStartDate'
                            value={values.lease.leaseStartDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.leaseStartDate && touched.lease && touched.lease.leaseStartDate && <Form.Text className="text-danger">
                            {errors.lease.leaseStartDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.leaseEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='End Date'
                            isMandatory={values.lease.moveInDate && true}
                            dateFormat='yyyy-MM-dd'
                            name='lease.leaseEndDate'
                            value={values.lease.leaseEndDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.leaseEndDate && touched.lease && touched.lease.leaseEndDate && <Form.Text className="text-danger">
                            {errors.lease.leaseEndDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.lockInEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Lock-in End Date'
                            isMandatory={values.lease.moveInDate && true}
                            dateFormat='yyyy-MM-dd'
                            name='lease.lockInEndDate'
                            value={values.lease.lockInEndDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.lockInEndDate && touched.lease && touched.lease.lockInEndDate && <Form.Text className="text-danger">
                            {errors.lease.lockInEndDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.noticePeriodQuantity">
                          <Form.Label>Notice Period {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                          <Form.Control type="text" name="lease.noticePeriodQuantity" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.noticePeriodQuantity} />
                          {errors.lease && errors.lease.noticePeriodQuantity && touched.lease && touched.lease.noticePeriodQuantity && <Form.Text className="text-danger">
                            {errors.lease.noticePeriodQuantity}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.noticePeriodUnit">
                          <Form.Label>Notice Period Unit {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                          <Form.Control as="select" name="lease.noticePeriodUnit" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.noticePeriodUnit}>
                            {
                              constants.NOTICE_PERIOD_UNITS.map((unit, index) =>
                                (<option key={index} value={unit}>{unit}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.noticePeriodUnit && touched.lease && touched.lease.noticePeriodUnit && <Form.Text className="text-danger">
                            {errors.lease.noticePeriodUnit}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.leaseType">
                          <Form.Label>Lease Type</Form.Label>
                          <Form.Control as="select" name="lease.leaseType" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.leaseType}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.LEASE_TYPE.map((lease, index) =>
                                (<option key={index} value={lease}>{lease}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.leaseType && touched.lease && touched.lease.leaseType && <Form.Text className="text-danger">
                            {errors.lease.leaseType}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.tenantType">
                          <Form.Label>Tenant Type</Form.Label>
                          <Form.Control as="select" name="lease.tenantType" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.tenantType}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.TENANT_TYPE.map((tenant, index) =>
                                (<option key={index} value={tenant}>{tenant}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.tenantType && touched.lease && touched.lease.tenantType && <Form.Text className="text-danger">
                            {errors.lease.tenantType}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group key={`lease.rentReminderSendStatus`} controlId={`lease.rentReminderSendStatus`}>
                          <Form.Label>Send Rent Reminder?</Form.Label>
                          {
                            constants.YES_NO.map((yesNo, index) =>
                            (<Form.Check type="radio" key={`lease.rentReminderSendStatus${index}`}>
                              <Form.Check.Input type="radio" onChange={handleChange}
                                name="lease.rentReminderSendStatus"
                                value={yesNo}
                                checked={
                                  (yesNo === values.lease.rentReminderSendStatus)
                                }
                              />
                              <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                            </Form.Check>)
                            )
                          }
                          {errors.lease && errors.lease.rentReminderSendStatus && touched.lease && touched.lease.rentReminderSendStatus && <Form.Text className="text-danger">
                            {errors.lease.rentReminderSendStatus}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group key={`lease.tenantFoundByAzuro`} controlId={`lease.tenantFoundByAzuro`}>
                          <Form.Label>Tenant Found By Azuro?</Form.Label>
                          {
                            constants.YES_NO.map((yesNo, index) =>
                            (<Form.Check type="radio" key={`lease.tenantFoundByAzuro${index}`}>
                              <Form.Check.Input type="radio" onChange={handleChange}
                                name="lease.tenantFoundByAzuro"
                                value={yesNo === 'YES' ? true : false}
                                checked={
                                  (yesNo === 'YES' && (values.lease.tenantFoundByAzuro === 'true')) ||
                                  (yesNo === 'NO' && (values.lease.tenantFoundByAzuro === 'false'))
                                }
                              />
                              <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                            </Form.Check>)
                            )
                          }
                          {errors.lease && errors.lease.tenantFoundByAzuro && touched.lease && touched.lease.tenantFoundByAzuro && <Form.Text className="text-danger">
                            {errors.lease.tenantFoundByAzuro}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.moveOutDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Actual Move-out Date'
                            // isMandatory={values.lease.moveInDate && true}
                            dateFormat='yyyy-MM-dd'
                            name='lease.moveOutDate'
                            value={values.lease.moveOutDate}
                            onChange={setFieldValue}
                            maxDate={addDays(new Date(), 1)}
                          />
                          {errors.lease && errors.lease.moveOutDate && touched.lease && touched.lease.moveOutDate && <Form.Text className="text-danger">
                            {errors.lease.moveOutDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.expectedMoveOutDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Move-out Date'
                            // isMandatory={values.lease.moveInDate && true}
                            dateFormat='yyyy-MM-dd'
                            name='lease.expectedMoveOutDate'
                            value={values.lease.expectedMoveOutDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.expectedMoveOutDate && touched.lease && touched.lease.expectedMoveOutDate && <Form.Text className="text-danger">
                            {errors.lease.expectedMoveOutDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row >
                      <Col xs={12}>
                        <h5>Rent Payment Details</h5>
                      </Col>

                      <Col xs={4}>
                        <Form.Group controlId="lease.tenantRentPaymentDay">
                          <Form.Label>Rent Cycle {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                          <Form.Control as="select" name="lease.tenantRentPaymentDay" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.tenantRentPaymentDay}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            <option value='1'>{'1 to Month-end'}</option>
                            {
                              constants.RENT_PAYMENT_DAY.map((rentPaymentDay, index) =>
                                (<option key={index} value={rentPaymentDay}> {rentPaymentDay + ' to ' + (rentPaymentDay - 1)}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.tenantRentPaymentDay && touched.lease && touched.lease.tenantRentPaymentDay && <Form.Text className="text-danger">
                            {errors.lease.tenantRentPaymentDay}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={4}>
                        <Form.Group controlId="lease.rentPaymentTiming">
                          <Form.Label>Payment Timing {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                          <Form.Control as="select" name="lease.rentPaymentTiming" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.rentPaymentTiming}>
                            {
                              <>
                                <option value='None'>{constants.DEFAULT_OPTION}</option>
                                <option value={'PRE'}>Pre-paid</option>
                                <option value={'POST'}>Post-paid</option>
                              </>
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.rentPaymentTiming && touched.lease && touched.lease.rentPaymentTiming && <Form.Text className="text-danger">
                            {errors.lease.rentPaymentTiming}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={4}>
                        <Form.Group key={`lease.isRentFlowing`} controlId={`lease.isRentFlowing`}>
                          <Form.Label>Rent Flowing through Azuro? {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                          {
                            constants.YES_NO.map((yesNo, index) =>
                            (<Form.Check type="radio" key={`lease.isRentFlowing${index}`} >
                              <Form.Check.Input type="radio" onChange={handleChange}
                                name="lease.isRentFlowing"
                                value={yesNo === 'YES' ? true : false}
                                checked={
                                  (yesNo === 'YES' && values.lease.isRentFlowing === 'true') ||
                                  (yesNo === 'NO' && values.lease.isRentFlowing === 'false')
                                }
                              />
                              <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                            </Form.Check>)
                            )
                          }
                          {errors.lease && errors.lease.isRentFlowing && touched.lease && touched.lease.isRentFlowing && <Form.Text className="text-danger">
                            {errors.lease.isRentFlowing}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={4}>
                        <Form.Group controlId="lease.tenantRentPaymentMode">
                          <Form.Label>Mode of Rent Payment</Form.Label>
                          <Form.Control as="select" name="lease.tenantRentPaymentMode" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.tenantRentPaymentMode}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.PAYMENT_MODES.map((modes, index) =>
                                (<option key={index} value={modes}>{modes}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.lease && errors.lease.tenantRentPaymentMode && touched.lease && touched.lease.tenantRentPaymentMode && <Form.Text className="text-danger">
                            {errors.lease.tenantRentPaymentMode}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.tenantRentPaymentBillDurationLength">
                          <Form.Label>Rent Payment Frequency</Form.Label>
                          <Form.Control as="select" name="lease.tenantRentPaymentBillDurationLength" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.tenantRentPaymentBillDurationLength}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            <option value={'MONTH'}>MONTHLY</option>
                            <option value={'QUARTER'}>QUARTERLY</option>
                          </Form.Control>
                          {errors.lease && errors.lease.tenantRentPaymentBillDurationLength && touched.lease && touched.lease.tenantRentPaymentBillDurationLength && <Form.Text className="text-danger">
                            {errors.lease.tenantRentPaymentBillDurationLength}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>Rent Periods {values.lease.moveInDate && <span className="text-danger">*</span>}</h5>
                        <p>Multiple rent periods can be added, atleast one rent period is required</p>
                      </Col>
                      <Col xs={12}>
                        <FieldArray
                          name="lease.rentPeriods"
                          render={arrayHelpers => (
                            <div>
                              {values.lease.rentPeriods.map((rentPeriod, index) =>
                              (<Row key={index}>
                                <Col xs={4}>
                                  <Form.Group controlId={`lease.rentPeriods${index}startDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='yyyy-MM-dd'
                                      name={`lease.rentPeriods[${index}].startDate`}
                                      label='Start Date'
                                      isMandatory={values.lease.moveInDate && true}
                                      value={rentPeriod.startDate}
                                      onChange={setFieldValue}
                                      minDate={index === 0 ? new Date(values.lease.leaseStartDate) : addDays(new Date(values.lease.rentPeriods[index - 1].endDate), 1)}
                                      maxDate={index === 0 ? new Date(values.lease.leaseStartDate) : addDays(new Date(values.lease.rentPeriods[index - 1].endDate), 1)}
                                    />
                                    {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].startDate && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].startDate && <Form.Text className="text-danger">
                                      {errors.lease.rentPeriods[index].startDate}
                                    </Form.Text>}
                                  </Form.Group>
                                </Col>
                                <Col xs={4}>
                                  <Form.Group controlId={`lease.rentPeriods${index}endDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='yyyy-MM-dd'
                                      label='End Date'
                                      isMandatory={values.lease.moveInDate && true}
                                      name={`lease.rentPeriods[${index}].endDate`}
                                      value={rentPeriod.endDate}
                                      onChange={setFieldValue}
                                      minDate={new Date(values.lease.rentPeriods[index].startDate)}
                                      maxDate={new Date(values.lease.leaseEndDate)}
                                    />
                                    {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].endDate && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].endDate && <Form.Text className="text-danger">
                                      {errors.lease.rentPeriods[index].endDate}
                                    </Form.Text>}
                                  </Form.Group>
                                </Col>
                                <Col xs={3}>
                                  <Form.Group controlId={`lease.rentPeriods${index}rent`}>
                                    <Form.Label>Rent(in {leaseProfile.lease.currency}) {values.lease.moveInDate && <span className="text-danger">*</span>}</Form.Label>
                                    <Form.Control type="number" name={`lease.rentPeriods[${index}].rent`} onChange={handleChange} onBlur={handleBlur}
                                      value={rentPeriod.rent} />
                                    {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].rent && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].rent && <Form.Text className="text-danger">
                                      {errors.lease.rentPeriods[index].rent}
                                    </Form.Text>}
                                  </Form.Group>
                                </Col>
                                {index > 0 && <Col xs={1} className="pl-0">
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>}
                              </Row>)
                              )}
                              {values.lease.rentPeriods[values.lease.rentPeriods.length - 1].endDate !== values.lease.leaseEndDate &&
                                <Button className='mb-3' variant="outline-primary" size="sm" onClick={() => arrayHelpers.push({ startDate: undefined, endDate: undefined, rent: undefined })}>Add Another Rent Period</Button>
                              }
                            </div>
                          )}
                        />
                      </Col>
                      <Col xs={12}>
                        <h5>Security Deposit {values.lease.moveInDate && <span className="text-danger">*</span>}</h5>
                      </Col>
                      <Col xs={4}>
                        <Form.Group controlId="lease.securityDepositType">
                          <Form.Label>Deposit Scheme <span className="text-danger">*</span></Form.Label>
                          <Form.Control as="select" name="lease.securityDepositType" onChange={handleChange} onBlur={handleBlur}
                            value={values.lease.securityDepositType}>
                            {
                              <>
                                <option value={0}>{constants.DEFAULT_OPTION}</option>
                                <option value={'ZERO'}>{'ZERO'}</option>
                                <option value={'PARTIAL'}>{'PARTIAL'}</option>
                                <option value={'FULL'}>{'FULL'}</option>
                              </>
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      {(values.lease.securityDepositType === 'PARTIAL' || values.lease.securityDepositType === 'FULL') &&
                        <>
                          <Col xs={4}>
                            <Form.Group controlId="lease.securityDeposit">
                              <Form.Label>Amount <span className="text-danger">*</span></Form.Label>
                              <Form.Control type="text" name="lease.securityDeposit" onChange={handleChange} onBlur={handleBlur}
                                value={values.lease.securityDeposit} />
                              {errors.lease && errors.lease.securityDeposit && touched.lease && touched.lease.securityDeposit && <Form.Text className="text-danger">
                                {errors.lease.securityDeposit}
                              </Form.Text>}
                            </Form.Group>
                          </Col>
                          <Col xs={4}>
                            <Form.Group controlId="lease.isSecurityDepositFlowing">
                              <Form.Label>Flowing through Azuro <span className="text-danger">*</span></Form.Label>
                              <Form.Control as="select" name="lease.isSecurityDepositFlowing" onChange={handleChange} onBlur={handleBlur}
                                value={values.lease.isSecurityDepositFlowing}>
                                {
                                  <>
                                    <option value={null}>{constants.DEFAULT_OPTION}</option>
                                    <option value={true}>{'YES'}</option>
                                    <option value={false}>{'NO'}</option>
                                  </>
                                }
                              </Form.Control>
                              {errors.lease && errors.lease.isSecurityDepositFlowing && touched.lease && touched.lease.isSecurityDepositFlowing && <Form.Text className="text-danger">
                                {errors.lease.isSecurityDepositFlowing}
                              </Form.Text>}
                            </Form.Group>
                          </Col>
                        </>
                      }
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditLeaseButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`m` + 11} show={showRequestBackgroundVerificationModal} onHide={this.handleRequestBackgroundVerificationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Raise New Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                backgroundVerificationRequest: {
                  ...backgroundVerificationRequest,
                  userId: undefined,
                  name: undefined,
                  email: undefined,
                  dateOfBirth: undefined,
                  fathersName: undefined,
                  backgroundVerificationTasks: [
                    {
                      taskType: 'pan_verification',
                      inputData: {
                        panName: undefined,
                        panNumber: undefined
                      }
                    },
                    {
                      taskType: 'court_record_verification',
                      inputData: {
                        addressType: undefined,
                        address: undefined
                      }
                    }
                  ],
                }
              }}
              // validationSchema={newBackgroundVerificationRequestSchema}
              onSubmit={
                async (values) => {
                  if (values.name === '') { values.name = undefined; }
                  if (values.email === '') { values.email = undefined; }
                  if (values.userId) { values.userId = parseInt(values.userId, 10) }
                  const result = await leaseProfileServices.createBackgroundVerificationRequest(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Background Verification Request Submitted!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showRequestBackgroundVerificationModal: !state.showRequestBackgroundVerificationModal }));
                    this.getLeaseDocumentation();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}><h5>Basic Information</h5></Col>
                      <Col xs={12}>
                        <Form.Group controlId="backgroundVerificationRequest.userId">
                          <Form.Label>Tenant</Form.Label>
                          <Form.Control as="select" name="backgroundVerificationRequest.userId"
                            onChange={
                              async (e) => {
                                handleChange(e);
                                await this.handleCustomChange(e, setFieldValue)
                              }
                            }
                            onBlur={handleBlur} value={values?.backgroundVerificationRequest?.userId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              tenants?.map((tenant =>
                                <option key={"backgroundVerificationRequest.userId" + tenant.id} value={tenant.id}>{tenant.fullName}
                                  {tenant.userSubCategory && ' (' + formatProperCase(tenant.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors?.backgroundVerificationRequest?.userId && touched?.backgroundVerificationRequest?.userId && <Form.Text className="text-danger">
                            {errors?.backgroundVerificationRequest?.userId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="backgroundVerificationRequest.name">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" name='backgroundVerificationRequest.name' onChange={handleChange} onBlur={handleBlur} value={values.backgroundVerificationRequest.name} readOnly="readonly" />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="backgroundVerificationRequest.email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" name='backgroundVerificationRequest.email' onChange={handleChange} onBlur={handleBlur} value={values.backgroundVerificationRequest.email} readOnly="readonly" />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="backgroundVerificationRequest.dateOfBirth">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label={`Date of Birth`}
                            dateFormat='MMMM d, yyyy'
                            name='backgroundVerificationRequest.dateOfBirth'
                            value={values.backgroundVerificationRequest?.dateOfBirth}
                            onChange={setFieldValue}
                          />
                          {errors.backgroundVerificationRequest?.dateOfBirth && touched.backgroundVerificationRequest?.dateOfBirth && <Form.Text className="text-danger">
                            {errors.backgroundVerificationRequest?.dateOfBirth}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="backgroundVerificationRequest.fathersName">
                          <Form.Label>Father's Name</Form.Label>
                          <Form.Control type="text" name='backgroundVerificationRequest.fathersName' onChange={handleChange} onBlur={handleBlur} value={values.backgroundVerificationRequest.fathersName} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='mr-0'>
                        <FieldArray
                          name="backgroundVerificationRequest.backgroundVerificationTasks"
                          render={arrayHelpers => (
                            <>
                              {values.backgroundVerificationRequest?.backgroundVerificationTasks.map((backgroundVerificationTask, index) =>
                              (
                                <>
                                  <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                  <Row key={index}>
                                    <Col xs={12}><h5>{backgroundVerificationTask?.taskType === 'pan_verification' ? `PAN Verification` : `Court Records Verification`}</h5></Col>
                                    {backgroundVerificationTask?.taskType === 'pan_verification' && <Col xs={6}>
                                      <Form.Group controlId={"panName" + index}>
                                        <Form.Label>Name on PAN Card</Form.Label>
                                        <Form.Control type="text" name={`backgroundVerificationRequest.backgroundVerificationTasks[${index}].inputData.panName`} onChange={handleChange} onBlur={handleBlur} value={backgroundVerificationTask?.inputData?.panName} />
                                      </Form.Group>
                                    </Col>}
                                    {backgroundVerificationTask?.taskType === 'pan_verification' && <Col xs={6}>
                                      <Form.Group controlId={"panNumber" + index}>
                                        <Form.Label>PAN Number</Form.Label>
                                        <Form.Control type="text" name={`backgroundVerificationRequest.backgroundVerificationTasks[${index}].inputData.panNumber`} onChange={handleChange} onBlur={handleBlur} value={backgroundVerificationTask?.inputData?.panNumber} />
                                      </Form.Group>
                                    </Col>}
                                    {backgroundVerificationTask?.taskType === 'court_record_verification' && <Col xs={12}>
                                      <Form.Group controlId={"backgroundVerificationTaskAddressType" + index}>
                                        <Form.Label>Address type</Form.Label>
                                        <Form.Control as="select" name={`backgroundVerificationRequest.backgroundVerificationTasks[${index}].inputData.addressType`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={backgroundVerificationTask.inputData.tasktype}>
                                          <option value='0'>{constants.DEFAULT_OPTION}</option>
                                          {
                                            constants.ADDRESSS_TYPE.map((addressType =>
                                              <option key={"backgroundVerificationRequest.addressType" + addressType} value={addressType}>
                                                {formatProperCaseWithUnderscores(addressType)}
                                              </option>
                                            ))
                                          }
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>}
                                    {backgroundVerificationTask?.taskType === 'court_record_verification' && <Col xs={12}>
                                      <Form.Group controlId={"address" + index}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control as="textarea" name={`backgroundVerificationRequest.backgroundVerificationTasks[${index}].inputData.address`} onChange={handleChange} onBlur={handleBlur} value={backgroundVerificationTask?.inputData?.address} />
                                      </Form.Group>
                                    </Col>}
                                  </Row>
                                </>)
                              )}
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleRequestBackgroundVerificationButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 12} show={this.state.showNoticePeriodModal} onHide={this.handleNoticePeriodButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Initiate Notice Period</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                noticePeriod: {
                  leaseId: leaseId || undefined,
                  initiatorId: undefined,
                  noticePeriodEndDate: undefined,
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.noticePeriod.leaseId = parseInt(values.noticePeriod.leaseId, 10);
                  values.noticePeriod.initiatorId = parseInt(values.noticePeriod.initiatorId, 10);
                  const result = await leaseProfileServices.createNoticePeriod(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Notice Period started!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showNoticePeriodModal: !state.showNoticePeriodModal }));
                    await this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        {new Date() < new Date(leaseProfile.lease.lockInEndDate) ? <p>Lock-in ends on {formatDate(leaseProfile.lease.lockInEndDate)}</p> : <p>Lock-in has expired.</p>}
                      </Col>
                      <Col xs={12}>
                        <p><strong>Notice Period:</strong> {leaseProfile.lease.noticePeriodQuantity} {leaseProfile.lease.noticePeriodUnit}</p>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="initiatorSelect">
                          <Form.Label>Initiator</Form.Label>
                          <Form.Control as="select" name="noticePeriod.initiatorId" onChange={handleChange} onBlur={handleBlur} value={values.noticePeriod.initiatorId}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              latestTenantAndOnwers && latestTenantAndOnwers.map((initiatorData =>
                                <option key={"initiatorData" + initiatorData.id} value={initiatorData.id}>{initiatorData.fullName}
                                  {initiatorData.userSubCategory && '(' + formatProperCase(initiatorData.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {/* {errors.noticePeriod.initiatorId && touched.noticePeriod.initiatorId && <Form.Text className="text-danger"> */}
                          {/* {errors.noticePeriod.initiatorId} */}
                          {/* </Form.Text>} */}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="noticePeriod.noticePeriodEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Notice Period End Date'
                            dateFormat='MMMM d, yyyy'
                            name='noticePeriod.noticePeriodEndDate'
                            value={values.noticePeriod.noticePeriodEndDate}
                            onChange={setFieldValue}
                          />
                          {/* {errors.noticePeriod.noticePeriodEndDate && touched.noticePeriod.noticePeriodEndDate && <Form.Text className="text-danger"> */}
                          {/* {errors.noticePeriod.noticePeriodEndDate} */}
                          {/* </Form.Text>} */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleNoticePeriodButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 13} show={this.state.showLeaseExtensionsModal} onHide={this.handleleaseExtensionsButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Extend Lease</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                leaseExtension: {
                  leaseId: leaseId || undefined,
                  previousLeaseEndDate: leaseProfile?.lease?.leaseEndDate || undefined,
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.createExtendLease(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Lease Extension started!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showLeaseExtensionsModal: !state.showLeaseExtensionsModal }))
                    await this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p>Are you sure you want to extend this lease?</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleleaseExtensionsButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 14} show={this.state.showMoveOutInitiationModal} onHide={this.handleMoveOutInitiationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Start Move-out Process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                lease: {
                  expectedMoveOutDate: (leaseProfile?.lease?.expectedMoveOutDate) || undefined,
                  moveOutInitiationStatus: "YES",
                  moveOutInitiationRemarksCategory: (leaseProfile?.lease?.moveOutInitiationRemarksCategory) || undefined,
                  moveOutInitiationRemarks: (leaseProfile?.lease?.moveOutInitiationRemarks) || undefined,
                  moveOutIntimationDate: (leaseProfile?.lease?.moveOutIntimationDate) || undefined,
                  reRentingAvailabilityStatus: (leaseProfile?.lease?.reRentingAvailabilityStatus) || undefined,
                }
              }}
              validationSchema={moveOutInitiationSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.markMoveOutInitiationStatus(leaseId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-out Process started!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMoveOutInitiationModal: !state.showMoveOutInitiationModal }));
                    await this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.expectedMoveOutDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Move-out Date'
                            dateFormat='MMMM d, yyyy'
                            isMandatory={true}
                            name='lease.expectedMoveOutDate'
                            value={values.lease.expectedMoveOutDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.expectedMoveOutDate && touched.lease && touched.lease.expectedMoveOutDate && <Form.Text className="text-danger">
                            {errors.lease.expectedMoveOutDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.moveOutIntimationDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Move-out Intimation Date'
                            dateFormat='MMMM d, yyyy'
                            isMandatory={true}
                            name='lease.moveOutIntimationDate'
                            value={values.lease.moveOutIntimationDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.moveOutIntimationDate && touched.lease && touched.lease.moveOutIntimationDate && <Form.Text className="text-danger">
                            {errors.lease.moveOutIntimationDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId={"lease.reRentingAvailabilityStatus"}>
                      <Form.Label>Available For Re-Renting <span className="text-danger">*</span></Form.Label>
                      <Form.Control as="select" name="lease.reRentingAvailabilityStatus" required="required" onChange={handleChange} onBlur={handleBlur} value={values.lease.reRentingAvailabilityStatus}>
                        <option value="">{constants.DEFAULT_OPTION}</option>
                        <option value='YES'>YES</option>
                        <option value='NO'>NO</option>
                      </Form.Control>
                      {errors.lease && errors.lease.reRentingAvailabilityStatus && touched.lease && touched.lease.reRentingAvailabilityStatus && <Form.Text className="text-danger">
                        {errors.lease.reRentingAvailabilityStatus}</Form.Text>}
                    </Form.Group>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="lease.moveOutInitiationRemarksCategory">
                          <Form.Label>Move-Out Remarks Category<span className='text-danger'>*</span></Form.Label>
                          <Form.Control as="select" name='lease.moveOutInitiationRemarksCategory' required="required" onChange={handleChange} onBlur={handleBlur} value={values.lease.moveOutInitiationRemarksCategory}>
                            <option value="">{constants.DEFAULT_OPTION}</option>
                            <option value="Rent escalation">Rent Escalation</option>
                            <option value="Tenant relocating due to job change">Tenant Relocating due to job change</option>
                            <option value="Self use by owner">Self use by owner</option>
                            <option value="Property sold out">Property Sold Out</option>
                            <option value="Owner no longer wants services">Owner No Longer Wants Services</option>
                            <option value="Tenant wants to move into a new flat">Tenant Wants To Move Into A new Flat</option>
                            <option value="Rent defaulters">Rent Defaulters</option>
                            <option value="Behavioral issues(tenant or owners end)">Behavioral Issues(Tenant Or Owners End)</option>
                            <option value="Financial issues(tenants end)">Financial Issues(Tenant's End)</option>
                            <option value="Other">Other</option>
                          </Form.Control>
                          {errors.lease && errors.lease.moveOutInitiationRemarksCategory && touched.lease && touched.lease.moveOutInitiationRemarksCategory && <Form.Text className="text-danger">
                            {errors.lease.moveOutInitiationRemarksCategory}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="lease.moveOutInitiationRemarks">
                          <Form.Label>Move-Out Remarks</Form.Label>
                          <Form.Control as="textarea" rows="3" name="lease.moveOutInitiationRemarks" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.lease.moveOutInitiationRemarks} />
                          {errors.lease && errors.lease.moveOutInitiationRemarks && touched.lease && touched.lease.moveOutInitiationRemarks && <Form.Text className="text-danger">
                            {errors.lease.moveOutInitiationRemarks} </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMoveOutInitiationButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`m` + 15} show={this.state.showMoveOutConfirmModal} onHide={this.handleMoveOutConfirmButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Move-Out</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                lease: {
                  leaseId: leaseId,
                  moveOutDate: undefined,
                  moveOutTerminalStatus: "YES",
                  file: undefined,
                }
              }}
              validationSchema={moveOutTerminationSchema}
              onSubmit={
                async (values) => {
                  let formData = new FormData();
                  values.lease.file && formData.append('file', values.lease.file);
                  values.lease.moveOutDate && formData.append('lease[moveOutDate]', values.lease.moveOutDate);
                  values.lease.moveOutTerminalStatus && formData.append('lease[moveOutTerminalStatus]', values.lease.moveOutTerminalStatus);
                  const result = await leaseProfileServices.markMoveOutTerminalStatus(leaseId, formData);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-out Confirmed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMoveOutConfirmModal: !state.showMoveOutConfirmModal }));
                    this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {/* <Col xs={12}><h5></h5></Col> */}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.moveOutDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Move-out Date'
                            dateFormat='MMMM d, yyyy'
                            name='lease.moveOutDate'
                            isMandatory={true}
                            value={values.lease.moveOutDate}
                            onChange={setFieldValue}
                            maxDate={addDays(new Date(), 1)}
                          />
                          {errors.lease && errors.lease.moveOutDate && touched.lease && touched.lease.moveOutDate && <Form.Text className="text-danger">
                            {errors.lease.moveOutDate}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <label>Move-out-Reconciliation File<span className="text-danger">*</span></label><br />
                        <input id="file" name="file" type="file" multiple onChange={(event) => {
                          setFieldValue("lease.file", event.currentTarget.files[0]);
                        }} />
                        {errors.lease && errors.lease.file && touched.lease && touched.lease.file && <Form.Text className="text-danger">
                          {errors.lease.file}</Form.Text>}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMoveOutConfirmButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Submit'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`m` + 16} show={this.state.showNoticePeriodTerminalModal} onHide={this.handleNoticePeriodTerminationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Notice Period</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                noticePeriod: {
                  terminalStatus: 'YES'
                },
                lease: {
                  expectedMoveOutDate: (leaseProfile?.lease?.expectedMoveOutDate) || undefined,
                  moveOutInitiationRemarksCategory: (leaseProfile?.lease?.moveOutInitiationRemarksCategory) || undefined,
                  moveOutInitiationRemarks: (leaseProfile?.lease?.moveOutInitiationRemarks) || undefined,
                  moveOutIntimationDate: (leaseProfile?.lease?.moveOutIntimationDate) || undefined,
                  reRentingAvailabilityStatus: (leaseProfile?.lease?.reRentingAvailabilityStatus) || undefined,
                }
              }}
              validationSchema={noticePeriodStatusInitiationSchema}
              onSubmit={
                async (values) => {
                  const result = await leaseProfileServices.markNoticePeriodTerminalStatus(leaseProfile.lease.noticePeriods[leaseProfile.lease.noticePeriods.length - 1].id, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Notice Period Ended!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showNoticePeriodTerminalModal: !state.showNoticePeriodTerminalModal }));
                    this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.expectedMoveOutDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Move-out Date'
                            dateFormat='MMMM d, yyyy'
                            isMandatory={true}
                            name='lease.expectedMoveOutDate'
                            value={values.lease.expectedMoveOutDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.expectedMoveOutDate && touched.lease && touched.lease.expectedMoveOutDate && <Form.Text className="text-danger">
                            {errors.lease.expectedMoveOutDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.moveOutIntimationDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Move-out Intimation Date'
                            dateFormat='MMMM d, yyyy'
                            isMandatory={true}
                            name='lease.moveOutIntimationDate'
                            value={values.lease.moveOutIntimationDate}
                            onChange={setFieldValue}
                          />
                          {errors.lease && errors.lease.moveOutIntimationDate && touched.lease && touched.lease.moveOutIntimationDate && <Form.Text className="text-danger">
                            {errors.lease.moveOutIntimationDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId={"lease.reRentingAvailabilityStatus"}>
                      <Form.Label>Available For Re-Renting <span className="text-danger">*</span></Form.Label>
                      <Form.Control as="select" name="lease.reRentingAvailabilityStatus" required="required" onChange={handleChange} onBlur={handleBlur} value={values.lease.reRentingAvailabilityStatus}>
                        <option value="">{constants.DEFAULT_OPTION}</option>
                        <option value='YES'>YES</option>
                        <option value='NO'>NO</option>
                      </Form.Control>
                      {errors.lease && errors.lease.reRentingAvailabilityStatus && touched.lease && touched.lease.reRentingAvailabilityStatus && <Form.Text className="text-danger">
                        {errors.lease.reRentingAvailabilityStatus}</Form.Text>}
                    </Form.Group>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="lease.moveOutInitiationRemarksCategory">
                          <Form.Label>Move-Out Remarks Category<span className='text-danger'>*</span></Form.Label>
                          <Form.Control as="select" name='lease.moveOutInitiationRemarksCategory' required="required" onChange={handleChange} onBlur={handleBlur} value={values.lease.moveOutInitiationRemarksCategory}>
                            <option value="">{constants.DEFAULT_OPTION}</option>
                            <option value="Rent escalation">Rent Escalation</option>
                            <option value="Tenant relocating due to job change">Tenant Relocating due to job change</option>
                            <option value="Self use by owner">Self use by owner</option>
                            <option value="Property sold out">Property Sold Out</option>
                            <option value="Owner no longer wants services">Owner No Longer Wants Services</option>
                            <option value="Tenant wants to move into a new flat">Tenant Wants To Move Into A new Flat</option>
                            <option value="Rent defaulters">Rent Defaulters</option>
                            <option value="Behavioral issues(tenant or owners end)">Behavioral Issues(Tenant Or Owners End)</option>
                            <option value="Financial issues(tenants end)">Financial Issues(Tenant's End)</option>
                            <option value="Other">Other</option>
                          </Form.Control>
                          {errors.lease && errors.lease.moveOutInitiationRemarksCategory && touched.lease && touched.lease.moveOutInitiationRemarksCategory && <Form.Text className="text-danger">
                            {errors.lease.moveOutInitiationRemarksCategory}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="lease.moveOutInitiationRemarks">
                          <Form.Label>Move-Out Remarks</Form.Label>
                          <Form.Control as="textarea" rows="3" name="lease.moveOutInitiationRemarks" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.lease.moveOutInitiationRemarks} />
                          {errors.lease && errors.lease.moveOutInitiationRemarks && touched.lease && touched.lease.moveOutInitiationRemarks && <Form.Text className="text-danger">
                            {errors.lease.moveOutInitiationRemarks}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleNoticePeriodTerminationButtonClick}>
                          No
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`m` + 17} show={this.state.showLeaseExtentionTerminalModal} onHide={this.handleLeaseExtensionTerminalButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>End Lease Extension Process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                leaseExtension: {
                  terminalStatus: "YES",
                  lease: {
                    leaseEndDate: undefined,
                    lockInEndDate: undefined,
                    rentPeriods: [
                      {
                        id: undefined,
                        startDate: undefined,
                        endDate: undefined,
                        rent: undefined
                      }
                    ]
                  }
                }

              }}
              // validationSchema={schema}
              onSubmit={
                async (values) => {
                  const result = await leaseProfileServices.markLeaseExtensionTerminalStatus(leaseProfile.lease.leaseExtensions.find(el => el.terminalStatus !== "YES").id, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Lease Extended successfully!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showLeaseExtentionTerminalModal: !state.showLeaseExtentionTerminalModal }));
                    this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>Extended Lease Details</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group controlId="leaseExtension.lease.leaseEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='New Lease End Date'
                            dateFormat='MMMM d, yyyy'
                            name='leaseExtension.lease.leaseEndDate'
                            onChange={setFieldValue}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="leaseExtension.lease.lockInEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='New Lock-in End Date'
                            dateFormat='MMMM d, yyyy'
                            name='leaseExtension.lease.lockInEndDate'
                            // value={values.leaseExtension.lease.lockInEndDate}
                            onChange={setFieldValue}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h5>New Rent Periods <span className='text-danger'>*</span></h5>
                        <p>Multiple rent periods can be added, atleast one rent period is required</p>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs={4}>
                            <Form.Label>Start Date</Form.Label>
                          </Col>
                          <Col xs={4}>
                            <Form.Label>End Date</Form.Label>
                          </Col>
                          <Col xs={3}>
                            <Form.Label>Rent (in {leaseProfile.lease.currency})</Form.Label>
                          </Col>
                        </Row>
                        <FieldArray
                          name="leaseExtension.lease.rentPeriods"
                          render={arrayHelpers => (
                            <div>
                              {values.leaseExtension.lease.rentPeriods.map((rentPeriod, index) =>
                              (<Row key={index}>
                                <Col xs={4}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}startDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='MMMM d, yyyy'
                                      name={`leaseExtension.lease.rentPeriods[${index}].startDate`}
                                      value={rentPeriod.startDate}
                                      onChange={setFieldValue}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={4}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}endDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='MMMM d, yyyy'
                                      name={`leaseExtension.lease.rentPeriods[${index}].endDate`}
                                      value={rentPeriod.endDate}
                                      onChange={setFieldValue}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={3}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}rent`}>
                                    <Form.Control type="number" name={`leaseExtension.lease.rentPeriods[${index}].rent`} onChange={handleChange} onBlur={handleBlur} value={rentPeriod.rent} />
                                  </Form.Group>
                                </Col>

                                {index > 0 && <Col xs={1}>
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>}
                              </Row>)
                              )}
                              <Button className='mb-3' variant="outline-primary" size="sm" onClick={() => arrayHelpers.push({ startDate: undefined, endDate: undefined, rent: undefined })}>Add Another Rent Period</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleLeaseExtensionTerminalButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Submit'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 19} show={this.state.showMoveInInitiationModal} onHide={this.handleMoveInInitiationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Start Move-in Process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                lease: {
                  expectedMoveInDate: (leaseProfile?.lease?.expectedMoveInDate) || undefined,
                  moveInInitiationStatus: 'YES'
                }
              }}
              validationSchema={moveInInitiationSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await leaseProfileServices.markMoveInInitiationStatus(leaseId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-in Process started!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMoveInInitiationModal: !state.showMoveInInitiationModal }));
                    await this.getLeaseDetails();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.Group controlId="lease.expectedMoveInDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Move-in Date'
                            dateFormat='MMMM d, yyyy'
                            name='lease.expectedMoveInDate'
                            isMandatory={true}
                            value={values.lease.expectedMoveInDate}
                            onChange={setFieldValue}
                            minDate={new Date()}
                          />
                          {errors.lease && errors.lease.expectedMoveInDate && touched.lease && touched.lease.expectedMoveInDate && <Form.Text className="text-danger">
                            {errors.lease.expectedMoveInDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMoveInInitiationButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save & Start'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 20} show={this.state.showMarkPrimaryUserModal} onHide={this.handleMarkPrimaryUserButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Mark Primary {this.state.userType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Confirm marking this {this.state.userType} as Primary?</strong> <br />There can be only one Primary {this.state.userType} for each Lease.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleMarkPrimaryUserButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmMarkPrimaryUserButtonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 21} show={this.state.showDeleteLeaseTenantParticipationModal} onHide={this.handleDeleteLeaseTenantParticipationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete {this.state.userType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Are you sure you want to remove the {this.state.userType} from this Lease?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteLeaseTenantParticipationButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteLeaseTenantParticipationButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});
export default connect(
  mapStateToProps,
  null
)(LeaseProfilePage);
