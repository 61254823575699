import React, { Component } from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Accordion, Modal, Form, Badge } from 'react-bootstrap';
import NotesComponent from '../../../partials/layout/NotesComponent';
import TasksListComponent from '../../../partials/layout/TasksListComponent';
import { rentPaymentBillsServices } from './RentPaymentBills.services';
import { commonServices } from '../../../../app/commonServices/commonServices';
import { connect } from 'react-redux';
import * as constants from '../../../constants';
import { Formik } from 'formik';
import { formatDate, formatProperCase, formatDateTime, formatAmount } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as firebase from 'firebase/app';
import AdditionalPaymentDetails from '../../../partials/layout/AdditionalPaymentDetails';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import * as Yup from 'yup';
import { Link, withRouter } from 'react-router-dom';
import FilterComponent from '../../../partials/layout/FilterComponent';

const newRentPaymentBillSchema = Yup.object().shape({
  tenantRentPaymentBill: Yup.object().shape({
    leaseId: Yup.number().required(),
    payableDurationStartDate: Yup.date().required('Payable Duration Start Date is a mandatory field'),
    payableDurationEndDate: Yup.date().required('Payable Duration End Date is a mandatory field'),
    paymentDueDate: Yup.date().required('Payment Due Date is a mandatory field'),
    amount: Yup.number().required('Amount is a mandatory field').moreThan(4999, 'Amount cannot be less than 5000'),
    billAmount: Yup.number().required('Bill Amount is a mandatory field')
  })
});

const editRentPaymentBillSchema = Yup.object().shape({
  tenantRentPaymentBill: Yup.object().shape({
    payableDurationStartDate: Yup.date().required('Payable Duration Start Date is a mandatory field'),
    payableDurationEndDate: Yup.date().required('Payable Duration End Date is a mandatory field'),
    paymentDueDate: Yup.date().required('Payment Due Date is a mandatory field'),
    amount: Yup.number().required('Amount is a mandatory field').moreThan(4999, 'Amount cannot be less than 5000'),
    updateRemarks: Yup.string().required('Update Remarks is a mandatory field')
  })
});

const transferValidationSchema = Yup.object().shape({
  transfer: Yup.object().shape({
    amount: Yup.number().moreThan(0, 'Amount cannot be zero or negative').required('Amount is a mandatory field'),
    transferMode: Yup.string().required('Select Transfer Mode')
  })
});

class RentPaymentBills extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showCreateRentPaymentBillModal: false,
      showAcceptPaymentConfirmationModal: false,
      showRejectPaymentConfirmationModal: false,
      showEditRentPaymentModal: false,
      showMarkAsPaidRentPaymentModal: false,
      showTransferModal: false,
      showMarkAsDepositedModal: false,
      showClearedOrBouncedModal: false,
      showDeleteRentPaymentBillModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      showChequeDetailsFields: false,
      state: (!props.houseIdFromPropertyProfile && !props.leaseIdFromLeaseProfile) ? ['OPEN'] : undefined,
      propertyManagementStatus: ['YES'],
      requestedFilters: ['state', 'propertyManagementStatus', 'pmsSubscriptionType'],
      noteComponentId: undefined,
      originalRentAmount: 0,
      allowedUserEmails: ["prem.gupta@azuro.in", "gauri.morey@azuro.in", "support@azuro.in", "sudhanshu_mishra@azuro.in"]
    }
  }

  async componentDidMount() {
    await this.getAllTenantRentPaymentBills();
    await this.getCities();
    await this.getPropertyManagers();
  }

  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  getAllTenantRentPaymentBills = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, leaseIdFromLeaseProfile, cityIds,
      maximumPaymentDueDate, maximumLatestNoteCreatedAt, propertyManagerId, state, propertyManagementStatus, pmsSubscriptionType, rentBillstatus } = this.state;
    const data = {
      state: (state && state[0]) || undefined,
      maximumPaymentDueDate, maximumLatestNoteCreatedAt, rentBillstatus,
      // propertyManagementStatus: (houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? undefined : 'YES',
      propertyManagementStatus: (!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile) ? (propertyManagementStatus && propertyManagementStatus[0]) : undefined,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      cityIds,
      houseId: houseIdFromPropertyProfile,
      leaseId: leaseIdFromLeaseProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
      minimumExclusiveFeeRateQuantity: pmsSubscriptionType && pmsSubscriptionType.includes("Paid") ? 0 : undefined,
      maximumFeeRateQuantity: pmsSubscriptionType && pmsSubscriptionType.includes("Free") ? 0 : undefined
    }
    const result = await rentPaymentBillsServices.getAllTenantRentPaymentBills(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      this.setState({
        rentPaymentBills: result.data.tenantRentPaymentBills,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      })
    }
    this.setState({ isFetching: false });
  }

  getTenantRentPaymentBillsById = async (id) => {
    const result = await rentPaymentBillsServices.getTenantRentPaymentBillsById(id);
    if (result && result.status === 200) {
      if (result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments.length > 0) {
        result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments.forEach((rpa, index) => { rpa.id = index; rpa.isChecked = true; });
        result.data.tenantRentPaymentBill.initialFinalAmount = await this.calculateFinalAmountForBillAdjustments(null, result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments, null);
      }
      await this.setState({
        accordionData: result.data.tenantRentPaymentBill,
        toBeEdited: result.data.tenantRentPaymentBill,
      });
      this.state.rentPaymentBills.forEach(bill => {
        if (this.state.accordionData?.id === bill?.id) {
          bill.notes = this.state.accordionData.notes;
          bill.notes.reverse();
        }
      })
      this.forceUpdate();
    }
  }

  handleNewRentPaymentBillButtonClick = async (leaseIdFromLeaseProfile = null) => {
    if (leaseIdFromLeaseProfile !== null) {
      const result = await rentPaymentBillsServices.getRentPaymentBillAdjustments(leaseIdFromLeaseProfile);
      if (result.status === 200) {
        if (result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments.length > 0) {
          result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments.forEach((rpa, index) => { rpa.id = index; rpa.isChecked = true; });
          result.data.tenantRentPaymentBill.initialFinalAmount = await this.calculateFinalAmountForBillAdjustments(null, result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments, null);
        }
        this.setState({
          suggestedPaymentBillAdjustments: result.data.tenantRentPaymentBill.resultantPaymentBillAdjustments,
          initialFinalAmount: result.data.tenantRentPaymentBill.initialFinalAmount
        });
      }
    }
    this.setState(state => ({ showCreateRentPaymentBillModal: !state.showCreateRentPaymentBillModal }))
  }

  calculateFinalAmountForBillAdjustments = async (e, rpas, setFieldValue) => {
    let finalAmount = Number(this.state.originalRentAmount);
    rpas && rpas.forEach(rpa => {
      if (rpa.isChecked) {
        console.log('subtracting', Number(rpa.adjustedPaymentBill.amount));
        finalAmount = finalAmount - Number(rpa.adjustedPaymentBill.amount);
      }
    });
    if (setFieldValue) {
      setFieldValue(`tenantRentPaymentBill.amount`, finalAmount.toFixed(2));
      this.forceUpdate();
    } else {
      return finalAmount.toFixed(2);
    }
  }

  handleAcceptPaymentConfirmationButtonClick = async (confirmationId = null) => {
    await this.setState(state => ({
      showAcceptPaymentConfirmationModal: !state.showAcceptPaymentConfirmationModal,
      confirmationId: confirmationId
    }));

    if (this.state.showAcceptPaymentConfirmationModal === true) {
      firebase.analytics().logEvent("accept_payment_button_click", { description: "Accept Payment Button Clicked" });
    }
  }

  handleRejectPaymentConfirmationButtonClick = (confirmationId = null) => {
    this.setState(state => ({
      showRejectPaymentConfirmationModal: !state.showRejectPaymentConfirmationModal,
      confirmationId: confirmationId
    }));
    if (this.state.showRejectPaymentConfirmationModal === true) {
      firebase.analytics().logEvent("reject_payment_button_click", { description: "Reject Payment Button Clicked" });
    }
  }

  handleMarkAsDepositedButtonClick = (chequeId = null) => {
    this.setState(state => ({
      showMarkAsDepositedModal: !state.showMarkAsDepositedModal,
      chequeId
    }));
  }

  handleClearedOrBouncedButtonClick = (chequeId = null, isCleared = null, selectedCheque = null) => {
    this.setState(state => ({
      showClearedOrBouncedModal: !state.showClearedOrBouncedModal,
      chequeId,
      isCleared,
      selectedCheque
    }));
  }

  handleConfirmAcceptPaymentConfirmationButtonClick = async () => {
    const { accordionData, confirmationId } = this.state;
    const result = await rentPaymentBillsServices.markPaymentReceiptStatus(confirmationId && confirmationId, 'YES')
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: `Payment confirmed!`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
      this.setState(state => ({ showAcceptPaymentConfirmationModal: !state.showAcceptPaymentConfirmationModal }));
      this.getTenantRentPaymentBillsById(accordionData && accordionData.id);
    }
  }

  handleConfirmRejectPaymentConfirmationButtonClick = async () => {
    const { accordionData, confirmationId } = this.state;
    const result = await rentPaymentBillsServices.markPaymentReceiptStatus(confirmationId && confirmationId, 'NO')
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: `Payment rejected!`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
      this.setState(state => ({ showRejectPaymentConfirmationModal: !state.showRejectPaymentConfirmationModal }));
      this.getTenantRentPaymentBillsById(accordionData && accordionData.id);
    }
  }

  handleEditRentPaymentBillButtonClick = async (id = null) => {
    this.setState(state => ({ showEditRentPaymentModal: !state.showEditRentPaymentModal }));
  }

  getNewTransferInformation = async (data) => {
    const result = await commonServices.getNewTransferBankAccountsAndPaymentAdjustments(data);
    if (result && result.status === 200) {
      this.setState({
        bankAccountsForTransfer: result.data.bankAccounts,
        transferNotes: {
          note: result.data.transferNotes,
          updatedAt: result.data.transferNotesUpdatedAt,
          createdBy: result.data.transferNotesCreatedBy
        },
        sourcePaymentForTransfer: result.data.sourcePayment
      });
      if (result.data.suggestedPaymentAdjustments && result.data.suggestedPaymentAdjustments.length > 0) {
        result.data.suggestedPaymentAdjustments = await this.addDefaultTDSAndInvoiceTotals(result.data.suggestedPaymentAdjustments);
        result.data.suggestedPaymentAdjustments.forEach((spa, index) => { spa.id = index; spa.isChecked = true; });
        result.data.initialFinalAmount = await this.calculateFinalAmountForTransfer(null, result.data.suggestedPaymentAdjustments, null);
      } else {
        result.data.initialFinalAmount = this.state.accordionData.pendingTransferableAmount;
      }
      this.setState({
        suggestedPaymentAdjustments: result.data.suggestedPaymentAdjustments,
        initialFinalAmount: result.data.initialFinalAmount
      });
    }
  }

  addDefaultTDSAndInvoiceTotals = async (spa) => {
    spa.forEach(adjustment => {
      adjustment.invoice.invoiceItems.push({ "name": "TDS", "amount": 0, "code": "TDS", "effect": "NEGATIVE" });
      let total = 0;
      adjustment.invoice.invoiceItems.forEach(item => total += Number(item.amount));
      adjustment.invoice.invoiceItems.push({ "name": "Total", "amount": (total).toFixed(2) });
    })
    return spa;
  }

  handleInitiateTransferButtonClick = async (sourcePaymentId = null) => {
    if (sourcePaymentId) {
      let data = { sourcePaymentId: sourcePaymentId };
      await this.getNewTransferInformation(data);
      this.setState(state => ({ transferData: data }));
    }
    this.setState(state => ({ showTransferModal: !state.showTransferModal }));
  }

  handleRedirect = (paymentId) => {
    this.props.history.push({
      pathname: "/refund-payments",
      state: { paymentId }
    });
  };

  handleMarkAsPaidRentPaymentBillButtonClick = async () => {
    await this.setState(state => ({ showMarkAsPaidRentPaymentModal: !state.showMarkAsPaidRentPaymentModal }));
    if (this.state.showMarkAsPaidRentPaymentModal === true) {
      firebase.analytics().logEvent("mark_as_paid_button_click", { description: "Mark As Paid Button Clicked" });
    }
  }

  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getTenantRentPaymentBillsById(id);
      firebase.analytics().logEvent("rent_payments_details_view_click", { description: "Rent Payment Bills Details View Clicked", rentPaymentId: id });
    }
  }

  handleChildData = async (rentPaymentId) => {
    this.getTenantRentPaymentBillsById(rentPaymentId);

  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllTenantRentPaymentBills();
  }

  handleAddPaymentData = async (tenantRentPaymentBillId) => {
    if (tenantRentPaymentBillId) {
      await this.getTenantRentPaymentBillsById(tenantRentPaymentBillId);
    }
  }

  logViewProof = () => {
    firebase.analytics().logEvent("rent_payments_view_proof_click", { description: "Rent Payment Bills View Proof Clicked" });
  }

  getOwners = async () => {
    const data = {
      leaseId: this.state.leaseIdFromLeaseProfile,
      stakeHolderCategories: ['OWNERS']
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      const owners = result.data && result.data.users;
      this.setState({ owners })
    }
  }

  getTenants = async () => {
    const data = {
      leaseId: this.state.leaseIdFromLeaseProfile,
      stakeHolderCategories: ['TENANTS']
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      const tenants = result.data && result.data.users;
      this.setState({ tenants })
    }
  }

  handleCustomChange = async (e, setFieldValue, isPayerCategory = undefined) => {
    const { tenants, owners } = this.state;
    if (e.target.validity.valid) {
      !tenants && e.target.value === 'TENANT' && await this.getTenants();
      !owners && e.target.value === 'OWNER' && await this.getOwners();
      isPayerCategory && e.target.value === 'AZURO' && setFieldValue('tenantRentPaymentBill.cheque.keeperId', null);
    }
  };

  showHideChequeDetailsFieldsButtonClick = async (setFieldValue) => {

    const { showChequeDetailsFields } = this.state;
    /**
     * TODO: Improve the logic there
     */
    if (showChequeDetailsFields) {
      setFieldValue('tenantRentPaymentBill.cheque.payerCategory', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.payerId', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.payeeCategory', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.payeeId', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.keeperCategory', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.keeperId', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.amount', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.date', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.chequeNumber', undefined);
      setFieldValue('tenantRentPaymentBill.cheque.sourceBankName', undefined);
    }
    await this.setState({ showChequeDetailsFields: !this.state.showChequeDetailsFields });
  }

  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.resetPage();
      await this.getAllTenantRentPaymentBills();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Rent Payment Bills' });
    }
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  checkIfTheBillHasDebitPayment = (payments) => {
    const debitPayment = payments?.find(payment => payment.direction === 'DEBIT');
    if (debitPayment) {
      return true;
    } else {
      return false;
    }
  }

  calculateFinalAmountForTransfer = async (e, rpas, setFieldValue) => {
    const { accordionData } = this.state;
    let finalAmount = Number(accordionData.pendingTransferableAmount);
    rpas.forEach(rpa => {
      if (rpa.isChecked) {
        finalAmount = finalAmount - Number(rpa.invoice.invoiceItems[rpa.invoice.invoiceItems.length - 1].amount);
      }
    });
    if (setFieldValue) {
      setFieldValue(`transfer.amount`, finalAmount.toFixed(2))
      this.forceUpdate();
    } else {
      return finalAmount.toFixed(2);
    }
  }

  updateGSTTDSAndTotalAmount = async (e, rpas, index, setFieldValue) => {
    let finalFee = 0;
    if (e.target.name === "transfer.resultantPaymentAdjustments[" + index + "].invoice.invoiceItems[0].amount") {
      let newGST = 0; //to be re-calculated
      let newTDS = rpas[index].invoice.invoiceItems[2].amount;
      newGST = (Number(e.target.value) * 0.18).toFixed(2);
      setFieldValue(`transfer.resultantPaymentAdjustments[${index}].invoice.invoiceItems[1].amount`, newGST)
      rpas[index].invoice.invoiceItems[1].amount = newGST;
      finalFee = (Number(e.target.value) + Number(newGST) - Number(newTDS)).toFixed(2);
      rpas[index].invoice.invoiceItems[3].amount = finalFee;
      setFieldValue(`transfer.resultantPaymentAdjustments[${index}].invoice.invoiceItems[3].amount`, finalFee);
    }
    if (e.target.name === "transfer.resultantPaymentAdjustments[" + index + "].invoice.invoiceItems[2].amount") {
      let pmsFee = rpas[index].invoice.invoiceItems[0].amount;
      let newGST = rpas[index].invoice.invoiceItems[1].amount;
      finalFee = (Number(pmsFee) + Number(newGST) - Number(e.target.value)).toFixed(2);
      rpas[index].invoice.invoiceItems[3].amount = finalFee;
      setFieldValue(`transfer.resultantPaymentAdjustments[${index}].invoice.invoiceItems[3].amount`, finalFee);
    }
    this.calculateFinalAmountForTransfer(e, rpas, setFieldValue);
  }

  handleDeleteRentPaymentButtonClick = (rentPaymentId = null) => {
    if (rentPaymentId !== null) {
      this.setState({ idToBeDeleted: rentPaymentId })
    }
    this.setState(state => ({ showDeleteRentPaymentBillModal: !state.showDeleteRentPaymentBillModal }));
  }

  handleConfirmDeleteRentPaymentButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await rentPaymentBillsServices.deleteRentPaymentBill(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Rent Payment Bill deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteRentPaymentBillModal: !state.showDeleteRentPaymentBillModal }));
        this.getAllTenantRentPaymentBills();
      }
    }
  }

  getReqStatusBadge = (requestStatus) => {
    return requestStatus === 'CLOSED' ? <Badge variant="info" title="Closed">C</Badge> : <Badge variant="success" title="Open">O</Badge>
  }

  render() {
    const { rentPaymentBills, accordionData, showMarkAsPaidRentPaymentModal, showCreateRentPaymentBillModal, showEditRentPaymentModal, showDeleteRentPaymentBillModal,
      showAcceptPaymentConfirmationModal, showRejectPaymentConfirmationModal, showTransferModal, allowedUserEmails,
      page, total, totalCount, houseIdFromPropertyProfile, leaseIdFromLeaseProfile,
      isFetching, bankAccountsForTransfer, sourcePaymentForTransfer, transferNotes, suggestedPaymentAdjustments, initialFinalAmount, transferData, tenants, owners, showMarkAsDepositedModal,
      chequeId, isCleared, showClearedOrBouncedModal, selectedCheque, formattedCities, preSelectedCities,
      maximumPaymentDueDate, maximumLatestNoteCreatedAt, propertyManagers, propertyManagerId, requestedFilters, state, propertyManagementStatus, suggestedPaymentBillAdjustments, pmsSubscriptionType
    } = this.state;
    let showPagination = false;
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid key="c1">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Rent Payment Bills (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  {leaseIdFromLeaseProfile && <Button className="mr-2" variant="link" size="sm" onClick={() => this.handleNewRentPaymentBillButtonClick(leaseIdFromLeaseProfile)}>New Rent Payment Bill</Button>}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                requestedFilters={requestedFilters}
                state={state}
                formattedCities={!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile ? preSelectedCities : undefined}
                maximumPaymentDueDate={maximumPaymentDueDate}
                maximumLatestNoteCreatedAt={maximumLatestNoteCreatedAt}
                propertyManagers={!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile ? propertyManagerId : undefined}
                propertyManagementStatus={!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile ? propertyManagementStatus : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                rentPaymentBillstatusData={constants.RENT_PAYMENT_BILL_STATUS}
                selectedRecordValue={this.state.limit}
                selectedRentBillstatus={this.state.rentBillstatus}
                pmsSubscriptionType={pmsSubscriptionType}
                filterParentCallback={this.handleFilterChildData}
              />
              <Accordion defaultActiveKey="0">
                <Card>
                  {rentPaymentBills && rentPaymentBills.length === 0 && <p>{constants.ZERO_DATA}</p>}
                  {rentPaymentBills && rentPaymentBills.length > 0 &&
                    <Card.Header>
                      <Row className="py-2 px-3 azuro-table-header">
                        {!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile && <Col xs={4}><strong>Property</strong></Col>}
                        <Col xs={1}><strong>Bill ID</strong></Col>
                        <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 2 : 1}><strong>Payable Amount</strong></Col>
                        <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2}><strong>Due Date &amp; Payable Period</strong></Col>
                        <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2}><strong>Latest Update</strong></Col>
                        <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2}><strong>Status</strong></Col>
                      </Row>
                    </Card.Header>}
                </Card>
                {rentPaymentBills && rentPaymentBills.map(rentPayment =>
                  <Card key={rentPayment.id}>
                    <Accordion.Toggle as={Card.Header} eventKey={rentPayment.id} className="row py-2 azuro-table-row align-items-center"
                      onClick={() => { this.handleAccordionClick(rentPayment.id) }}
                    >
                      {!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile && <Col xs={4} >
                        <span className="pr-3">{this.getReqStatusBadge(((rentPayment && rentPayment.id) && rentPayment.terminalStatus))}</span>
                        {rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house && <span>{rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house?.formattedHouseName} <br />
                          <span className="small text-muted pl-5">{rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house.propertyManager ? `PM: ${(rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house.propertyManager && rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                        </span>}
                      </Col>}

                      <Col xs={1}>
                        {(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) && <span className="pr-3">{this.getReqStatusBadge(((rentPayment && rentPayment.id) && rentPayment.terminalStatus))}</span>}
                        #{rentPayment.id}
                      </Col>
                      <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 2 : 1}>
                        {rentPayment.amount && formatAmount(rentPayment.amount, rentPayment.currency)}
                      </Col>
                      <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2}>
                        {rentPayment.paymentDueDate && formatDate(rentPayment.paymentDueDate)}<br />
                        <span className='small'>{rentPayment.payableDurationStartDate && formatDate(rentPayment.payableDurationStartDate)}
                          {` to `}
                          {rentPayment.payableDurationEndDate && formatDate(rentPayment.payableDurationEndDate)}</span>
                      </Col>
                      <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2}>
                        {(rentPayment.notes.length === 0) ? "No Updates" : (formatDate(rentPayment.notes[0].createdAt)) + " : " + (rentPayment.notes[0].content)}
                      </Col>
                      <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 3 : 2} className="d-flex justify-content-between align-items-center">
                        <span className="flex-fill">
                          {rentPayment.status}
                        </span>
                        {rentPayment.lease && rentPayment.lease.houseOfferings && rentPayment.lease.houseOfferings.house && <React.Fragment>
                          <Link to={`/property-profile/${rentPayment.lease.houseOfferings.house.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                          </Link>
                          <Link to={`/lease-profile/${rentPayment.lease.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-edit-1"></i></Button>
                          </Link>
                        </React.Fragment>}
                      </Col>

                    </Accordion.Toggle>

                    {
                      accordionData && (accordionData.id === rentPayment.id) &&
                      <Accordion.Collapse eventKey={rentPayment.id}>
                        <Card.Body>
                          <Row className="mb-4">
                            <Col>
                              <p><small>Created by {accordionData && accordionData.createdBy?.fullName ? accordionData.createdBy?.fullName : "Unknown"} at {formatDateTime(accordionData.createdAt)}</small></p>
                            </Col>
                            {!accordionData.terminalStatus && <Col xs={12}>
                              {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) && <>
                                <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleEditRentPaymentBillButtonClick(accordionData.id)}><i className="flaticon2-edit"></i></Button>
                                {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && (Math.floor((Math.abs(new Date() - new Date(accordionData.createdAt))) / (1000 * 60 * 60 * 24)) < 180) && accordionData.status === 'PENDING') &&
                                  <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleDeleteRentPaymentButtonClick(accordionData.id)}><i className="flaticon2-trash"></i></Button>}
                              </>
                              }
                            </Col>}
                          </Row>

                          <NotesComponent notes={accordionData.notes || []} allowAddNotes={
                            (!accordionData.terminalStatus)
                          }
                            rentPaymentId={accordionData.id}
                            parentCallback={this.handleChildData}
                          />

                          <TasksListComponent tasks={accordionData.tasks || []} allowAddTasks={
                            (!accordionData.terminalStatus)
                          }
                            rentPaymentId={accordionData.id}
                            houseId={accordionData?.house?.id}
                            parentCallback={this.handleChildData}
                            // approverData={propertySuperVisors && propertySuperVisors.length > 0 && propertySuperVisors}
                            approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                          />

                          <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Confirmations</h5>
                            </Col>
                            {accordionData.tenantRentPaymentConfirmations && accordionData.tenantRentPaymentConfirmations.length === 0 &&
                              <Col xs={12} className="text-center">
                                <p>No Confirmations</p>
                              </Col>
                            }
                            {accordionData.tenantRentPaymentConfirmations && accordionData.tenantRentPaymentConfirmations.length > 0 && accordionData.tenantRentPaymentConfirmations.map((confirmation, index) =>
                              <React.Fragment key={"confirmation" + index}>
                                <Col xs={6} className='d-flex align-items-center'>
                                  <p className='mb-0'>
                                    {/* {confirmation.date}: 
                                    */}
                                    {formatAmount(confirmation.amount, confirmation.currency)} via {confirmation.modeOfPayment} {confirmation.paymentConfirmer && `from ${confirmation.paymentConfirmer.fullName}`} | Ref: {confirmation.reference} {confirmation.paymentReceiptStatus !== null && ` | ${confirmation.paymentReceiptStatus}`}
                                  </p>
                                </Col>
                                <Col xs={6} className="text-right">
                                  <a onClick={() => this.logViewProof()} className="btn btn-clean btn-sm" target="_blank" rel="noopener noreferrer" href={confirmation.proofFile} >View Proof</a>
                                  {confirmation.paymentReceiptStatus === null && <>
                                    {` | `}
                                    <Button variant="clean" size="sm" className="text-success" onClick={() => this.handleAcceptPaymentConfirmationButtonClick(confirmation.id)}>Accept</Button>
                                    {` | `}
                                    <Button variant="clean" size="sm" className="text-danger" onClick={() => this.handleRejectPaymentConfirmationButtonClick(confirmation.id)}>Reject</Button>
                                    {/* | <Button variant="clean" size="sm"><i className="flaticon2-edit"></i></Button> | <Button variant="clean" size="sm"><i className="flaticon2-trash mr-0"></i></Button>*/}
                                  </>}
                                </Col>
                              </React.Fragment>
                            )
                            }
                          </Row>
                          <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Cheques</h5>
                            </Col>
                            {accordionData.cheques && accordionData.cheques.length === 0 &&
                              <Col xs={12} className="text-center">
                                <p>No Cheques</p>
                              </Col>
                            }
                            {accordionData.cheques && accordionData.cheques.length > 0 && accordionData.cheques.map(chq =>
                              <React.Fragment key={"chq" + chq.id}>
                                <Col xs={9}>
                                  <p>Date: {formatDate(chq.date)} | Amount: {formatAmount(chq.amount)} | Bank: {chq.sourceBankName}<br />
                                    Payer: {chq.payer?.fullName} ({chq.payerCategory}) | Payee: {chq.payee?.fullName} ({chq.payeeCategory}) | Keeper: {chq.keeper?.fullName} ({chq.keeperCategory})<br />
                                    Deposited: {chq.depositionStatus ? chq.depositionStatus : 'Not deposited'} | Clearance Status: {chq.terminalStatus ? chq.terminalStatus : 'Not Available'}
                                  </p>
                                </Col>
                                <Col xs={3} className="text-right">
                                  {
                                    chq.depositionStatus === null &&
                                    <>
                                      <Button variant="clean" size="sm" className="text-primary" onClick={() => this.handleMarkAsDepositedButtonClick(chq.id)}>Mark as Deposited</Button><br />
                                    </>}
                                  {
                                    chq.depositionStatus !== null && chq.terminalStatus === null &&
                                    <>
                                      <Button variant="clean" size="sm" className="text-success" onClick={() => this.handleClearedOrBouncedButtonClick(chq.id, true, chq)}>Mark as Cleared</Button>
                                      <Button variant="clean" size="sm" className="text-danger" onClick={() => this.handleClearedOrBouncedButtonClick(chq.id, false, chq)}>Mark as Bounced</Button>
                                    </>}
                                </Col>
                              </React.Fragment>)
                            }
                          </Row>
                          {accordionData.effectiveTenantRentPaymentPreBill && accordionData.effectiveTenantRentPaymentPreBill.id && <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Owner and Bank Account Details </h5>
                            </Col>
                            <Col xs={12}>
                              <p className="mb-0"><strong>Owner's Name</strong>: {accordionData.effectiveTenantRentPaymentPreBill.payeeProperties.fullName}</p>
                              <p className="mb-0"><strong>Email</strong>: {accordionData.effectiveTenantRentPaymentPreBill.payeeProperties.email}</p>
                              <p className="mb-0"><strong>Phone Number</strong>: +{accordionData.effectiveTenantRentPaymentPreBill.payeeProperties?.phoneCountryCode}-{accordionData.effectiveTenantRentPaymentPreBill.payeeProperties?.phoneNumber}</p>
                              <p className="mb-0"><strong>PAN</strong>: {accordionData.effectiveTenantRentPaymentPreBill.payeeProperties.pan || 'Not Entered'}</p>
                              {(accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties?.vpa?.length > 0) ?
                                <p className="mb-0"><strong>VPA</strong>: {accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties.vpa}</p> :
                                <span><p className="mb-0"><strong>Account Holder's Name</strong>: {accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties.accountHolderName}</p>
                                  <p className="mb-0"><strong>Account Number</strong>: {accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties.accountNumber}</p>
                                  <p className="mb-0"><strong>Account Type</strong>: {accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties.accountType}</p>
                                  <p><strong>IFSC</strong>: {accordionData.effectiveTenantRentPaymentPreBill.bankAccountProperties.ifsc}</p></span>
                              }
                            </Col>
                          </Row>}
                          <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Bill Adjustments</h5>
                            </Col>
                            <Col xs={12}>
                              <p className="mb-0 small text-muted">As per Lease Agreement</p>
                              <p>Actual Rent Amount: {formatAmount(accordionData.billAmount)}</p>
                            </Col>
                            {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length === 0 &&
                              <Col xs={12}>
                                <p>No Bill Adjustments</p>
                              </Col>
                            }
                            {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length > 0 && accordionData.resultantPaymentBillAdjustments.map(billAdjust =>
                              <>
                                <Col xs={6}>
                                  <p className="mb-0 small text-muted">Payment Bill #{billAdjust.adjustedPaymentBill.id}</p>
                                  <p>Token Amount: <span className={billAdjust.effect === "NEGATIVE" ? "text-danger" : ""}>{(billAdjust.effect === "NEGATIVE") ? "-" : ""}{formatAmount(billAdjust.adjustedPaymentBill.amount)}</span></p>
                                </Col>
                              </>)
                            }
                          </Row>
                          <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Payments</h5>
                            </Col>
                          </Row>
                          {accordionData.payments && accordionData.payments.length === 0 && <Row>
                            <Col xs={12} className="text-center">
                              <p>No Payments</p>
                            </Col>
                          </Row>}
                          {accordionData.payments && accordionData.payments.length > 0 && accordionData.payments.map(payment => <Row key={'payment' + payment.id}>
                            <Col xs={8}>
                              <p className="small text-muted mb-0">Payment ID #{payment.id} | Ref No: {payment.reference}</p>
                              <p className="mb-0"><span className={payment.direction === 'CREDIT' ? 'text-success' : 'text-danger'}>{payment.direction}</span> | {formatAmount(payment.amount, payment.currency)} via {payment.mode} | {payment.user && `${payment.direction === 'DEBIT' ? 'Paid to ' : 'Received from '} ${payment.user.fullName}`}</p>
                              <p className="small text-muted">on {formatDateTime(payment.datetime)}</p>
                            </Col>
                            <Col xs={2} className="text-right">
                              {payment.paymentGatewayProperties && <AdditionalPaymentDetails paymentDetails={payment.paymentGatewayProperties} />}
                            </Col>
                            <Col xs={8}>
                              {payment.direction === 'CREDIT' && payment.paymentGatewayCharges > 0 &&
                                <h6 className="pb-1">Processing Fee: {formatAmount(payment.paymentGatewayCharges)}</h6>}
                            </Col>
                            <Col xs={2} className="text-right pb-1">
                              {payment.direction === 'CREDIT' && payment.paymentGatewayCharges > 0 && ((this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) && allowedUserEmails.includes(this.props.userDetails.email)) &&
                                <Button variant="link" size="sm" onClick={() => this.handleRedirect(payment.id)}>Go to Refund</Button>}
                            </Col>
                            <Col xs={2} className="text-right">
                              {((this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) && allowedUserEmails.includes(this.props.userDetails.email)) && (payment?.direction === 'CREDIT') && (payment?.paymentGatewayName === 'CASHFREE') &&
                                (accordionData.terminalStatus === null) && payment?.currency === 'INR' && payment?.paymentGatewaySettlementStatus === 'YES' && accordionData.pendingTransferableAmount > 0 &&
                                <Button variant="link" size="sm" onClick={() => this.handleInitiateTransferButtonClick(payment.id)}>Initiate Transfer</Button>
                              }
                            </Col>
                            {payment.resultantPaymentAdjustments && payment.resultantPaymentAdjustments.length > 0 && <Col xs={12} className="pl-4">
                              <h6>Adjustments of this Payment:</h6>
                            </Col>}
                            {payment.resultantPaymentAdjustments && payment.resultantPaymentAdjustments.length > 0 && payment.resultantPaymentAdjustments.map((rpa, index) => <Col xs={12} className="pl-4 mb-3" key={"rpa" + index}>
                              <p className="small text-muted mb-0">Invoice #{rpa.invoice.invoiceNumber}</p>
                              {rpa.invoice.invoiceItems && rpa.invoice.invoiceItems.length > 0 && rpa.invoice.invoiceItems.map((item, index) => <p key={"rpaInvoiceItem" + index} className="mb-0">&gt; {item.name} : {formatAmount(item.amount)}</p>)}
                              <p className="mb-0">Total: {formatAmount(rpa.invoice.totalAmount)}</p>
                            </Col>)}
                          </Row>)}
                          {accordionData.pendingTransferableAmount > 0 ? <Row>
                            <Col xs={12}>
                              <p className='text-warning'><strong>Amount pending to be transferred: {formatAmount(accordionData.pendingTransferableAmount)}.</strong> You may close the bill after initiating transfer.</p>
                            </Col>
                          </Row> : <Row>
                            <Col xs={12}>
                              <p className='text-muted'>No amount pending for transfer for this bill. {accordionData.status === 'TRANSFERRED' && !accordionData.terminalStatus && <strong className='text-warning'>You may close the bill now.</strong>}</p>
                            </Col>
                          </Row>}
                          <Row className="mb-4">
                            <Col xs={12} className="text-right">
                              {accordionData.terminalStatus === null && <Button className="ml-2" variant="outline-primary" size="sm" disabled={accordionData.pendingTransferableAmount > 9} onClick={this.handleMarkAsPaidRentPaymentBillButtonClick}>Close Bill</Button>}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    }
                  </Card>
                )
                }
              </Accordion>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key="m1" show={showAcceptPaymentConfirmationModal} onHide={this.handleAcceptPaymentConfirmationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Accept Payment Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Confirm Accept of this Payment Confirmation?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleAcceptPaymentConfirmationButtonClick}>
              Cancel
            </Button>
            <Button variant="success" onClick={this.handleConfirmAcceptPaymentConfirmationButtonClick}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m2" show={showRejectPaymentConfirmationModal} onHide={this.handleRejectPaymentConfirmationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Reject Payment Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Confirm Reject of this Payment Confirmation?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleRejectPaymentConfirmationButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmRejectPaymentConfirmationButtonClick}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m3" show={showCreateRentPaymentBillModal} onHide={() => this.handleNewRentPaymentBillButtonClick(null)}>
          <Modal.Header closeButton>
            <Modal.Title>New Rent Payment Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                tenantRentPaymentBill: {
                  leaseId: parseInt(leaseIdFromLeaseProfile),
                  payableDurationStartDate: undefined,
                  payableDurationEndDate: undefined,
                  paymentDueDate: undefined,
                  billAmount: undefined,
                  amount: undefined,
                  cheque: undefined,
                  resultantPaymentBillAdjustments: suggestedPaymentBillAdjustments,
                }
              }}
              validationSchema={newRentPaymentBillSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let sendingValues = JSON.parse(JSON.stringify(values));
                  //using sendingValues for manipulation to keep form values intact for retrying if it fails.
                  if (sendingValues.tenantRentPaymentBill?.resultantPaymentBillAdjustments && sendingValues.tenantRentPaymentBill?.resultantPaymentBillAdjustments.length > 0) {
                    sendingValues.tenantRentPaymentBill.resultantPaymentBillAdjustments = sendingValues.tenantRentPaymentBill.resultantPaymentBillAdjustments.filter(rpa => rpa.isChecked === true);
                    if (sendingValues.tenantRentPaymentBill.resultantPaymentBillAdjustments.length > 0) {
                      sendingValues.tenantRentPaymentBill.resultantPaymentBillAdjustments.forEach(rpa => {
                        delete rpa.id;
                        delete rpa.isChecked;
                        delete rpa.adjustedPaymentBill.tokenLeaseId;
                        delete rpa.adjustedPaymentBill.amount;
                      });
                    }
                  }
                  console.log("==== values manipulated before sending", JSON.stringify(sendingValues, null, 2));
                  const result = await rentPaymentBillsServices.createRentPaymentBill(sendingValues);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Rent Payment Bill created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateRentPaymentBillModal: !state.showCreateRentPaymentBillModal }));
                    this.getAllTenantRentPaymentBills();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>1. Add Bill Details:</h5>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.payableDurationStartDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payable Duration Start Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.payableDurationStartDate'
                            value={values.tenantRentPaymentBill.payableDurationStartDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.payableDurationStartDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.payableDurationStartDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.payableDurationStartDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.payableDurationEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payable Duration End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.payableDurationEndDate'
                            value={values.tenantRentPaymentBill.payableDurationEndDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.payableDurationEndDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.payableDurationEndDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.payableDurationEndDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.paymentDueDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payment Due Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.paymentDueDate'
                            value={values.tenantRentPaymentBill.paymentDueDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.paymentDueDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.paymentDueDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.paymentDueDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.billAmount">
                          <Form.Label>Bill Amount before Adjustments <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" name="tenantRentPaymentBill.billAmount" onChange={
                            async (e) => {
                              handleChange(e);
                              await this.setState({ originalRentAmount: e.target.value });
                              await this.calculateFinalAmountForBillAdjustments(e, values.tenantRentPaymentBill.resultantPaymentBillAdjustments, setFieldValue);
                            }
                          } onBlur={handleBlur} value={values.tenantRentPaymentBill.billAmount} />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.billAmount && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.billAmount && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.billAmount}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      {suggestedPaymentBillAdjustments && suggestedPaymentBillAdjustments.length > 0 && <Col xs={12}>
                        <h5>2. Adjustments to include in this bill:</h5>
                        {/* <h5>2. Choose Suggested Adjustments to include in this bill:</h5> */}
                        {values.tenantRentPaymentBill.resultantPaymentBillAdjustments.map((adjustment, index) =>
                          <Form.Group className={adjustment.isChecked ? 'card border-success p-3' : 'card p-3'} key={`tenantRentPaymentBill.resultantPaymentBillAdjustments[${index}].isChecked`} controlId={`tenantRentPaymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}>
                            <Form.Check type="checkbox" key={`tenantRentPaymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}>
                              <Form.Check.Input type="checkbox" readOnly
                                name={`tenantRentPaymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}
                                value={{ adjustment }}
                                checked={(adjustment.isChecked === true)}
                              // onClick={async (e) => {
                              //   adjustment.isChecked = !adjustment.isChecked;
                              //   await this.calculateFinalAmountForBillAdjustments(e, values.tenantRentPaymentBill.resultantPaymentBillAdjustments, setFieldValue);
                              // }}
                              />
                              <Form.Check.Label className="pl-3">Token Paid: {(adjustment.effect === 'NEGATIVE') ? '-' : ''}₹{adjustment.adjustedPaymentBill.amount}</Form.Check.Label>
                            </Form.Check>
                            <Form.Text className="text-muted">Note: This adjustment cannot be unchecked. Please create the Rent Bill or Security Deposit Bill where this is to be adjusted first.</Form.Text>
                          </Form.Group>
                        )}
                      </Col>}
                      {suggestedPaymentBillAdjustments && suggestedPaymentBillAdjustments.length === 0 && <Col xs={12} style={{ marginBottom: "2rem" }}>
                        <h5>2. Suggested Bill Adjustments:</h5>
                        <p className="small text-muted mb-0">No suggestions available at this time.</p>
                      </Col>}
                      <Col xs={12} md={6}>
                        <Form.Group controlId="tenantRentPaymentBill.amount">
                          <Form.Label>Final Payable Amount <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" min={1} readOnly name="tenantRentPaymentBill.amount" onChange={handleChange} onBlur={handleBlur} value={values.tenantRentPaymentBill.amount} />
                          {(errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.amount && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.amount) ? <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.amount}</Form.Text> : <Form.Text>Auto-calculated</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-link btn-sm" type="button" onClick={() => this.showHideChequeDetailsFieldsButtonClick(setFieldValue)}>{this.state.showChequeDetailsFields ? 'Remove' : 'Add'} Cheque Details</button>
                      </Col>
                    </Row>
                    {this.state.showChequeDetailsFields && <Row>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.amount">
                          <Form.Label>Cheque Amount</Form.Label>
                          <Form.Control type="number"
                            name="tenantRentPaymentBill.cheque.amount"
                            onChange={handleChange} onBlur={handleBlur}
                            value={values.tenantRentPaymentBill?.cheque?.amount}
                          />
                          {errors?.tenantRentPaymentBill?.cheque?.amount && touched?.tenantRentPaymentBill?.cheque?.amount && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.amount}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.date">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Cheque Date'
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.cheque.date'
                            value={values.tenantRentPaymentBill?.cheque?.date}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill?.cheque?.date && touched.tenantRentPaymentBill?.cheque?.date && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill?.cheque?.date}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.chequeNumber">
                          <Form.Label>Cheque Number</Form.Label>
                          <Form.Control type="text" name="tenantRentPaymentBill.cheque.chequeNumber" onChange={handleChange} onBlur={handleBlur} value={values?.tenantRentPaymentBill?.cheque?.chequeNumber} />
                          {errors?.tenantRentPaymentBill?.cheque?.chequeNumber && touched?.tenantRentPaymentBill?.cheque?.chequeNumber && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.chequeNumber}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.sourceBankName">
                          <Form.Label>Source Bank Name</Form.Label>
                          <Form.Control type="text" name="tenantRentPaymentBill.cheque.sourceBankName" onChange={handleChange} onBlur={handleBlur} value={values?.tenantRentPaymentBill?.cheque?.sourceBankName} />
                          {errors?.tenantRentPaymentBill?.cheque?.sourceBankName && touched?.tenantRentPaymentBill?.cheque?.sourceBankName && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.sourceBankName}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.payerCategory">
                          <Form.Label>Payer Category</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.payerCategory"
                            onChange={
                              async (e) => {
                                handleChange(e);
                                await this.handleCustomChange(e, setFieldValue)
                              }
                            }
                            onBlur={handleBlur} value={values.tenantRentPaymentBill?.cheque?.payerCategory}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.TENANT_RENT_PAYMENT_BILL_PAYER_CATEGORY.map((payerCategory, index) =>
                                (<option key={index} value={payerCategory}>{payerCategory}</option>)
                              )
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.payerCategory && touched?.tenantRentPaymentBill?.cheque?.payerCategory && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.payerCategory}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.payerId">
                          <Form.Label>Payer</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.payerId" onChange={handleChange} onBlur={handleBlur} value={values?.tenantRentPaymentBill?.cheque?.payerId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              tenants?.map((tenant =>
                                <option key={"tenantRentPaymentBill.cheque.payerId" + tenant.id} value={tenant.id}>{tenant.fullName}
                                  {tenant.userSubCategory && ' (' + formatProperCase(tenant.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.payerId && touched?.tenantRentPaymentBill?.cheque?.payerId && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.payerId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.payeeCategory">
                          <Form.Label>Payee Category</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.payeeCategory"
                            // onChange={handleChange}
                            onChange={
                              async (e) => {
                                handleChange(e);
                                await this.handleCustomChange(e, setFieldValue)
                              }
                            }
                            onBlur={handleBlur} value={values.tenantRentPaymentBill?.cheque?.payeeCategory}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.TENANT_RENT_PAYMENT_BILL_PAYEE_CATEGORY.map((payeeCategory, index) =>
                                (<option key={index} value={payeeCategory}>{payeeCategory}</option>)
                              )
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.payeeCategory && touched?.tenantRentPaymentBill?.cheque?.payeeCategory && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.payeeCategory}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.payeeId">
                          <Form.Label>Payee</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.payeeId" onChange={handleChange} onBlur={handleBlur} value={values.tenantRentPaymentBill?.cheque?.payeeId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              owners?.map((owner =>
                                <option key={"tenantRentPaymentBill.cheque.payeeId" + owner.id} value={owner.id}>{owner.fullName}
                                  {owner.userSubCategory && ' (' + formatProperCase(owner.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.payeeId && touched?.tenantRentPaymentBill?.cheque?.payeeId && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.payeeId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.keeperCategory">
                          <Form.Label>Keeper Category</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.keeperCategory"
                            onChange={
                              async (e) => {
                                handleChange(e);
                                await this.handleCustomChange(e, setFieldValue, true)
                              }
                            }
                            onBlur={handleBlur} value={values.tenantRentPaymentBill?.cheque?.keeperCategory}
                          >
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.TENANT_RENT_PAYMENT_BILL_KEEPER_CATEGORY.map((keeperCategory, index) =>
                                (<option key={index} value={keeperCategory}>{keeperCategory}</option>)
                              )
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.keeperCategory && touched?.tenantRentPaymentBill?.cheque?.keeperCategory && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.keeperCategory}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      {values.tenantRentPaymentBill?.cheque?.keeperCategory === 'OWNER' && <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.cheque.keeperId">
                          <Form.Label>Keeper</Form.Label>
                          <Form.Control as="select" name="tenantRentPaymentBill.cheque.keeperId" onChange={handleChange} onBlur={handleBlur} value={values.tenantRentPaymentBill?.cheque?.keeperId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              owners?.map((owner =>
                                <option key={"tenantRentPaymentBill.cheque.keeperId" + owner.id} value={owner.id}>{owner.fullName}
                                  {owner.userSubCategory && ' (' + formatProperCase(owner.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors?.tenantRentPaymentBill?.cheque?.keeperId && touched?.tenantRentPaymentBill?.cheque?.keeperId && <Form.Text className="text-danger">
                            {errors?.tenantRentPaymentBill?.cheque?.keeperId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>}

                    </Row>}
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleNewRentPaymentBillButtonClick(null)}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m4" show={showMarkAsPaidRentPaymentModal} onHide={this.handleMarkAsPaidRentPaymentBillButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Close Rent Payment Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                tenantRentPaymentBill: {
                  terminalStatus: 'CLOSED'
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await rentPaymentBillsServices.rentPaymentBillsMarkTerminalStatus(accordionData && accordionData.id, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Rent Payment bill closed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMarkAsPaidRentPaymentModal: !state.showMarkAsPaidRentPaymentModal }));
                    await this.getAllTenantRentPaymentBills();
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>

                    <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
                    <p><strong>Are you sure you want to close this rent payment bill?</strong></p>

                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMarkAsPaidRentPaymentBillButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m5" size="lg" show={showTransferModal} onHide={() => this.handleInitiateTransferButtonClick()}>
          <Modal.Header closeButton>
            <Modal.Title>Initiate Transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bankAccountsForTransfer && bankAccountsForTransfer.length === 0 && <>
              <h5>Transfer cannot be initiated at this time because the settlement bank account does not exist.</h5>
            </>}
            {transferNotes && transferNotes.updatedAt && <Card className="mb-3 border-warning">
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <h5>Transfer Note <i className="la la-info-circle pl-3" title="To update this information, check Owners tab on Property Profile page"></i></h5>
                    <p>{transferNotes.note}</p>
                    <p className="small text-muted mb-0">Last updated: <strong>{formatDateTime(transferNotes.updatedAt)}</strong> by <strong>{transferNotes.createdBy.fullName}</strong></p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>}

            {bankAccountsForTransfer && bankAccountsForTransfer.length > 0 && <Formik
              initialValues={{
                transfer: {
                  sourcePaymentId: (transferData && transferData.sourcePaymentId) || undefined,
                  bankAccountId: undefined,
                  amount: initialFinalAmount || (sourcePaymentForTransfer && Number(sourcePaymentForTransfer.amount).toFixed(2)) || undefined,
                  resultantPaymentAdjustments: suggestedPaymentAdjustments,
                  transferMode: (transferData && transferData.transferMode) || undefined,
                }
              }}
              validationSchema={transferValidationSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let sendingValues = JSON.parse(JSON.stringify(values));
                  //using sendingValues for manipulation to keep form values intact for retrying if it fails.
                  if (sendingValues.transfer?.bankAccountId) {
                    sendingValues.transfer.bankAccountId = parseInt(sendingValues.transfer.bankAccountId, 10);
                  }
                  if (sendingValues.transfer?.resultantPaymentAdjustments && sendingValues.transfer?.resultantPaymentAdjustments.length > 0) {
                    sendingValues.transfer.resultantPaymentAdjustments = sendingValues.transfer.resultantPaymentAdjustments.filter(rpa => rpa.isChecked === true);
                    if (sendingValues.transfer.resultantPaymentAdjustments.length > 0) {
                      sendingValues.transfer.resultantPaymentAdjustments.forEach(rpa => {
                        delete rpa.id;
                        delete rpa.isChecked;
                        delete rpa.invoice.pmsSubscription.startDate;
                        delete rpa.invoice.pmsSubscription.endDate;
                        delete rpa.invoice.pmsSubscription.feeRateQuantity;
                        delete rpa.invoice.pmsSubscription.feeRateUnit;
                        rpa.invoice.invoiceItems.length > 3 && rpa.invoice.invoiceItems.pop();
                      });
                    }
                  }
                  const result = await commonServices.createTransferToOwner(sendingValues);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Transfer Created Successfully!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    await this.getTenantRentPaymentBillsById(accordionData && accordionData.id);
                    this.setState(state => ({ showTransferModal: !state.showTransferModal }));
                  }
                }
              }
            >
              {
                ({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <h5>1. Select the Bank Account to make the transfer to:</h5>
                        <Form.Group className="mb-2" key={`transfer.bankAccountId`} controlId={`transfer.bankAccountId`}>
                          {bankAccountsForTransfer && bankAccountsForTransfer.map((account, index) =>
                          (<Form.Check className={(values?.transfer?.bankAccountId === account.id.toString()) ? 'card border-success pl-2 pr-3 pt-2' : 'card pl-2 pr-3 pt-2'} type="radio" key={`transfer.bankAccountId${index}`}>
                            <Form.Check.Input className="ml-0" type="radio" name="transfer.bankAccountId" onChange={handleChange} value={account.id} disabled={!account.cashfreeBeneficiaryId} checked={(values?.transfer?.bankAccountId === account.id.toString())} />
                            {!account.cashfreeBeneficiaryId && <Form.Text className='text-danger ml-4'>This bank account does not have Cashfree Beneficiary ID</Form.Text>}
                            <Form.Check.Label className="ml-4 mt-1">
                              <Row>
                                <Col xs={12}>
                                  {account.vpa !== null && account.vpa !== "" ?
                                    <p>
                                      <strong>VPA:</strong> {account.vpa}<br />
                                      <strong>Cashfree Beneficiary ID:</strong> {account.cashfreeBeneficiaryId ? account.cashfreeBeneficiaryId : 'Not Set'}
                                    </p> :
                                    <p>
                                      <strong>Account No:</strong> {account.accountNumber} <small>{account.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</small><br />
                                      Account Holder Name: {account.accountHolderName}<br />
                                      Bank: {account.currency === 'INR' && `${account.ifsc} | `}{account.currency === 'AED' && `${account.iban} | ${account.swiftCode} | `} {account.bankName} | {account.branch} <br />
                                      Cashfree Beneficiary ID: {account.cashfreeBeneficiaryId ? account.cashfreeBeneficiaryId : 'Not Set'}
                                      <p className='text-muted small'>Linked to {account.user.fullName} (Email: {account.user.email != null ? account.user.email : 'N/A'}) </p>
                                    </p>}
                                </Col>
                              </Row>
                            </Form.Check.Label>
                          </Form.Check>)
                          )
                          }
                          <Form.Text className="card pl-2 pr-3 py-2 text-warning small">Note: If you don't see an account you would like to transfer to, make sure it is added and a settlement account is created for it.</Form.Text>
                        </Form.Group>
                      </Col>
                      {accordionData && accordionData.pendingTransferableAmount > 0 && <Col xs={12}>
                        <h5>2. Amount pending to be transferred: {formatAmount(accordionData.pendingTransferableAmount)}</h5>
                      </Col>}
                      {suggestedPaymentAdjustments && suggestedPaymentAdjustments.length > 0 && <Col xs={12}>
                        <h5 className='ml-4'>Received Payment Amount: {formatAmount(sourcePaymentForTransfer.amount, sourcePaymentForTransfer.currency)}</h5>
                        <br />
                        <h5>3. Choose Adjustments to deduct from this payment:</h5>
                        {values.transfer.resultantPaymentAdjustments.map((rpa, index) =>
                        (<Form.Group className={rpa.isChecked ? 'card border-success pl-2 pr-3' : 'card pl-2 pr-3'} key={`transfer.resultantPaymentAdjustments[${index}].isChecked`} controlId={`transfer.resultantPaymentAdjustments[${index}].isChecked`}>
                          <Form.Check type="checkbox" key={`transfer.resultantPaymentAdjustments[${index}].isChecked`}>
                            <Form.Check.Input type="checkbox" onChange={handleChange}
                              name={`transfer.resultantPaymentAdjustments[${index}].isChecked`}
                              value={{ rpa }}
                              checked={(rpa.isChecked === true)}
                              onClick={async (e) => {
                                rpa.isChecked = !rpa.isChecked;
                                await this.calculateFinalAmountForTransfer(e, values.transfer.resultantPaymentAdjustments, setFieldValue);
                              }} />
                            <Form.Check.Label className="ml-3 mt-1">
                              {rpa.purposeCode === 'PMS_SUBSCRIPTION_INVOICE' &&
                                <>
                                  <p>
                                    <strong>{rpa.invoice.pmsSubscription.tcfIdentifier}</strong><br></br>
                                    Active PMS Subscription from <strong>{formatDate(rpa.invoice.pmsSubscription.startDate)} to {formatDate(rpa.invoice.pmsSubscription.endDate)}</strong> with Service Fee applicable at <strong>{rpa.invoice.pmsSubscription.feeRateUnit === '%' ? rpa.invoice.pmsSubscription.feeRateQuantity + rpa.invoice.pmsSubscription.feeRateUnit : formatAmount(rpa.invoice.pmsSubscription.feeRateQuantity)} + GST</strong>
                                  </p>
                                  <Row>

                                    <Col xs={12} md={3}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[0].amount`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[0].amount`}>
                                        <Form.Label>PMS Fee</Form.Label>
                                        <Form.Control type="number" step="0.01" readOnly={!rpa.isChecked} min={1} name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[0].amount`} onChange={
                                          async (e) => {
                                            handleChange(e);
                                            await this.updateGSTTDSAndTotalAmount(e, values.transfer.resultantPaymentAdjustments, rpa.id, setFieldValue);
                                            // await this.calculateFinalAmountForTransfer(e, values.transfer.resultantPaymentAdjustments, setFieldValue);
                                          }
                                        } onBlur={handleBlur} value={rpa.invoice.invoiceItems[0].amount} />
                                        {rpa.invoice.invoiceItems[0].amount === "" && <Form.Text className="text-danger">Amount cannot be empty</Form.Text>}
                                        {(rpa.invoice.invoiceItems[0].amount < 1) && <Form.Text className="text-danger">Amount cannot be negative or 0</Form.Text>}
                                        <Form.Text className="text-muted">Correct this amount if required.</Form.Text>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={12} md={3}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[1].amount`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[1].amount`}>
                                        <Form.Label>{rpa.invoice.invoiceItems[1].name}</Form.Label>
                                        <Form.Control type="text" readOnly name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[1].amount`} onChange={handleChange} onBlur={handleBlur} value={rpa.invoice.invoiceItems[1].amount} />
                                        <Form.Text className="text-muted">Auto-calculated</Form.Text>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={12} md={3}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[2].amount`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[2].amount`}>
                                        <Form.Label>{rpa.invoice.invoiceItems[2].name}</Form.Label>
                                        <Form.Control type="number" step="0.01" readOnly={!rpa.isChecked} min={0} name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[2].amount`} onChange={async (e) => {
                                          handleChange(e);
                                          await this.updateGSTTDSAndTotalAmount(e, values.transfer.resultantPaymentAdjustments, rpa.id, setFieldValue);
                                        }
                                        } onBlur={handleBlur} value={rpa.invoice.invoiceItems[2].amount}
                                        />
                                        {(rpa.invoice.invoiceItems[2].amount === "" || rpa.invoice.invoiceItems[2].amount < 0) && <Form.Text className="text-danger">Amount cannot be empty or negative</Form.Text>}
                                        <Form.Text className="text-muted">Add if required</Form.Text>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={12} md={3}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[3].amount`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[3].amount`}>
                                        <Form.Label>{rpa.invoice.invoiceItems[3].name}</Form.Label>
                                        <Form.Control type="text" readOnly name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.invoiceItems[3].amount`} onChange={handleChange} onBlur={handleBlur} value={rpa.invoice.invoiceItems[3].amount} />
                                        <Form.Text className="text-muted">Auto-calculated</Form.Text>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerName`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerName`}>
                                        <Form.Label>Customer Name (as required on Invoice)</Form.Label>
                                        <Form.Control type='text' placeholder='Optional' name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerName`} readOnly={!rpa.isChecked} onChange={handleChange} value={rpa.invoice.customerName}></Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerGstin`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerGstin`}>
                                        <Form.Label>Customer GSTIN</Form.Label>
                                        <Form.Control type='text' placeholder='Optional' name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerGstin`} readOnly={!rpa.isChecked} onChange={handleChange} value={rpa.invoice.customerGstin}></Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerAddress`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerAddress`}>
                                        <Form.Label>Customer Address</Form.Label>
                                        <Form.Control type='text' placeholder='Optional' name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerAddress`} readOnly={!rpa.isChecked} onChange={handleChange} value={rpa.invoice.customerAddress}></Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerState`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerState`}>
                                        <Form.Label>Customer State</Form.Label>
                                        <Form.Control type='text' placeholder='Optional' name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerState`} readOnly={!rpa.isChecked} onChange={handleChange} value={rpa.invoice.customerState}></Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                      <Form.Group className="mb-3" key={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerPincode`} controlId={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerPincode`}>
                                        <Form.Label>Customer Pincode</Form.Label>
                                        <Form.Control type='text' placeholder='Optional' name={`transfer.resultantPaymentAdjustments[${rpa.id}].invoice.customerPincode`} readOnly={!rpa.isChecked} onChange={handleChange} value={rpa.invoice.customerPincode}></Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </>
                              }
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>)
                        )}
                      </Col>}
                      <Col xs={6}>
                        <Form.Group controlId="transfer.transferMode">
                          <Form.Label>Transfer Mode</Form.Label>
                          <Form.Control as="select" name="transfer.transferMode" onChange={handleChange} onBlur={handleBlur} value={values.transfer.transferMode} >
                            <option value="">{constants.DEFAULT_OPTION}</option>
                            <option key={'viaIMPS'} value={'imps'}>IMPS</option>
                            <option key={'viaNEFT'} value={'neft'}>NEFT</option>
                          </Form.Control>
                          {(errors.transfer && errors.transfer.transferMode) && <Form.Text className="text-danger">{errors.transfer.transferMode}</Form.Text>}
                          <Form.Text className="text-muted small">Use NEFT when the selected bank account does not support IMPS transfers.</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="transfer.amount">
                          <Form.Label>Final Amount to Transfer</Form.Label>
                          <Form.Control type="number" step="0.01" name="transfer.amount" min={1} max={accordionData && accordionData.pendingTransferableAmount}
                            onChange={handleChange} onBlur={handleBlur} value={values?.transfer?.amount} />
                          {(values.transfer.amount === "0.00" || values.transfer.amount === 0) && <Form.Text className="text-danger">Amount cannot be zero</Form.Text>}
                          {values.transfer.amount < 0 && <Form.Text className="text-danger">Amount cannot be negative</Form.Text>}
                          {/* {values.transfer.amount > 0 && <Form.Text className="text-muted">Auto-calculated</Form.Text>} */}
                          {accordionData.pendingTransferableAmount > 0 && (values.transfer.amount > accordionData.pendingTransferableAmount) && <Form.Text className="text-muted">Amount cannot be greater than {formatAmount(accordionData.pendingTransferableAmount)}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleInitiateTransferButtonClick()}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Confirm'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>}
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m6" show={showMarkAsDepositedModal} onHide={this.handleMarkAsDepositedButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Mark as Paid</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                cheque: {
                  depositionStatus: 'YES'
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await rentPaymentBillsServices.markDepositionStatus(chequeId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Cheque marked as deposited!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMarkAsDepositedModal: !state.showMarkAsDepositedModal }));
                    await this.getTenantRentPaymentBillsById(accordionData?.id);
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>

                    <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
                    <p><strong>Do you want to mark this cheque as deposited?</strong></p>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMarkAsDepositedButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m7" show={showClearedOrBouncedModal} onHide={this.handleClearedOrBouncedButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isCleared === true ? `Mark as Cleared` : `Mark as Bounced`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                cheque: {
                  terminalStatus: isCleared ? `YES` : `NO`,
                  terminalRemarks: undefined,
                  payment: undefined

                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await rentPaymentBillsServices.markChequeTerminalStatus(chequeId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: (isCleared && `Chequed marked as Cleared`) || `Chequed marked as Bounced`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showClearedOrBouncedModal: !state.showClearedOrBouncedModal }));
                    await this.this.getAllTenantRentPaymentBills();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      {isCleared && (selectedCheque?.payerCategory === 'AZURO' ||
                        selectedCheque?.payeeCategory === 'AZURO') && <Col xs={12}>
                          <Form.Group controlId="cheque.payment.datetime">
                            <DatePickerField
                              className='form-control'
                              autoComplete='off'
                              label='Payment Date Time'
                              showTimeSelect
                              timeFormat='HH:mm'
                              timeIntervals={15}
                              timeCaption='time'
                              dateFormat='MMMM d, yyyy hh:mm a'
                              name='cheque.payment.datetime'
                              value={values?.cheque?.payment?.datetime}
                              onChange={setFieldValue}
                            />
                            {errors?.cheque?.payment?.datetime && touched?.cheque?.payment?.datetime && <Form.Text className="text-danger">
                              {(errors?.cheque?.payment?.datetime && touched?.cheque?.payment?.datetime && errors?.cheque?.payment?.datetime)}
                            </Form.Text>}
                          </Form.Group>
                        </Col>}

                      <Col xs={12}>
                        <Form.Group controlId="cheque.terminalRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control as="textarea" name='cheque.terminalRemarks' onChange={handleChange} onBlur={handleBlur} value={values?.cheque?.terminalRemarks} />
                          {errors?.cheque?.terminalRemarks && touched?.cheque?.terminalRemarks && <Form.Text className="text-danger">
                            {(errors?.cheque?.terminalRemarks && touched?.cheque?.terminalRemarks && errors?.cheque?.terminalRemarks)}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleClearedOrBouncedButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m8" show={showEditRentPaymentModal} onHide={this.handleEditRentPaymentBillButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Rent Payment Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                tenantRentPaymentBill: {
                  rentPaymentsBillId: (this.state.toBeEdited && this.state.toBeEdited.id) || undefined,
                  payableDurationStartDate: (this.state.toBeEdited && this.state.toBeEdited.payableDurationStartDate) || undefined,
                  payableDurationEndDate: (this.state.toBeEdited && this.state.toBeEdited.payableDurationEndDate) || undefined,
                  paymentDueDate: (this.state.toBeEdited && this.state.toBeEdited.paymentDueDate) || undefined,
                  amount: (this.state.toBeEdited && this.state.toBeEdited.amount) || undefined,
                  billAmount: (this.state.toBeEdited && this.state.toBeEdited.billAmount) || undefined,
                  updateRemarks: (this.state.toBeEdited && this.state.toBeEdited.updateRemarks) || undefined
                }
              }}
              validationSchema={editRentPaymentBillSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.tenantRentPaymentBill.billAmount = values.tenantRentPaymentBill.amount;
                  const data = {
                    'tenantRentPaymentBill': {
                      'payableDurationStartDate': values.tenantRentPaymentBill.payableDurationStartDate,
                      'payableDurationEndDate': values.tenantRentPaymentBill.payableDurationEndDate,
                      'paymentDueDate': values.tenantRentPaymentBill.paymentDueDate,
                      'amount': values.tenantRentPaymentBill.amount,
                      'billAmount': values.tenantRentPaymentBill.billAmount,
                      'updateRemarks': values.tenantRentPaymentBill.updateRemarks
                    }
                  }
                  let id = values.tenantRentPaymentBill.rentPaymentsBillId;
                  const result = await rentPaymentBillsServices.updateRentPaymentBill(id, data);
                  if (result.status === 200 || result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Rent Payment Bill Updated! Please refresh to see the updated bill.`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showEditRentPaymentModal: !state.showEditRentPaymentModal }));
                    this.getTenantRentPaymentBillsById(values.tenantRentPaymentBill.rentPaymentsBillId);
                  }
                }
              }
            >

              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>1. Edit Bill Details:</h5>
                        <p className="small text-muted">Tenant Rent Payment Bill #{this.state.toBeEdited && this.state.toBeEdited.id}</p>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.payableDurationStartDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payable Duration Start Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.payableDurationStartDate'
                            value={values.tenantRentPaymentBill.payableDurationStartDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.payableDurationStartDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.payableDurationStartDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.payableDurationStartDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.payableDurationEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payable Duration End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.payableDurationEndDate'
                            value={values.tenantRentPaymentBill.payableDurationEndDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.payableDurationEndDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.payableDurationEndDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.payableDurationEndDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.paymentDueDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payment Due Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='tenantRentPaymentBill.paymentDueDate'
                            value={values.tenantRentPaymentBill.paymentDueDate}
                            onChange={setFieldValue}
                          />
                          {errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.paymentDueDate && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.paymentDueDate && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.paymentDueDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.billAmount">
                          <Form.Label>Bill Amount before Adjustments <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" name="tenantRentPaymentBill.billAmount" onChange={
                            async (e) => {
                              handleChange(e);
                              await this.setState({ originalRentAmount: e.target.value });
                              await this.calculateFinalAmountForBillAdjustments(e, accordionData.resultantPaymentBillAdjustments, setFieldValue);
                            }
                          } onBlur={handleBlur} value={values.tenantRentPaymentBill.billAmount} />
                          {(errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.billAmount && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.billAmount) && <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.billAmount}</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <h5>2. Applied Bill Adjustments:</h5>
                      </Col>
                      {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length > 0 &&
                        accordionData.resultantPaymentBillAdjustments.map((billAdjust, index) => <Col xs={12} key={"rpba" + index}>
                          <p className="mb-0 small text-muted">Payment Bill #{billAdjust.adjustedPaymentBill.id}</p>
                          <p>Token Amount: <span className={billAdjust.effect === "NEGATIVE" ? "text-danger" : ""}>{(billAdjust.effect === "NEGATIVE") ? "-" : ""}{formatAmount(billAdjust.adjustedPaymentBill.amount)}</span></p>
                        </Col>)}
                      {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length === 0 && <Col xs={12} style={{ marginBottom: "2rem" }}>
                        <p className="small text-muted mb-0">No Payment Bill Adjustments applied.</p>
                      </Col>}
                      <Col xs={6}>
                        <Form.Group controlId="tenantRentPaymentBill.amount">
                          <Form.Label>Final Payable Amount <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" min={1} readOnly name="tenantRentPaymentBill.amount" value={values.tenantRentPaymentBill.amount} />
                          {(errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.amount && touched.tenantRentPaymentBill && touched.tenantRentPaymentBill.amount) ? <Form.Text className="text-danger">
                            {errors.tenantRentPaymentBill.amount}</Form.Text> : <Form.Text>Auto-calculated</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="tenantRentPaymentBill.updateRemarks">
                          <Form.Label>Update Remarks<span className="text-danger">*</span></Form.Label>
                          <Form.Control type="text" name="tenantRentPaymentBill.updateRemarks" value={values.tenantRentPaymentBill.updateRemarks} onChange={handleChange} onBlur={handleBlur} />
                          {(errors.tenantRentPaymentBill && errors.tenantRentPaymentBill.updateRemarks) && <Form.Text className="text-danger">{errors.tenantRentPaymentBill.updateRemarks}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditRentPaymentBillButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={"m9"} show={showDeleteRentPaymentBillModal} onHide={this.handleDeleteRentPaymentButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Rent Payment Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Rent Payment Bill?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteRentPaymentButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteRentPaymentButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default withRouter(connect(
  mapStateToProps,
  null
)(RentPaymentBills));
