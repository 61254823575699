import React, { Component } from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FilterComponent from '../../../partials/layout/FilterComponent';
import * as constants from '../../../constants';
import { formatDateTime, formatAmount, datePlusOneDay } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { RefundPaymentsServices } from './RefundPayments.service';

export default class Refunds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isFetching: false,
            page: 1,
            total: undefined,
            minimumDatetime: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split("T")[0],
            maximumDatetime: new Date().toISOString().split("T")[0],
            offset: 0,
            limit: 10,
        }
    }

    /**
     * component did mount method
     */
    async componentDidMount() {
        await this.getAllRefunds();
    }

    /**
     * Method to get all the payments data
     */
    getAllRefunds = async () => {
        this.setState({ isFetching: true });
        const { offset, limit, minimumDatetime, maximumDatetime, refundStatus, houseIdFromPropertyProfile } = this.state;
        let newMaximumDatetime = maximumDatetime && datePlusOneDay(maximumDatetime);
        const data = {
            minimumDatetime,
            maximumDatetime: newMaximumDatetime,
            houseId: houseIdFromPropertyProfile,
            include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
            refundStatus,
            offset,
            limit,
        }

        const result = await RefundPaymentsServices.getAllRefunds(data);
        if (result && result.status === 200) {
            const totalCount = result.headers['x-total-count'];
            const totalPageNo = Math.ceil(totalCount / limit);
            await this.setState({
                refunds: result.data.refunds,
                total: totalPageNo === 0 ? 1 : totalPageNo,
                totalCount
            });
        }
        this.setState({ isFetching: false })
    }

    /**
     * method to handle page change for pagination
     * @param {*} page
     */
    onPageChange = async (page) => {
        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });
        await this.getAllRefunds();
    }


    /**
     * Method to handle data of add payment component
     * @param {String} childData
     */
    handleFilterChildData = async (childData) => {
        if (childData) {
            this.setState({ ...childData })
            await this.resetPage();
            await this.getAllRefunds();
        }
    }

    /**
     * Method to reset Pagination
     */
    resetPage = async () => {
        await this.setState({
            page: 1,
            offset: 0,
            //limit: 10,
        })
    }


    render() {
        const { isFetching, refunds = [], page, total, totalCount,
            minimumDatetime, maximumDatetime,
        } = this.state;
        if (isFetching) {
            return <p>{constants.LOADING_DATA}</p>
        }
        let showPagination = false;
        if (total) { showPagination = total !== 0 && total !== 1; }
        return (
            <>
                <Container fluid key="c1">
                    <Portlet fluidHeight={true}>
                        <PortletHeader title={`Refunds (${totalCount || 0})`} />
                        <PortletBody>
                            <FilterComponent
                                minimumDatetime={minimumDatetime}
                                maximumDatetime={maximumDatetime}
                                recordsPerPage={constants.RECORDS_PER_PAGE}
                                selectedRecordValue={this.state.limit}
                                refundStatusData={constants.REFUND_STATUS}
                                selectedRefundstatus={this.state.refundStatus}
                                filterParentCallback={this.handleFilterChildData}
                            />
                            <Card>
                                {refunds && refunds.length === 0 && <p>{constants.ZERO_DATA}</p>}
                                {refunds && refunds.length > 0 && <Card.Header>
                                    <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                                        <Col md={2}><strong>Refund ID</strong></Col>
                                        <Col md={2}><strong>Initiator</strong></Col>
                                        <Col md={2}><strong>Customer</strong></Col>
                                        <Col md={1}><strong>Refund Amount</strong></Col>
                                        <Col md={2}><strong>Date & OrderId</strong></Col>
                                        <Col md={1}><strong>Refund Status</strong></Col>
                                        <Col md={2}><strong>Refund Notes</strong></Col>
                                    </Row>
                                </Card.Header>}
                                <Card.Body className="p-0">
                                    {refunds && refunds.map(refund =>
                                        <React.Fragment key={refund.id}>
                                            <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                                                {<Col md={2} className="d-flex flex-row">
                                                    {refund.refundId}

                                                </Col>}
                                                <Col md={2}>{refund.creator && refund.creator.fullName}</Col>
                                                <Col md={2}>{refund.user && refund.user.fullName}</Col>
                                                <Col md={1}>{formatAmount(refund.amount)}</Col>
                                                <Col md={2} className="d-flex justify-content-between align-items-center">
                                                    <p className="mb-0">{formatDateTime(refund.createdAt)}<br /><span className="small text-muted">{refund.orderId}</span></p>
                                                </Col>
                                                <Col md={1}>{refund.refundStatus}</Col>
                                                <Col md={2} className="text-truncate" style={{ maxWidth: "150px" }}>
                                                    {refund.refundNotes ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-${refund.id}`}>
                                                                    {refund.refundNotes}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="d-inline-block text-truncate" style={{ maxWidth: "100%" }}>
                                                                {refund.refundNotes.length > 20
                                                                    ? `${refund.refundNotes.substring(0, 20)}...`
                                                                    : refund.refundNotes}
                                                            </span>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <span className="text-muted">N/A</span> // Handle null or undefined case
                                                    )}
                                                </Col>

                                            </Row>
                                            <hr className="my-1" />
                                        </React.Fragment>
                                    )
                                    }
                                </Card.Body>
                            </Card>
                            {/* Code for pagination */}
                            {showPagination && showPagination && <UltimatePagination
                                currentPage={page}
                                totalPages={total}
                                onChange={this.onPageChange}
                                boundaryPagesRange={0}
                                hidePreviousAndNextPageLinks={true}
                                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                            />}
                        </PortletBody>
                    </Portlet>
                </Container>

            </>
        )
    }

}

