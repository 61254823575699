import axiosInstance from "../../../../app/myAxiosInstance";

const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;


/**
 * Method to get all payments
 * @param {Object} data
 */
async function getPaymentsForRefund(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/payments/creditPayments`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getPaymentsForRefund ', err);
    }

}

/**
 * Method to get all payments
 * @param {Object} data
 */
async function createRefundToCustomer(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/refunds`, data);
        return result;
    } catch (err) {
        console.error('error in createRefundToCustomer ', err);
    }

}

async function getAllRefunds(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/refunds`, { params: data });
        return result;
    } catch (err) {
        console.error('error in createRefundToCustomer ', err);
    }

}

export const RefundPaymentsServices = {
    getPaymentsForRefund,
    createRefundToCustomer,
    getAllRefunds
};