import React, { Component } from 'react';
import { Row, Col, Button, Form, Accordion, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import MultiSelect from 'react-multi-select-component';
import { formatMultipleUsersWithoutSpan } from '../../../_metronic/utils/utils';
import { DatePickerField } from './DatePickerField';
import * as constants from '../../constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { HouseOnboardingServices } from '../../pages/home/HouseOnboarding/HouseOnboarding.services'


export default class FilterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showFilterModal: false,
            selectedCities: props.preSelectedCities || [],
            isLoading: false,
            propertyOptions: [],
            propertyQuery: '',
            propertyOffset: 0,
            propertyLimit: 50,
            availableFilters: {
                state: [
                    {
                        name: 'Open',
                        selected: props.state && props.state.includes('OPEN') ? true : false,
                        value: 'OPEN',
                        id: 0
                    },
                    {
                        name: 'Closed',
                        selected: props.state && props.state.includes('CLOSED') ? true : false,
                        value: 'CLOSED',
                        id: 1
                    },
                ],
                validityStatus: [
                    {
                        name: 'Valid',
                        selected: props.validityStatus && props.validityStatus.includes('VALID') ? true : false,
                        value: 'VALID',
                        id: 0
                    },
                    {
                        name: 'Invalid',
                        selected: props.validityStatus && props.validityStatus.includes('INVALID') ? true : false,
                        value: 'INVALID',
                        id: 1
                    },
                ],
                clearanceStatus: [
                    {
                        name: 'Paid',
                        selected: props.clearanceStatus && props.clearanceStatus.includes('PAID') ? true : false,
                        value: 'PAID',
                        id: 0
                    },
                    {
                        name: 'Pending',
                        selected: props.clearanceStatus && props.clearanceStatus.includes('PENDING') ? true : false,
                        value: 'PENDING',
                        id: 1
                    },

                ],
                direction: [
                    {
                        name: 'Credit',
                        selected: props.direction && props.direction.includes('CREDIT') ? true : false,
                        value: 'CREDIT',
                        id: 0
                    },
                    {
                        name: 'Debit',
                        selected: props.direction && props.direction.includes('DEBIT') ? true : false,
                        value: 'DEBIT',
                        id: 1
                    },
                ],
                mode: [
                    // {
                    //     name: 'NEFT',
                    //     selected: props.mode && props.mode.includes('NEFT') ? true : false,
                    //     value: 'NEFT'
                    // },
                    // {
                    //     name: 'RTGS',
                    //     selected: props.mode && props.mode.includes('RTGS') ? true : false,
                    //     value: 'RTGS'
                    // },
                    // {
                    //     name: 'IMPS',
                    //     selected: props.mode && props.mode.includes('IMPS') ? true : false,
                    //     value: 'IMPS'
                    // },
                    // {
                    //     name: 'Cheque',
                    //     selected: props.mode && props.mode.includes('CHEQUE') ? true : false,
                    //     value: 'CHEQUE'
                    // },

                    // {
                    //     name: 'Cash',
                    //     selected: props.mode && props.mode.includes('CASH') ? true : false,
                    //     value: 'CASH'
                    // },
                    {
                        name: 'DEBIT CARD',
                        selected: props.mode && props.mode.includes('DEBIT_CARD') ? true : false,
                        value: 'DEBIT_CARD'
                    },
                    {
                        name: 'CREDIT CARD',
                        selected: props.mode && props.mode.includes('CREDIT_CARD') ? true : false,
                        value: 'CREDIT_CARD'
                    },
                    {
                        name: 'NET BANKING',
                        selected: props.mode && props.mode.includes('NET_BANKING') ? true : false,
                        value: 'NET_BANKING'
                    },
                    {
                        name: 'UPI',
                        selected: props.mode && props.mode.includes('UPI') ? true : false,
                        value: 'UPI'
                    },
                ],
                propertyManagementStatus: [
                    {
                        name: 'Yes',
                        selected: props.propertyManagementStatus && props.propertyManagementStatus.includes('YES') ? true : false,
                        value: 'YES',
                        id: 0
                    },
                    {
                        name: 'No',
                        selected: props.propertyManagementStatus && props.propertyManagementStatus.includes('NO') ? true : false,
                        value: 'NO',
                        id: 1
                    },
                ],
                terminalStatusForInspection: [
                    {
                        name: 'DONE',
                        selected: props.terminalStatusForInspection && props.terminalStatusForInspection.includes('DONE') ? true : false,
                        value: 'DONE',
                        id: 0
                    },
                    {
                        name: 'CANCELLED',
                        selected: props.terminalStatusForInspection && props.terminalStatusForInspection.includes('CANCELLED') ? true : false,
                        value: 'CANCELLED',
                        id: 1
                    },
                ],
                terminalStatusForRenewal: [
                    {
                        name: "RENEWED",
                        selected: props.terminalStatusForRenewal && props.terminalStatusForRenewal.includes('RENEWED') ? true : false,
                        value: "RENEWED",
                        id: 0,

                    },
                    {
                        name: "DISCONTINUED",
                        selected: props.terminalStatusForRenewal && props.terminalStatusForRenewal.includes('DISCONTINUED') ? true : false,
                        value: "DISCONTINUED",
                        id: 1,

                    }
                ],
                terminalStatusForOnboarding: [
                    {
                        name: "Completed",
                        selected: props.terminalStatusForOnboarding && props.terminalStatusForOnboarding.includes('COMPLETED') ? true : false,
                        value: "COMPLETED",
                        id: 0,

                    },
                    {
                        name: "Cancelled",
                        selected: props.terminalStatusForOnboarding && props.terminalStatusForOnboarding.includes('CANCELLED') ? true : false,
                        value: "CANCELLED",
                        id: 1,

                    }
                ],
                published: [
                    {
                        name: 'Yes',
                        selected: props.published && props.published.includes('YES') ? true : false,
                        value: 'YES',
                        id: 0
                    },
                    {
                        name: 'No',
                        selected: props.published && props.published.includes('NO') ? true : false,
                        value: 'NO',
                        id: 1
                    },
                ],

                beatsListingIdentifier: [],

                onboardingStatus: [
                    {
                        name: 'ON HOLD',
                        selected: props.onboardingStatus && props.onboardingStatus.includes('YES') ? true : false,
                        value: 'HOLD',
                        id: 0
                    },
                    {
                        name: 'NOT ON HOLD',
                        selected: props.onboardingStatus && props.onboardingStatus.includes('NO') ? true : false,
                        value: null,
                        id: 1
                    }
                ],
                purpose: [
                    {
                        name: 'Rent',
                        selected: props.purpose && props.purpose.includes('RENT') ? true : false,
                        value: 'RENT'
                    },
                    {
                        name: 'Security Deposit',
                        selected: props.purpose && props.purpose.includes('SECURITY_DEPOSIT') ? true : false,
                        value: 'SECURITY_DEPOSIT'
                    },
                    {
                        name: 'Token',
                        selected: props.purpose && props.purpose.includes('TOKEN') ? true : false,
                        value: 'TOKEN'
                    },
                    {
                        name: 'Invoice',
                        selected: props.purpose && props.purpose.includes('INVOICE') ? true : false,
                        value: 'INVOICE'
                    }


                ],
                status: [
                    {
                        name: 'Paid',
                        selected: props.status && props.status.includes('PAID') ? true : false,
                        value: 'PAID'
                    },
                    {
                        name: 'Pending',
                        selected: props.status && props.status.includes('PENDING') ? true : false,
                        value: 'PENDING'
                    },
                    {
                        name: 'Cancelled',
                        selected: props.status && props.status.includes('CANCELLED') ? true : false,
                        value: 'CANCELLED'
                    }
                ],
                pmsSubscriptionType:[
                    {
                        name: 'Paid',
                        selected: props.pmsSubscriptionType && props.pmsSubscriptionType.includes('Paid') ? true : false,
                        value: 'Paid',
                        id:0
                    },
                    {
                        name: 'Free',
                        selected: props.pmsSubscriptionType && props.pmsSubscriptionType.includes('Free') ? true : false,
                        value: 'Free',
                        id:1
                    },

                ]

            },
        };

    }

    propertyCache = {};

    async componentDidMount() {
    }

    static getDerivedStateFromProps = (props, state) => {
        let result = {};
        if (state !== props) {
            result = props;
        }
        return Object.keys(result).length > 0 ? result : null;
    }

    handleFilterButtonClick = async () => {
        this.setState(state => ({ showFilterModal: !state.showFilterModal }));
    }

    handleApplyFilterButtonClick = async () => {
        this.setState(state => ({ showFilterModal: !state.showFilterModal }));
        // create filterParams
        // make API call
    }

    handleClearFiltersButtonClick = async () => {
        //reset all filters to default
        this.setState(state => ({ showFilterModal: !state.showFilterModal }));
        //create filterParams
        // make API call
    }

    setSelected = (selectedCities) => {
        this.setState({ selectedCities });
    }
    // /**
    //  * Method to handle service request state toggle
    //  */
    // handleServiceRequestToggle = async () => {
    //     if (this.state.serviceRequestType === 'OPEN') {
    //         await this.setState({ serviceRequestType: 'CLOSED' });
    //     } else if (this.state.serviceRequestType === 'CLOSED') {
    //         await this.setState({ serviceRequestType: 'OPEN' });
    //     }
    //     this.getData();

    // }

    /**
      * Helper method to format the cities
      */
    formatResultCities = (rawCities) => {
        return rawCities.map(city => {
            return city.id
        });
    }

    /**
     * Method to handle property input change
     * @param {String} propertyQuery
     */
    handlePropertyInputChange = propertyQuery => {
        this.setState({ propertyQuery });
    };

    /**
     * Method to handle property pagination
     * @param {Object} e
     * @param {Array} shownResults
     */
    handlePropertyPagination = async (e, shownResults) => {
        const { propertyQuery } = this.state;
        const cachedPropertyQuery = this.propertyCache[propertyQuery];

        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedPropertyQuery.options.length > shownResults ||
            cachedPropertyQuery.options.length === cachedPropertyQuery.total_count
        ) {
            return;
        }

        this.setState({ isLoading: true });
        const propertyPage = cachedPropertyQuery.propertyPage + 1;
        const { propertyLimit } = this.state;
        const propertyOffset = (propertyLimit * (propertyPage - 1));
        await this.setState({ propertyPage, propertyOffset });
        const response = await this.getAllProperties();
        const propertyOptions = cachedPropertyQuery.options.concat(response.options);
        this.propertyCache[propertyQuery] = { ...cachedPropertyQuery, propertyOptions, propertyPage, propertyOffset };
        this.setState({
            isLoading: false,
            propertyOptions,
        });
    };


    /**
     * Method to handle propertyQuery
     * @param {String} propertyQuery
     */
    handlePropertySearch = async (propertyQuery) => {
        if (this.propertyCache[propertyQuery]) {
            await this.setState({ propertyOptions: this.propertyCache[propertyQuery].options });
            return;
        }
        this.setState({ isLoading: true });
        const response = await this.getAllProperties();
        this.propertyCache[propertyQuery] = { ...response, propertyPage: 1 };
        await this.setState({
            isLoading: false,
            propertyOptions: response && response.options,
        });
    };


    /**
     * Method to get all the properties Data
     */
    getAllProperties = async () => {
        this.setState({ isLoading: true });
        const { propertyOffset, propertyLimit, propertyQuery } = this.state;
        const data = {
            searchQuery: propertyQuery,
            offset: propertyOffset,
            limit: propertyLimit,
            scope: 'ALL',
            include: ['OWNERS', 'CURRENT_TENANTS']
        }
        const result = await HouseOnboardingServices.getAllProperties(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const options = result.data.houses.map(houseData => ({
                id: houseData.id,
                property: `${houseData.formattedHouseName} | Owners: ${formatMultipleUsersWithoutSpan(houseData.owners)} | Tenants: ${formatMultipleUsersWithoutSpan(houseData.currentTenants)}`,
                propertyManagementStatus: houseData.propertyManagementStatus
            }));
            return { options, total_count };
        }
        this.setState({ isLoading: false });
    }

    render() {
        const { availableFilters, requestedFilters, formattedCities, selectedCities, minimumCreatedAt,
            maximumCreatedAt, minimumClosureDatetime, maximumClosureDatetime, minimumStartDate, maximumStartDate,
            minimumEndDate, maximumEndDate, activeDate, maximumPaymentDueDate, maximumLatestNoteCreatedAt, minimumSourcePmsSubscriptionEndDate,
            maximumSourcePmsSubscriptionEndDate, minimumExpectedMoveOutDate, maximumExpectedMoveOutDate, minimumDatetime, maximumDatetime, maximumAmount, minimumAmount, minimumInvoiceDate, maximumInvoiceDate,
            minimumRentEscalationDate, maximumRentEscalationDate, minimumDueDate, maximumDueDate, maximumScheduleStartDatetime,
            propertyManagers, propertyManagerId, propertySupervisors, assigneeId, minimumStartDatetime, maximumStartDatetime,
            maximumPreviousLeaseEndDate, maximumNoticePeriodEndDate, minimumExecutionDate, maximumExecutionDate,
            houseName, selectedHouseObject, isMyProperties, recordsPerPage, rentPaymentBillstatusData, selectedRentBillstatus, selectedRefundstatus, refundStatusData, moveInManagers, selectedRecordValue, onboardingStatus,
            moveInManagerId, beatsListingIdentifier, minimumOnboardingRequirementStatusMarkedAt, maximumOnboardingRequirementStatusMarkedAt, purpose, mode, status } = this.state;

        return (
            <React.Fragment>
                <Accordion defaultActiveKey="0">
                    <Card key={'test-card'}>

                        <Accordion.Toggle as={Card.Header} eventKey={'test'} className="row py-2 azuro-table-row d-flex justify-content-end align-items-center"
                        // onClick={() => { this.handleAccordionClick(req.id) }}
                        >
                            {/* Applied Filters: {requestedFilters.length} */}
                            {/* {requestedFilters && requestedFilters.length > 0 && <p className="text-muted small mb-0">
                                Applied Filters: {requestedFilters.map((item, index) => <Badge pill variant="info">{formatProperCase(item)}</Badge>)}
                            </p>} */}
                            {/* Filter Button */}
                            <Button className="mx-2" variant="link" size="sm" onClick={this.handleFilterButtonClick}>Filter</Button>
                        </Accordion.Toggle>

                        {/* Modal to show the Timeline */}
                        <Accordion.Collapse key={'test'} eventKey={'test'}>
                            <Card.Body>
                                <Row className="mb-4">
                                    <Col xs={12}>
                                        <Formik
                                            initialValues={{
                                                state: requestedFilters?.indexOf('state') >= 0 ? availableFilters.state : undefined,
                                                // mode: requestedFilters?.indexOf('mode') >= 0 ? availableFilters.mode : undefined,
                                                direction: requestedFilters?.indexOf('direction') >= 0 ? availableFilters.direction : undefined,
                                                validityStatus: requestedFilters?.indexOf('validityStatus') >= 0 ? availableFilters.validityStatus : undefined,
                                                clearanceStatus: requestedFilters?.indexOf('clearanceStatus') >= 0 ? availableFilters.clearanceStatus : undefined,
                                                terminalStatus: requestedFilters?.indexOf('terminalStatusForRenewal') >= 0 ? availableFilters.terminalStatusForRenewal : requestedFilters?.indexOf("terminalStatusForInspection") >= 0 ? availableFilters.terminalStatusForInspection : requestedFilters?.indexOf("terminalStatusForOnboarding") >= 0 ? availableFilters.terminalStatusForOnboarding : undefined,
                                                minimumCreatedAt, maximumCreatedAt, minimumClosureDatetime, maximumClosureDatetime,
                                                minimumStartDate, maximumStartDate, minimumEndDate,
                                                maximumEndDate, activeDate, maximumPaymentDueDate, maximumLatestNoteCreatedAt, minimumSourcePmsSubscriptionEndDate,
                                                maximumSourcePmsSubscriptionEndDate, minimumExpectedMoveOutDate, maximumExpectedMoveOutDate, minimumDatetime, maximumDatetime, maximumAmount, minimumAmount, minimumInvoiceDate, maximumInvoiceDate,
                                                minimumRentEscalationDate, maximumRentEscalationDate, minimumDueDate, maximumDueDate, maximumScheduleStartDatetime,
                                                minimumStartDatetime, maximumStartDatetime,
                                                propertyManagerId, assigneeId, maximumPreviousLeaseEndDate, maximumNoticePeriodEndDate,
                                                minimumExecutionDate, maximumExecutionDate, houseName, beatsListingIdentifier, onboardingStatus, minimumOnboardingRequirementStatusMarkedAt, maximumOnboardingRequirementStatusMarkedAt,
                                                houseId: selectedHouseObject?.id, selectedRecordValue, moveInManagerId, selectedRentBillstatus, selectedRefundstatus,
                                                pmsSubscriptionType: requestedFilters?.indexOf('pmsSubscriptionType') >= 0 ? availableFilters.pmsSubscriptionType : undefined,
                                                purpose, status, mode,
                                            }}
                                            onSubmit={
                                                async (values, { setSubmitting }) => {
                                                    let result = {};
                                                    requestedFilters && requestedFilters.map((requestedFilter, index) => {
                                                        // let newRequestedFilterArray = requestedFilter;
                                                        result[requestedFilter] = []
                                                        // loop througth the availableFitler of value
                                                        // availableFilter.mode
                                                        availableFilters[requestedFilter].map((availableFilter, index) => {
                                                            // if the selected value is true then push that to requested filter array
                                                            if (availableFilter.selected === true) {
                                                                result[requestedFilter].push(availableFilter.value)
                                                            }
                                                            return result[requestedFilter];
                                                        })
                                                        return result[requestedFilter];
                                                    });
                                                    if (selectedCities) { result['cityIds'] = this.formatResultCities(selectedCities); }
                                                    result['minimumCreatedAt'] = values.minimumCreatedAt;
                                                    result['maximumCreatedAt'] = values.maximumCreatedAt;
                                                    result['minimumClosureDatetime'] = values.minimumClosureDatetime;
                                                    result['maximumClosureDatetime'] = values.maximumClosureDatetime;
                                                    result['minimumStartDate'] = values.minimumStartDate;
                                                    result['maximumStartDate'] = values.maximumStartDate;
                                                    result['minimumEndDate'] = values.minimumEndDate;
                                                    result['maximumEndDate'] = values.maximumEndDate;
                                                    result['activeDate'] = values.activeDate;
                                                    result['maximumPaymentDueDate'] = values.maximumPaymentDueDate;
                                                    result['maximumLatestNoteCreatedAt'] = values.maximumLatestNoteCreatedAt;
                                                    result['minimumSourcePmsSubscriptionEndDate'] = values.minimumSourcePmsSubscriptionEndDate;
                                                    result['maximumSourcePmsSubscriptionEndDate'] = values.maximumSourcePmsSubscriptionEndDate;
                                                    result['minimumExpectedMoveOutDate'] = values.minimumExpectedMoveOutDate;
                                                    result['maximumExpectedMoveOutDate'] = values.maximumExpectedMoveOutDate
                                                    result['minimumDatetime'] = values.minimumDatetime;
                                                    result['maximumDatetime'] = values.maximumDatetime;
                                                    result['maximumAmount'] = values.maximumAmount;
                                                    result['minimumAmount'] = values.minimumAmount;
                                                    result['minimumInvoiceDate'] = values.minimumInvoiceDate;
                                                    result['maximumInvoiceDate'] = values.maximumInvoiceDate;
                                                    result['minimumRentEscalationDate'] = values.minimumRentEscalationDate;
                                                    result['maximumRentEscalationDate'] = values.maximumRentEscalationDate;
                                                    result['minimumDueDate'] = values.minimumDueDate;
                                                    result['maximumDueDate'] = values.maximumDueDate;
                                                    result['minimumStartDatetime'] = values.minimumStartDatetime;
                                                    result['maximumStartDatetime'] = values.maximumStartDatetime;
                                                    result['maximumScheduleStartDatetime'] = values.maximumScheduleStartDatetime;
                                                    result['propertyManagerId'] = values.propertyManagerId;
                                                    result['assigneeId'] = values.assigneeId;
                                                    result['maximumPreviousLeaseEndDate'] = values.maximumPreviousLeaseEndDate;
                                                    result['maximumNoticePeriodEndDate'] = values.maximumNoticePeriodEndDate;
                                                    result['minimumExecutionDate'] = values.minimumExecutionDate;
                                                    result['maximumExecutionDate'] = values.maximumExecutionDate;
                                                    result['houseId'] = values.houseId;
                                                    result['houseName'] = values.houseName;
                                                    result['propertyOptions'] = values.propertyOptions;
                                                    result['limit'] = values.selectedRecordValue;
                                                    result['moveInManagerId'] = values.moveInManagerId;
                                                    result['onboardingStatus'] = values.onboardingStatus;
                                                    result['minimumOnboardingRequirementStatusMarkedAt'] = values.minimumOnboardingRequirementStatusMarkedAt;
                                                    result['maximumOnboardingRequirementStatusMarkedAt'] = values.maximumOnboardingRequirementStatusMarkedAt;
                                                    result['beatsListingIdentifier'] = values.beatsListingIdentifier;
                                                    result['purpose'] = values.purpose;
                                                    result['mode'] = values.mode;
                                                    result['status'] = values.status;
                                                    result['rentBillstatus'] = values.selectedRentBillstatus;
                                                    result['refundStatus'] = values.selectedRefundstatus;
                                                    this.state.filterParentCallback(result);
                                                    this.setState(state => ({ showFilterModal: !state.showFilterModal }));
                                                }
                                            }
                                        >
                                            {({ values, handleSubmit, handleChange, errors, touched, isSubmitting, setFieldValue, handleBlur }) =>
                                            (
                                                <Form onSubmit={handleSubmit}>
                                                    <Row>
                                                        {requestedFilters?.indexOf('state') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">State</p>
                                                            {availableFilters?.state.map((item, index) =>
                                                            (
                                                                <Form.Group key={`sr-status-checkbox${index}`} controlId={`sr-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" name="sr-status-checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.state[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.state[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>
                                                            )
                                                            )}
                                                        </Col>}


                                                        {this.state.state && this.state.state[0] === "CLOSED" && requestedFilters?.indexOf('terminalStatusForRenewal') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Renewal Status </p>
                                                            {/* Terminal status For PMS Renewal Component */}
                                                            {availableFilters.terminalStatusForRenewal.map((item, index) =>
                                                            (
                                                                <Form.Group key={`terminal-status-checkbox${index}`} controlId={`terminal-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" name="terminal-status-checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.terminalStatusForRenewal[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.terminalStatusForRenewal[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange}
                                                                        />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>
                                                            )
                                                            )}
                                                        </Col>}


                                                        {this.state.state && this.state.state[0] === "CLOSED" && requestedFilters?.indexOf('terminalStatusForInspection') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Inspection Status </p>
                                                            {/* Terminal status For PMS Renewal Component */}
                                                            {availableFilters.terminalStatusForInspection.map((item, index) =>
                                                            (
                                                                <Form.Group key={`terminal-status-checkbox${index}`} controlId={`terminal-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" name="terminal-status-checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.terminalStatusForInspection[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.terminalStatusForInspection[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange}
                                                                        />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>
                                                            )
                                                            )}
                                                        </Col>}

                                                        {this.state.state && this.state.state[0] === "CLOSED" && requestedFilters?.indexOf('terminalStatusForOnboarding') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Onboarding Terminal Status </p>
                                                            {/* Terminal status For PMS Renewal Component */}
                                                            {availableFilters.terminalStatusForOnboarding.map((item, index) =>
                                                            (
                                                                <Form.Group key={`terminal-status-checkbox${index}`} controlId={`terminal-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" disabled={this.state.state[0] === "OPEN"} name="terminal-status-checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.terminalStatusForOnboarding[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.terminalStatusForOnboarding[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange}
                                                                        />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>
                                                            )
                                                            )}
                                                        </Col>}

                                                        {'mode' in this.state && <Col xs={12} md={2}>
                                                            <Form.Group controlId="mode">
                                                                <Form.Label>Payment Mode</Form.Label>
                                                                <Form.Control as="select" name="mode" onChange={handleChange} onBlur={handleBlur} value={values.mode}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>

                                                                    {availableFilters.mode.map((item, index) =>
                                                                        <option value={item.value}> {item.name}  </option>
                                                                    )}

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {requestedFilters?.indexOf('direction') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Payment Direction</p>
                                                            {availableFilters.direction.map((item, index) =>
                                                            (
                                                                <Form.Group key={`payment-direction-checkbox${index}`} controlId={`payment-direction-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.direction[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.direction[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}

                                                        {requestedFilters?.indexOf('validityStatus') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Validity Status</p>
                                                            {availableFilters.validityStatus.map((item, index) =>
                                                            (
                                                                <Form.Group key={`invoice-validity-checkbox${index}`} controlId={`invoice-validity-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.validityStatus[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.validityStatus[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}


                                                        {requestedFilters?.indexOf('clearanceStatus') >= 0 && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Clearance Status</p>
                                                            {availableFilters.clearanceStatus.map((item, index) =>
                                                            (
                                                                <Form.Group key={`invoice-clearance-status-checkbox${index}`} controlId={`invoice-clearance-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.clearanceStatus[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.clearanceStatus[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name === 'Pending' ? 'No' : 'Yes'}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}


                                                        {(('propertyManagementStatus' in this.state && this.state.propertyManagementStatus) || isMyProperties) && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Property Under Management?</p>
                                                            {availableFilters.propertyManagementStatus.map((item, index) =>
                                                            (
                                                                <Form.Group key={`property-management-status-checkbox${index}`} controlId={`property-management-status-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.propertyManagementStatus[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.propertyManagementStatus[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}
                                                        {requestedFilters?.indexOf('pmsSubscriptionType') >= 0&&<Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Pms Subscription</p>
                                                            {availableFilters.pmsSubscriptionType.map((item, index) =>
                                                            (
                                                                <Form.Group key={`pms-subscription${index}`} controlId={`pms-subscription${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.pmsSubscriptionType[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.pmsSubscriptionType[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}


                                                        {(('published' in this.state && this.state.published)) && <Col xs={12} md={2} className="mb-2">
                                                            <p className="mb-2">Published Status</p>
                                                            {availableFilters.published.map((item, index) =>
                                                            (
                                                                <Form.Group key={`inspection-report-published-checkbox${index}`} controlId={`inspection-report-published-checkbox${index}`} className="mb-2">
                                                                    <Form.Check type="checkbox">
                                                                        <Form.Check.Input type="checkbox" checked={item.selected} value={{ item }}
                                                                            onClick={() => {
                                                                                // TODO: improve the logic here
                                                                                if (item.id === index) {
                                                                                    item.selected = !item.selected
                                                                                }
                                                                                if (index !== 0) {
                                                                                    availableFilters.published[index - 1].selected = false
                                                                                } else {
                                                                                    availableFilters.published[index + 1].selected = false
                                                                                }
                                                                            }
                                                                            }
                                                                            onChange={handleChange} />
                                                                        <Form.Check.Label className="ml-3 mt-1">{item.name}</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>

                                                            )
                                                            )}
                                                        </Col>}

                                                        {formattedCities &&
                                                            <Col xs={12} md={2} className="mb-2">
                                                                <p className="mb-2">Cities</p>
                                                                <MultiSelect
                                                                    options={formattedCities}
                                                                    value={selectedCities}
                                                                    onChange={this.setSelected}
                                                                    labelledBy={"Select Cities"}
                                                                />
                                                            </Col>
                                                        }

                                                        {'minimumCreatedAt' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumCreatedAt">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Created from'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='minimumCreatedAt'
                                                                    value={values.minimumCreatedAt}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumCreatedAt' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumCreatedAt">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Created till'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='maximumCreatedAt'
                                                                    value={values.maximumCreatedAt}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumClosureDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumClosureDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Closed from'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='minimumClosureDatetime'
                                                                    value={values.minimumClosureDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumClosureDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumClosureDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Closed till'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='maximumClosureDatetime'
                                                                    value={values.maximumClosureDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumStartDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumStartDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Started from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumStartDate'
                                                                    value={values.minimumStartDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumStartDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumStartDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Started till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumStartDate'
                                                                    value={values.maximumStartDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Ended from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumEndDate'
                                                                    value={values.minimumEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Ended till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumEndDate'
                                                                    value={values.maximumEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'activeDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="activeDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Active as on'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='activeDate'
                                                                    value={values.activeDate}
                                                                    onChange={setFieldValue}
                                                                    isClearable
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumPaymentDueDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumPaymentDueDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Payment Due till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumPaymentDueDate'
                                                                    value={values.maximumPaymentDueDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumSourcePmsSubscriptionEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumSourcePmsSubscriptionEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Subscription Ends from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumSourcePmsSubscriptionEndDate'
                                                                    value={values.minimumSourcePmsSubscriptionEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumSourcePmsSubscriptionEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumSourcePmsSubscriptionEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Subscription Ends by'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumSourcePmsSubscriptionEndDate'
                                                                    value={values.maximumSourcePmsSubscriptionEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}
                                                        {'minimumExpectedMoveOutDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumExpectedMoveOutDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Expected Move-out from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumExpectedMoveOutDate'
                                                                    value={values.minimumExpectedMoveOutDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumExpectedMoveOutDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumExpectedMoveOutDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Expected Move-out till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumExpectedMoveOutDate'
                                                                    value={values.maximumExpectedMoveOutDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}


                                                        {'maximumLatestNoteCreatedAt' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumLatestNoteCreatedAt">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='No Updates since'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumLatestNoteCreatedAt'
                                                                    value={values.maximumLatestNoteCreatedAt}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Paid from'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='minimumDatetime'
                                                                    value={values.minimumDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Paid till'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='maximumDatetime'
                                                                    value={values.maximumDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumInvoiceDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumInvoiceDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Invoice Date from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumInvoiceDate'
                                                                    value={values.minimumInvoiceDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumInvoiceDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumInvoiceDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Invoice Date till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumInvoiceDate'
                                                                    value={values.maximumInvoiceDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumRentEscalationDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumRentEscalationDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Earliest Escalation Date'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumRentEscalationDate'
                                                                    value={values.minimumRentEscalationDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumRentEscalationDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumRentEscalationDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Latest Escaltion Date'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumRentEscalationDate'
                                                                    value={values.maximumRentEscalationDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumDueDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumDueDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Due from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumDueDate'
                                                                    value={values.minimumDueDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumDueDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumDueDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Due by'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumDueDate'
                                                                    value={values.maximumDueDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumStartDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumStartDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Earliest Job Start on'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    autoTime={true}
                                                                    name='minimumStartDatetime'
                                                                    value={values.minimumStartDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumStartDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumStartDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Latest Job Start on'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    autoTime={true}
                                                                    name='maximumStartDatetime'
                                                                    value={values.maximumStartDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumScheduleStartDatetime' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumScheduleStartDatetime">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Schedule Starts by'
                                                                    autoTime={true}
                                                                    dateFormat='yyyy-MM-dd'
                                                                    name='maximumScheduleStartDatetime'
                                                                    value={values.maximumScheduleStartDatetime}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumPreviousLeaseEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumPreviousLeaseEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Previous Lease Ends by'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumPreviousLeaseEndDate'
                                                                    value={values.maximumPreviousLeaseEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumNoticePeriodEndDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumNoticePeriodEndDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Notice Period Ends by'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumNoticePeriodEndDate'
                                                                    value={values.maximumNoticePeriodEndDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'minimumExecutionDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumExecutionDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Execution Date from'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumExecutionDate'
                                                                    value={values.minimumExecutionDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumExecutionDate' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumExecutionDate">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Execution Date till'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumExecutionDate'
                                                                    value={values.maximumExecutionDate}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumAmount' in this.state && (
                                                            <Col xs={6} md={2}>
                                                                <Form.Group controlId="maximumAmount">
                                                                    <Form.Label>Maximum Amount</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        name="maximumAmount"
                                                                        value={values.maximumAmount}
                                                                        onChange={(e)=> setFieldValue("maximumAmount", e.target.value)}
                                                                        placeholder="Enter max amount"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        {'minimumAmount' in this.state && (
                                                            <Col xs={6} md={2}>
                                                                <Form.Group controlId="minimumAmount">
                                                                    <Form.Label>Minimum Amount</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        name="minimumAmount"
                                                                        value={values.minimumAmount}
                                                                        onChange={(e)=> setFieldValue("minimumAmount", e.target.value)}
                                                                        placeholder="Enter min amount"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        {'propertyManagers' in this.state && this.state.propertyManagers && <Col xs={12} md={2}>
                                                            <Form.Group controlId="propertyManagerIdSelect">
                                                                <Form.Label>Property Manager</Form.Label>
                                                                <Form.Control as="select" name="propertyManagerId" onChange={handleChange} onBlur={handleBlur} value={values.propertyManagerId}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        propertyManagers && propertyManagers.map((pm =>
                                                                            <option key={"pm-" + pm.id} value={pm.id}> {pm.fullName}  </option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'purpose' in this.state && <Col xs={12} md={2}>
                                                            <Form.Group controlId="purpose">
                                                                <Form.Label>Purpose</Form.Label>
                                                                <Form.Control as="select" name="purpose" onChange={handleChange} onBlur={handleBlur} value={values.purpose}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>

                                                                    {availableFilters.purpose.map((item, index) =>
                                                                        <option value={item.value}> {item.name}  </option>
                                                                    )}

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'moveInManagers' in this.state && this.state.moveInManagers && <Col xs={6} md={2}>
                                                            <Form.Group controlId="moveInManagerIdSelect">
                                                                <Form.Label>Move In Manager</Form.Label>
                                                                <Form.Control as="select" name="moveInManagerId" onChange={handleChange} onBlur={handleBlur} value={values.moveInManagerId}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        moveInManagers && moveInManagers.map((mim =>
                                                                            <option key={"mim-" + mim.id} value={mim.id}> {mim.fullName}  </option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'onboardingStatus' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="onboardingStatusSelect">
                                                                <Form.Label >Onboarding Status</Form.Label>
                                                                <Form.Control as="select" name="onboardingStatus" onChange={handleChange} onBlur={handleBlur} value={values.onboardingStatus}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                    <option value={'HOLD'}>ON HOLD</option>
                                                                    <option value={'null'}>NOT ON HOLD</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'minimumOnboardingRequirementStatusMarkedAt' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="minimumOnboardingRequirementStatusMarkedAt">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Onboarding Requirement Marked(Min)'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='minimumOnboardingRequirementStatusMarkedAt'
                                                                    value={values.onboardingRequirementMarkedOnMin}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'maximumOnboardingRequirementStatusMarkedAt' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="maximumOnboardingRequirementStatusMarkedAt">
                                                                <DatePickerField
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    label='Onboarding Requirement Marked(Max)'
                                                                    dateFormat='MMMM d, yyyy'
                                                                    name='maximumOnboardingRequirementStatusMarkedAt'
                                                                    value={values.onboardingRequirementMarkedOnMax}
                                                                    onChange={setFieldValue}
                                                                />
                                                            </Form.Group>
                                                        </Col>}

                                                        {'recordsPerPage' in this.state && this.state.recordsPerPage && <Col xs={6} md={2}>
                                                            <Form.Group controlId="selectedRecordValueSelect">
                                                                <Form.Label>Records per page</Form.Label>
                                                                <Form.Control as="select" name="selectedRecordValue" onChange={handleChange} onBlur={handleBlur} value={values.selectedRecordValue} >
                                                                    {
                                                                        recordsPerPage && recordsPerPage.map((value =>
                                                                            <option key={'limit' + value} value={value}>{value}</option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'rentPaymentBillstatusData' in this.state && this.state.rentPaymentBillstatusData && <Col xs={6} md={2}>
                                                            <Form.Group controlId="selectedRentBillstatusSelect">
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control as="select" name="selectedRentBillstatus" onChange={handleChange} onBlur={handleBlur} value={values.selectedRentBillstatus} >
                                                                    <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        rentPaymentBillstatusData && rentPaymentBillstatusData.map((value =>
                                                                            <option key={"idx" + value} value={value}>{value.replace(/_/g, ' ')}</option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'refundStatusData' in this.state && this.state.refundStatusData && <Col xs={6} md={2}>
                                                            <Form.Group controlId="selectedRefundstatusSelect">
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control as="select" name="selectedRefundstatus" onChange={handleChange} onBlur={handleBlur} value={values.selectedRefundstatus} >
                                                                    <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        refundStatusData && refundStatusData.map((value =>
                                                                            <option key={"idx" + value} value={value}>{value.replace(/_/g, ' ')}</option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'status' in this.state && <Col xs={12} md={2}>
                                                            <Form.Group controlId="status">
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control as="select" name="status" onChange={handleChange} onBlur={handleBlur} value={values.status}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>

                                                                    {availableFilters.status.map((item, index) =>
                                                                        <option value={item.value}> {item.name}  </option>
                                                                    )}

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'beatsListingIdentifier' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="beatsListingIdentifierSelect">
                                                                <Form.Label>Beats Listing ID</Form.Label>
                                                                <Form.Control type="text" name="beatsListingIdentifier" onChange={handleChange} onBlur={handleBlur} value={values.beatsListingIdentifier} />
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'propertySupervisors' in this.state && <Col xs={6} md={2}>
                                                            <Form.Group controlId="assigneeIdSelect">
                                                                <Form.Label>Property Supervisor</Form.Label>
                                                                <Form.Control as="select" name="assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        propertySupervisors && propertySupervisors.map((ps =>
                                                                            <option key={"ps-" + ps.id} value={ps.id}> {ps.fullName}  </option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        }

                                                        {'selectHouse' in this.state && this.state.selectHouse && <Col xs={6} className="mb-2">
                                                            <label>Search Property</label>
                                                            {selectedHouseObject && <AsyncTypeahead
                                                                name="houseId"
                                                                isLoading={this.state.isLoading}
                                                                options={this.state.propertyOptions}
                                                                query={this.state.propertyQuery}
                                                                id="async-pagination-property"
                                                                labelKey="property"
                                                                maxResults={this.state.propertyLimit - 1}
                                                                minLength={3}
                                                                onChange={async (e) => {
                                                                    const houseId = (e.length > 0) ? e[0].id : undefined;
                                                                    const houseName = (e.length > 0) ? e[0].property : undefined;
                                                                    setFieldValue('houseId', houseId);
                                                                    setFieldValue('houseName', houseName);
                                                                    setFieldValue('propertyOptions', this.state.propertyOptions)
                                                                }}
                                                                onInputChange={this.handlePropertyInputChange}
                                                                onPaginate={this.handlePropertyPagination}
                                                                onSearch={this.handlePropertySearch}
                                                                defaultSelected={[selectedHouseObject] || null}
                                                                paginate
                                                                placeholder={"Search for your property here..."}
                                                                renderMenuItemChildren={option => (
                                                                    <div key={option.id}>
                                                                        <span>{option.property}</span>
                                                                    </div>
                                                                )}
                                                                useCache={false}
                                                            />}

                                                            {!selectedHouseObject && <AsyncTypeahead
                                                                name="houseId"
                                                                isLoading={this.state.isLoading}
                                                                options={this.state.propertyOptions}
                                                                query={this.state.propertyQuery}
                                                                id="async-pagination-property"
                                                                labelKey="property"
                                                                maxResults={this.state.propertyLimit - 1}
                                                                minLength={3}
                                                                onChange={async (e) => {
                                                                    const houseId = (e.length > 0) ? e[0].id : undefined;
                                                                    const houseName = (e.length > 0) ? e[0].property : undefined;
                                                                    setFieldValue('houseId', houseId);
                                                                    setFieldValue('houseName', houseName);
                                                                    setFieldValue('propertyOptions', this.state.propertyOptions)
                                                                }}
                                                                onInputChange={this.handlePropertyInputChange}
                                                                onPaginate={this.handlePropertyPagination}
                                                                onSearch={this.handlePropertySearch}
                                                                paginate
                                                                placeholder={"Search for your property here..."}
                                                                renderMenuItemChildren={option => (
                                                                    <div key={option.id}>
                                                                        <span>{option.property}</span>
                                                                    </div>
                                                                )}
                                                                useCache={false}
                                                            />}
                                                        </Col>}
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} className="text-right">
                                                            {/* <Button variant="secondary" className="mr-2" onClick={this.handleClearFiltersButtonClick}>
                                                            Clear All
                                                        </Button> */}
                                                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                                Apply
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </React.Fragment>
        );
    }

}
