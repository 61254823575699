import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Builder from "./Builder";
import Dashboard from "./Dashboard";
import NoUserRolesDashboard from './NoUserRolesDashboard';
// import HomepagePreMoveIn from "./HomePage/HomepagePreMoveIn";
// import HomepagePostMoveIn from "./HomePage/HomepagePostMoveIn";
// import HomepagePMHead from "./HomePage/HomepagePMHead";
import ServiceRequests from "./ServiceRequests/ServiceRequests";
import LeaseTerminations from "./LeaseTerminations/LeaseTerminations";
import MoveOutInitiatedLease from "./LeaseTerminations/MoveOutInitiatedLease";
import LeaseProfilePage from "./LeaseProfilePage/LeaseProfilePage";
import RentPaymentBills from "./RentPaymentBills/RentPaymentBills";
import PropertyProfilePage from "./PropertyProfilePage/PropertyProfilePage";
import RentEscalations from "./RentEscalations/RentEscalations"
import PMSRenewals from "./PMSRenewals/PMSRenewals";
import PMSSubscriptions from './PMSSubscriptions/PMSSubscriptions'
import Inspections from "./Inspections/Inspections";
import InspectionReports from './InspectionReports/InspectionReports'
import MyProperties from "./MyProperties/MyProperties";
import Payments from "./Payments/Payments";
import Invoices from "./Invoices/Invoices";
import InspectionReport from "./InspectionReport/InspectionReport";
import MoveInMoveOutReport from "./MoveInMoveOutReport/MoveInMoveOutReport";
import HouseOnboarding from "./HouseOnboarding/HouseOnboarding"
import ChangePassword from "../auth/ChangePassword";
import NewPMSUser from "../auth/NewPMSUser";
import LeaseExtensions from './LeaseExtensions/LeaseExtensions';
import PendingOnboardings from './PendingOnboardings/PendingOnboardings';
import RentReceipts from "./RentReceipts/RentReceipts";
import ScheduledJobs from "./ScheduledJobs/ScheduledJobs";
import { LayoutSplashScreen } from "../../../_metronic";
import { connect } from "react-redux";
import ErrorPage2 from "../errors/ErrorPage2";
import PMSUsers from "../auth/PMSUsers";
import SecurityDepositBills from "./SecurityDepositBills/SecurityDepositBills";
import RefundPayments from "./RefundPayments/RefundPayments";

// const ReactBootstrapPage = lazy(() =>
//   import("./react-bootstrap/ReactBootstrapPage")
// );

function HomePage(props) {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const { userRoleCodes } = props;
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        {
          userRoleCodes.length === 0 &&

          /* Original Redirect from root URL to /dashboard. */
          /* New Redirect from root URL to /pre-move-in. Need to update this in the future to redirect to dashboard but content displayed is based on user role, available via auth. */
          <><Switch>
            <Redirect exact from="/" to="/no-user-roles" />
            <Route path="/no-user-roles" component={NoUserRolesDashboard} />
            {/* <Redirect to="/error/error-v1" /> */}
            <Route path='/*' component={ErrorPage2} />
          </Switch>
          </>

        }

        {
          userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&

          /* Original Redirect from root URL to /dashboard. */
          /* New Redirect from root URL to /pre-move-in. Need to update this in the future to redirect to dashboard but content displayed is based on user role, available via auth. */
          <><Switch>
            <Redirect exact from="/" to="/dashboard" />
            {/* <Route path="/pre-move-in" component={HomepagePreMoveIn} />
            <Route path="/post-move-in" component={HomepagePostMoveIn} />
            <Route path="/pm-head" component={HomepagePMHead} /> */}
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/service-requests" component={ServiceRequests} />
            <Route path="/lease-profile/:leaseId" component={LeaseProfilePage} />
            <Route path="/rent-payments" component={RentPaymentBills} />
            <Route path="/security-deposits" component={SecurityDepositBills} />
            <Route path="/property-profile/:houseId" component={PropertyProfilePage} />
            <Route path="/rent-escalations" component={RentEscalations} />
            <Route path="/pms-renewals" component={PMSRenewals} />
            <Route path="/inspections" component={Inspections} />
            <Route path="/inspection-reports" component={InspectionReports} />
            <Route path="/my-properties" component={MyProperties} />
            <Route path="/payments" component={Payments} />
            <Route path="/invoices" component={Invoices} />
            <Route path="/pms-subscriptions" component={PMSSubscriptions} />
            <Route path="/house-onboarding" component={HouseOnboarding} />
            <Route path="/inspection-report/:workId/:mode" component={InspectionReport} />
            <Route path="/move-in-move-out-report/:leaseId/:mode" component={MoveInMoveOutReport} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/new-pms-user" component={NewPMSUser} />
            <Route path="/lease-extensions" component={LeaseExtensions} />
            <Route path="/lease-terminations" component={MoveOutInitiatedLease} />
            <Route path="/lease-terminations-old" component={LeaseTerminations} />
            <Route path="/pending-onboardings" component={PendingOnboardings} />
            <Route path="/rent-receipts" component={RentReceipts} />
            <Route path="/scheduled-jobs" component={ScheduledJobs} />
            <Route path="/manage-users" component={PMSUsers} />
            <Route path="/refund-payments" component={RefundPayments} />
            {/* <Redirect to="/error/error-v1" /> */}
            <Route path='/*' component={ErrorPage2} />

          </Switch>
          </>

        }

        {
          userRoleCodes.includes('PROPERTY_MANAGER') &&

          <>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              {/* <Route path="/pre-move-in" component={HomepagePreMoveIn} /> */}
              {/* <Route path="/post-move-in" component={HomepagePostMoveIn} /> */}
              {/* <Route path="/pm-head" component={HomepagePMHead} /> */}
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/service-requests" component={ServiceRequests} />
              <Route path="/lease-profile/:leaseId" component={LeaseProfilePage} />
              <Route path="/rent-payments" component={RentPaymentBills} />
              <Route path="/security-deposits" component={SecurityDepositBills} />
              <Route path="/property-profile/:houseId" component={PropertyProfilePage} />
              <Route path="/rent-escalations" component={RentEscalations} />
              <Route path="/pms-renewals" component={PMSRenewals} />
              <Route path="/inspections" component={Inspections} />
              <Route path="/inspection-reports" component={InspectionReports} />
              <Route path="/my-properties" component={MyProperties} />
              <Route path="/payments" component={Payments} />
              <Route path="/invoices" component={Invoices} />
              <Route path="/pms-subscriptions" component={PMSSubscriptions} />
              <Route path="/house-onboarding" component={HouseOnboarding} />
              <Route path="/inspection-report/:workId/:mode" component={InspectionReport} />
              <Route path="/move-in-move-out-report/:leaseId/:mode" component={MoveInMoveOutReport} />
              <Route path="/change-password" component={ChangePassword} />
              <Route path="/lease-extensions" component={LeaseExtensions} />
              <Route path="/lease-terminations" component={MoveOutInitiatedLease} />
              <Route path="/lease-terminations-old" component={LeaseTerminations} />
              <Route path="/pending-onboardings" component={PendingOnboardings} />
              <Route path="/rent-receipts" component={RentReceipts} />
              <Route path="/scheduled-jobs" component={ScheduledJobs} />
              {/* <Redirect to="/error/error-v1" /> */}
              <Route path='/*' component={ErrorPage2} />
            </Switch>
          </>

        }

        {
          userRoleCodes.includes('MOVE_IN_MANAGER') &&
          <>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              <Route path="/dashboard" component={Dashboard} />
              {/* <Route path="/pre-move-in" component={HomepagePreMoveIn} /> */}
              {/* <Route path="/post-move-in" component={HomepagePostMoveIn} /> */}
              {/* <Route path="/pm-head" component={HomepagePMHead} /> */}
              {/* <Route path="/service-requests" component={ServiceRequests} />
              <Route path="/lease-terminations" component={LeaseTerminations} /> */}
              <Route path="/lease-profile/:leaseId" component={LeaseProfilePage} />
              <Route path="/property-profile/:houseId" component={PropertyProfilePage} />
              {/* <Route path="/rent-escalations" component={RentEscalations} />
              <Route path="/rent-payments" component={RentPaymentBills} />
              <Route path="/pms-renewals" component={PMSRenewals} />
              <Route path="/inspections" component={Inspections} />
              <Route path="/inspection-report/:workId/:mode" component={InspectionReport} /> */}
              <Route path="/my-properties" component={MyProperties} />
              <Route path="/payments" component={Payments} />
              <Route path="/invoices" component={Invoices} />
              <Route path="/house-onboarding" component={HouseOnboarding} />
              <Route path="/move-in-move-out-report/:leaseId/:mode" component={MoveInMoveOutReport} />
              <Route path="/scheduled-jobs" component={ScheduledJobs} />
              <Route path="/change-password" component={ChangePassword} />
              {/* <Redirect to="/error/error-v1" /> */}
              <Route path='/*' component={ErrorPage2} />

            </Switch>
          </>
        }

        {/*<Route path="/builder" component={Builder} />*/}
        {/*<Route path="/dashboard" component={Dashboard} />*/}
        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}


      </Switch>
    </Suspense>
  );
}


const mapStateToProps = state => ({
  userRoleCodes: state.auth && state.auth.user && state.auth.user.userRoleCodes
});

export default
  connect(
    mapStateToProps,
    null
  )(HomePage);