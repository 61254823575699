import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Form, Tabs, Tab, Modal } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom';
import { RefundPaymentsServices } from './RefundPayments.service';
import { datePlusOneDay, formatAmount, formatDateTime } from '../../../../_metronic/utils/utils';
import { commonServices } from '../../../commonServices/commonServices';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import FilterComponent from '../../../partials/layout/FilterComponent';
import * as constants from '../../../constants';
import Refunds from './Refunds';

class RefundPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isFetching: false,
            activeTab: "PaymentsTab",
            paymentId: this.props.location.state?.paymentId,
            minimumDatetime: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split("T")[0],
            maximumDatetime: new Date().toISOString().split("T")[0],
            page: 1,
            total: undefined,
            offset: 0,
            limit: 10,
            showRefundDetailsModal: false,
            selectedRefund: null,
            allowedUserEmails: ["prem.gupta@azuro.in", "gauri.morey@azuro.in", "support@azuro.in"],
            requestedFilters: [
                'mode',
                'purpose'
            ]
        };
    }
    async componentDidMount() {
        await this.getAllPayments();
        await this.getCities();
    }

    /**
  * Method to get all the payments data
  */
    getAllPayments = async () => {
        this.setState({ isFetching: true });
        const { offset, limit, cityIds, minimumDatetime, maximumDatetime, purpose, mode, maximumAmount, minimumAmount, paymentId } = this.state;
        let newMaximumDatetime = maximumDatetime && datePlusOneDay(maximumDatetime);
        const data = {
            cityIds,
            minimumDatetime,
            maximumDatetime: newMaximumDatetime,
            maximumAmount,
            minimumAmount,
            paymentId,
            mode: mode !== "0" ? mode : undefined,
            paymentPurpose: purpose !== "0" ? purpose : undefined,
            include: ['PROPERTY_MANAGER'],
            offset,
            limit
        }

        const result = await RefundPaymentsServices.getPaymentsForRefund(data);
        if (result && result.status === 200) {
            const totalCount = result.headers['x-total-count'];
            const totalPageNo = Math.ceil(totalCount / limit);
            await this.setState({
                payments: result.data.payments,
                total: totalPageNo === 0 ? 1 : totalPageNo,
                totalCount
            });
        }
        this.setState({ isFetching: false })
    };


    handleSelect = (key) => {
        this.setState({ activeTab: key });
    };

    /**
     * Method to get all the cities
     */
    getCities = async () => {
        const { cityIds } = this.state;
        const data = {
            pmsOperationStatus: 'YES'
        }
        const result = await commonServices.getCities(data);
        if (result && result.status === 200) {
            const cities = result?.data?.cities;
            const formattedCities = commonServices.formatCities(cities);
            let preSelectedCities = null;
            if (this.state.cityIds?.length > 0) {
                preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
            }
            await this.setState({ formattedCities });
            preSelectedCities && await this.setState({ preSelectedCities });
        }
    }


    /**
     * Method to handle data of add payment component
     * @param {String} childData
     */
    handleFilterChildData = async (childData) => {
        if (childData) {
            this.setState({ ...childData, paymentId: undefined });
            await this.resetPage();
            await this.getAllPayments();
            await this.getCities();
        }
    }

    /**
   * method to handle page change for pagination
   * @param {*} page
   */
    onPageChange = async (page) => {
        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });
        await this.getAllPayments();
    };

    /**
   * Method to reset Pagination
   */
    resetPage = async () => {
        await this.setState({
            page: 1,
            offset: 0,
            //limit: 10,
        })
    }

    handleInitiateRefundButtonClick = async (payment = null) => {
        if (payment) {
            this.setState({ paymentData: payment });
        }
        this.setState(state => ({ showInitiateRefundModal: !state.showInitiateRefundModal }));
    };

    handleShowRefundDetails = (refund) => {
        this.setState({
            selectedRefund: refund,
            showRefundDetailsModal: true
        });
    };


    render() {
        const { isFetching, activeTab, payments = [], page, total, totalCount, showInitiateRefundModal,
            requestedFilters, mode, formattedCities, preSelectedCities, purpose,
            minimumDatetime, maximumDatetime, paymentData, maximumAmount, minimumAmount, allowedUserEmails } = this.state;
        if (isFetching) {
            return <p>{constants.LOADING_DATA}</p>
        }
        let showPagination = false;
        if (total) { showPagination = total !== 0 && total !== 1; }

        return (
            <>
                <Container fluid>
                    <Card>
                        <Card.Body>
                            <Tabs
                                activeKey={activeTab}
                                onSelect={this.handleSelect}
                                id="refund-payments-tabs"
                                variant="pills"
                                className="mb-3"
                            >
                                <Tab eventKey="PaymentsTab" title="Credit Payments">
                                    <Portlet fluidHeight={true}>
                                        <PortletHeader title={`Credit Payments (${totalCount || 0})`} />
                                        <PortletBody>
                                            <FilterComponent
                                                formattedCities={formattedCities}
                                                preSelectedCities={preSelectedCities}
                                                minimumDatetime={minimumDatetime}
                                                maximumDatetime={maximumDatetime}
                                                maximumAmount={maximumAmount}
                                                minimumAmount={minimumAmount}
                                                requestedFilters={requestedFilters}
                                                recordsPerPage={constants.RECORDS_PER_PAGE}
                                                selectedRecordValue={this.state.limit}
                                                filterParentCallback={this.handleFilterChildData}
                                                mode={mode}
                                                purpose={purpose}
                                            />
                                            <Card>
                                                {payments && payments.length === 0 && <p>{constants.ZERO_DATA}</p>}
                                                {payments && payments.length > 0 && <Card.Header>
                                                    <Row className="d-flex justify-content-between align-items-center">
                                                        <Col md={2}><strong>Property</strong></Col>
                                                        <Col md={2}><strong>Title</strong></Col>
                                                        <Col md={2}><strong>Customer</strong></Col>
                                                        <Col md={2}><strong>Amount / Mode</strong></Col>
                                                        <Col md={1}><strong>Processing Fee</strong></Col>
                                                        <Col md={2}><strong>Date & OrderId</strong></Col>
                                                        <Col md={1}><strong>Refund</strong></Col>
                                                    </Row>
                                                </Card.Header>}
                                                <Card.Body className="p-0">
                                                    {payments && payments.map(payment =>
                                                        <React.Fragment key={payment.id}>
                                                            <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                                                                {<Col md={2} className="d-flex flex-row">
                                                                    {payment.house && <span>{payment.house.formattedHouseName} <br />
                                                                    </span>}
                                                                </Col>}
                                                                <Col md={2}>{payment.title}</Col>
                                                                <Col md={2}>{payment.user && payment.user.fullName}</Col>
                                                                <Col md={2}>{formatAmount(payment.amount, payment.currency)}<br /><span className="small text-muted">{payment.mode}</span></Col>
                                                                <Col md={1}>{formatAmount(payment.paymentGatewayCharges)}</Col>
                                                                <Col md={2} className="d-flex justify-content-between align-items-center">
                                                                    <p className="mb-0">{formatDateTime(payment.datetime)}<br /><span className="small text-muted">{payment.orderId}</span></p>
                                                                </Col>
                                                                <Col md={1}>
                                                                    {((this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) && allowedUserEmails.includes(this.props.userDetails.email)) && (payment?.direction === 'CREDIT') && (payment?.paymentGatewaySettlementStatus === "YES") && (!payment?.isRefundFound) &&
                                                                        (payment?.paymentGatewayCharges > 0) && <Button variant="link" size="sm" onClick={() => this.handleInitiateRefundButtonClick(payment)}>Initiate Refund</Button>
                                                                    }
                                                                    {(payment?.direction === 'CREDIT') && (payment?.isRefundFound) &&
                                                                        <Button variant="link" size="sm" onClick={() => this.handleShowRefundDetails(payment.refundPayments)}>View</Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <hr className="my-1" />
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </Card.Body>
                                            </Card>
                                            {/* Code for pagination */}
                                            {showPagination && showPagination && <UltimatePagination
                                                currentPage={page}
                                                totalPages={total}
                                                onChange={this.onPageChange}
                                                boundaryPagesRange={0}
                                                hidePreviousAndNextPageLinks={true}
                                                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                                            />}
                                        </PortletBody>
                                    </Portlet>
                                </Tab>
                                <Tab eventKey="RefundsTab" title="Refund History">
                                    {activeTab === "RefundsTab" && <Refunds />}
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Container>


                <Modal backdrop="static" keyboard={false} show={showInitiateRefundModal} onHide={this.handleInitiateRefundButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>Initiate Refund</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                amount: (paymentData && paymentData.paymentGatewayCharges) || 0,
                                orderId: (paymentData && paymentData.orderId) || undefined,
                                refundNote: null
                            }}
                            // validationSchema={Yup.object().shape({
                            //     amount: Yup.number()
                            //         .min(2, "Amount must be at least 1")
                            //         .required("Refund amount is required"),
                            // })}
                            onSubmit={async (values, { setSubmitting }) => {
                                const data = {
                                    amount: values.amount,
                                    orderId: values.orderId,
                                    refundNote: values.refundNote
                                };


                                const result = await RefundPaymentsServices.createRefundToCustomer(data);
                                if (result.status === 200 || result.status === 201) {
                                    store.addNotification({
                                        title: "Success!",
                                        message: `Refund Created Successfully!`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true,
                                            showIcon: true,
                                            pauseOnHover: true
                                        }
                                    });
                                    await this.getAllPayments();
                                    this.setState(state => ({ showInitiateRefundModal: !state.showInitiateRefundModal }));
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group controlId="Property">
                                                <Form.Label>Property</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="Property"
                                                    value={paymentData && paymentData?.house?.formattedHouseName}
                                                    disabled
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="CustomerName">
                                                <Form.Label>Customer Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="customerName"
                                                    value={paymentData && paymentData?.user && paymentData?.user?.fullName}
                                                    disabled
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="refundOrderId">
                                                <Form.Label>OrderId</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="refundOrderId"
                                                    value={values.orderId}
                                                    disabled
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {/* {paymentData && errors.paymentData.orderId && touched.paymentData.orderId && (
                                                    <Form.Text className="text-danger">
                                                        {errors.paymentData.orderId}
                                                    </Form.Text>
                                                )} */}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="refundAmount">
                                                <Form.Label>Refund Amount <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="refundAmount"
                                                    value={values.amount !== undefined && values.amount !== null ? Math.round(values.amount * 100) / 100 : ""}
                                                    max={paymentData.paymentGatewayCharges.toFixed(2)}
                                                    required
                                                    onChange={(e) => {
                                                        let inputValue = e.target.value;
                                                        // Allow empty value for smooth typing
                                                        if (inputValue === "") {
                                                            setFieldValue("amount", "");
                                                            return;
                                                        }
                                                        // Convert input to a valid number
                                                        setFieldValue("amount", Number(inputValue));
                                                    }}
                                                    onBlur={(e) => {
                                                        let inputValue = e.target.value;
                                                        if (inputValue) {
                                                            setFieldValue("amount", parseFloat(inputValue).toFixed(2));
                                                        }
                                                    }}
                                                />
                                                {errors.amount && touched.amount && (
                                                    <Form.Text className="text-danger">
                                                        {errors.amount}
                                                    </Form.Text>
                                                )}
                                                <Form.Text className="text-muted small">Refund amount cannot be greater than the {formatAmount(paymentData.paymentGatewayCharges)}.</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="refundNotes">
                                                <Form.Label>Refund Notes <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="refundNotes"
                                                    value={values.refundNote}
                                                    required
                                                    onChange={(e) => setFieldValue("refundNote", e.target.value)}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="text-right">
                                            <Button variant="secondary" className="mr-2" onClick={() => this.setState({ showInitiateRefundModal: false })}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                {isSubmitting ? "Processing..." : "Initiate Refund"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showRefundDetailsModal} onHide={() => this.setState({ showRefundDetailsModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Refund Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.selectedRefund && this.state.selectedRefund.length > 0 ? (
                            this.state.selectedRefund.map((refund, index) => (
                                <div key={refund.id || index} className="mb-3 p-3 border-bottom">
                                    <p><strong>Refund ID:</strong> {refund.refundId}</p>
                                    {/* <p><strong>Initiator:</strong> {refund.creator?.fullName}</p>
                                    <p><strong>Customer:</strong> {refund.user?.fullName}</p> */}
                                    <p><strong>Amount:</strong> {formatAmount(refund.amount)}</p>
                                    <p><strong>Date:</strong> {formatDateTime(refund.createdAt)}</p>
                                    {/* <p><strong>Order ID:</strong> {refund.orderId}</p> */}
                                    <p><strong>Status:</strong> {refund.refundStatus}</p>
                                    <p><strong>Notes:</strong> {refund.refundNotes}</p>
                                </div>
                            ))
                        ) : (
                            <p>No refunds available.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showRefundDetailsModal: false })}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}


const mapStateToProps = state => ({
    userDetails: state.auth && state.auth.user
});
export default withRouter(connect(
    mapStateToProps,
    null
)(RefundPayments));